import types from '../types'
import authService from './authService'

export const getFhirMetadata = (iss) => async (dispatch) => {
  dispatch({ type: types.GET_FHIR_META_DATA_REQUESTED })
  try {
    const metadata = await authService.getMetadata(iss)
    const conformance = metadata.data
    const secExtensions = conformance.rest[0].security.extension.find((ext) => ext.url === 'http://fhir-registry.smarthealthit.org/StructureDefinition/oauth-uris').extension
    const authorizeExt = secExtensions.find(ext => ext.url === 'authorize')
    const tokenExt = secExtensions.find(ext => ext.url === 'token')
    if (conformance.software && conformance.software.name && conformance.software.name === 'Epic') {
      window.sessionStorage.setItem('ehr', 'Epic')
    } else {
      window.sessionStorage.setItem('ehr', 'Unknown')
    }

    dispatch({ type: types.GET_FHIR_META_DATA_FULFILLED, payload: { authUrl: authorizeExt.valueUri, tokenUrl: tokenExt.valueUri } })
  } catch (error) {
    dispatch({ type: types.GET_FHIR_META_DATA_REJECTED, payload: error })
  }
}

export const getAccessToken = (url) => async (dispatch) => {
  dispatch({ type: types.GET_ACCESS_TOKEN_REQUESTED })
  try {
    const accecssToken = await authService.getAccessToken(url)
    dispatch({ type: types.GET_ACCESS_TOKEN_FULFILLED, payload: accecssToken.data })
  } catch (error) {
    dispatch({ type: types.GET_ACCESS_TOKEN_REJECTED, payload: error })
  }
}

export const generateTokenUri = (payload) => async (dispatch) => {
  dispatch({ type: types.FHIR_GENERATE_TOKEN_URI_REQUESTED })
  try {
    const tokenUri = await authService.generateTokenUri(payload)
    dispatch({ type: types.FHIR_GENERATE_TOKEN_URI_FULFILLED, payload: tokenUri.uri })
  } catch (error) {
    dispatch({ type: types.FHIR_GENERATE_TOKEN_URI_REJECTED, payload: error.toString() })
  }
}

export const initializeSmart = (payload) => (dispatch) => {
  dispatch({ type: types.INITIALIZE_SMART, payload })
  dispatch(getFhirMetadata(payload.iss))
}
