import types from '../types'
import adminService from './adminService'

export const getProgramTypes = () => async (dispatch) => {
  const programTypes = await adminService.getDropdownValues('program-type')
  dispatch({ type: types.GET_PROGRAM_TYPES, payload: programTypes.data })
}

export const getManufacturers = () => async (dispatch) => {
  const manufacturers = await adminService.getDropdownValues('manufacturer')
  dispatch({ type: types.GET_MANUFACTURERS, payload: manufacturers.data })
}

export const getPrograms = () => async (dispatch) => {
  dispatch({ type: types.GET_PROGRAMS_REQUESTED })
  try {
    const programs = await adminService.getPrograms()
    dispatch({ type: types.GET_PROGRAMS_FULFILLED, payload: programs.data })
  } catch (error) {
    dispatch({ type: types.GET_PROGRAMS_REJECTED, payload: error })
  }
}

export const getProgram = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_PROGRAM_REQUESTED })
  try {
    const program = await adminService.getProgram(programId)
    dispatch({ type: types.GET_PROGRAM_FULFILLED, payload: program.data })
  } catch (error) {
    dispatch({ type: types.GET_PROGRAM_REJECTED, payload: error })
  }
}

export const createNewProgram = (values) => async (dispatch) => {
  dispatch({ type: types.CREATE_NEW_PROGRAM_REQUESTED })
  try {
    const newProgram = await adminService.createNewProgram(values)
    dispatch({ type: types.CREATE_NEW_PROGRAM_FULFILLED, payload: newProgram.data })
  } catch (error) {
    dispatch({ type: types.CREATE_NEW_PROGRAM_REJECTED, payload: error })
  }
}

export const getSection = (payload) => async (dispatch) => {
  dispatch({ type: types.GET_SECTION_REQUESTED })
  try {
    const section = await adminService.getSection(payload)
    dispatch({ type: types.GET_SECTION_FULFILLED, payload: section.data })
  } catch (error) {
    dispatch({ type: types.GET_SECTION_REJECTED, payload: error })
  }
}

export const getFormSection = (payload) => async (dispatch) => {
  dispatch({ type: types.GET_FORM_SECTION_REQUESTED })
  try {
    const formSection = await adminService.getFormSection(payload)
    dispatch({ type: types.GET_FORM_SECTION_FULFILLED, payload: formSection.data })
  } catch (error) {
    dispatch({ type: types.GET_FORM_SECTION_REJECTED, payload: error })
  }
}

export const updateProviderSection = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PROVIDER_SECTION_REQUESTED })
  try {
    const updatedSection = await adminService.updateProviderSection(payload)
    dispatch({ type: types.UPDATE_PROVIDER_SECTION_FULFILLED, payload: updatedSection.data })
  } catch (error) {
    dispatch({ type: types.UPDATE_PROVIDER_SECTION_REJECTED, payload: error })
  }
}

export const saveProviderSection = (payload) => async (dispatch) => {
  dispatch({ type: types.SAVE_PROVIDER_SECTION_REQUESTED })
  try {
    const savedSection = await adminService.saveProviderSection(payload)
    dispatch({ type: types.SAVE_PROVIDER_SECTION_FULFILLED, payload: savedSection.data })
  } catch (error) {
    dispatch({ type: types.SAVE_PROVIDER_SECTION_REJECTED })
  }
}

export const getAttestationSection = (payload) => async (dispatch) => {
  dispatch({ type: types.GET_ATTESTATION_SECTION_REQUESTED })
  try {
    const section = await adminService.getSection(payload)
    dispatch({ type: types.GET_ATTESTATION_SECTION_FULFILLED, payload: section.data })
  } catch (error) {
    dispatch({ type: types.GET_ATTESTATION_SECTION_REJECTED, payload: error })
  }
}

export const getAttestationFormSection = (payload) => async (dispatch) => {
  dispatch({ type: types.GET_ATTESTATION_FORM_SECTION_REQUESTED })
  try {
    const formSection = await adminService.getFormSection(payload)
    dispatch({ type: types.GET_ATTESTATION_FORM_SECTION_FULFILLED, payload: formSection.data })
  } catch (error) {
    dispatch({ type: types.GET_ATTESTATION_FORM_SECTION_REJECTED, payload: error })
  }
}

export const updateAttestationSection = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_ATTESTATION_SECTION_REQUESTED })
  try {
    const updatedSection = await adminService.updateAttestationSection(payload)
    dispatch({ type: types.UPDATE_ATTESTATION_SECTION_FULFILLED, payload: updatedSection.data })
  } catch (error) {
    dispatch({ type: types.UPDATE_ATTESTATION_SECTION_REJECTED, payload: error })
  }
}

export const editAttestationSection = (payload) => async (dispatch) => {
  dispatch({ type: types.EDIT_ATTESTATION_SECTION_REQUESTED })
  try {
    const updatedSection = await adminService.editAttestationSection(payload)
    dispatch({ type: types.EDIT_ATTESTATION_SECTION_FULFILLED, payload: updatedSection.data })
  } catch (error) {
    dispatch({ type: types.EDIT_ATTESTATION_SECTION_REJECTED, payload: error })
  }
}

export const getDosingBases = () => async (dispatch) => {
  const dosingBases = await adminService.getDropdownValues('dosing-bases')
  dispatch({ type: types.GET_DOSING_BASES, payload: dosingBases.data })
}

export const getDrugProducts = (programId) => async (dispatch) => {
  const drugProducts = await adminService.getDropdownValues('drug-products', programId)
  dispatch({ type: types.GET_DRUG_PRODUCTS, payload: drugProducts.data })
}

export const updatePrescriptionSection = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PRESCRIPTION_SECTION_REQUESTED })
  try {
    const updatedSection = await adminService.updatePrescriptionSection(payload)
    dispatch({ type: types.UPDATE_PRESCRIPTION_SECTION_FULFILLED, payload: updatedSection.data })
  } catch (error) {
    dispatch({ type: types.UPDATE_PRESCRIPTION_SECTION_REJECTED, payload: error })
  }
}

export const getPatientDemographics = () => async (dispatch) => {
  const demographics = await adminService.getDropdownValues('demographics')
  dispatch({ type: types.GET_PATIENT_DEMOGRAPHICS, payload: demographics.data })
}

export const saveProgramPatientDemographics = (payload) => async (dispatch) => {
  dispatch({ type: types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REQUESTED })
  try {
    const savedDemographics = await adminService.saveDemographics(payload)
    dispatch({ type: types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_FULFILLED, payload: savedDemographics.data })
  } catch (error) {
    dispatch({ type: types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REJECTED, payload: error })
  }
}

export const updatePatientSection = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PATIENT_SECTION_REQUESTED })
  try {
    const savedSection = await adminService.updatePatientSection(payload)
    dispatch({ type: types.UPDATE_PATIENT_SECTION_FULFILLED, payload: savedSection.data })
  } catch (error) {
    dispatch({ type: types.UPDATE_PATIENT_SECTION_REJECTED, payload: error })
  }
}

export const editPatientSection = (payload) => async (dispatch) => {
  dispatch({ type: types.EDIT_PATIENT_SECTION_REQUESTED })
  try {
    const editedSection = await adminService.editPatientSection(payload)
    dispatch({ type: types.EDIT_PATIENT_SECTION_FULFILLED, payload: editedSection.data })
  } catch (error) {
    dispatch({ type: types.EDIT_PATIENT_SECTION_REJECTED, payload: error })
  }
}

export const updateProgram = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PROGRAM_REQUESTED })
  try {
    await adminService.updateProgram(payload)
    dispatch({ type: types.UPDATE_PROGRAM_FULFILLED, payload })
  } catch (error) {
    dispatch({ type: types.UPDATE_PROGRAM_REJECTED, payload: error })
  }
}

export const updateProgramSettings = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PROGRAM_SETTINGS_REQUESTED })
  try {
    const program = await adminService.updateProgramSettings(payload)
    dispatch({ type: types.UPDATE_PROGRAM_SETTINGS_FULFILLED, payload: program.data })
  } catch (error) {
    dispatch({ type: types.UPDATE_PROGRAM_SETTINGS_REJECTED, payload: error })
  }
}

export const resetClinicalForm = (programId) => async (dispatch) => {
  dispatch({ type: types.RESET_CLINICAL_FORM_REQUESTED })
  try {
    const sections = await adminService.resetClinicalForm(programId)
    dispatch({ type: types.RESET_CLINICAL_FORM_FULFILLED, payload: sections.data })
  } catch (error) {
    dispatch({ type: types.RESET_CLINICAL_FORM_REJECTED, payload: error })
  }
}

export const resetPatientForm = (programId) => async (dispatch) => {
  dispatch({ type: types.RESET_PATIENT_FORM_REQUESTED })
  try {
    const sections = await adminService.resetPatientForm(programId)
    dispatch({ type: types.RESET_PATIENT_FORM_FULFILLED, payload: sections.data })
  } catch (error) {
    dispatch({ type: types.RESET_PATIENT_FORM_REJECTED })
  }
}

export const editPrescriptionSection = (payload) => async (dispatch) => {
  dispatch({ type: types.EDIT_PRESCRIPTION_SECTION_REQUESTED })
  try {
    const editedSection = await adminService.editPrescriptionSection(payload)
    dispatch({ type: types.EDIT_PRESCRIPTION_SECTION_FULFILLED, payload: editedSection.data })
  } catch (error) {
    dispatch({ type: types.EDIT_PRESCRIPTION_SECTION_REJECTED, payload: error })
  }
}

export const sortPrograms = (payload) => (dispatch) => dispatch({ type: types.SORT_PROGRAMS, payload })

export const searchPrograms = (payload) => (dispatch) => dispatch({ type: types.SEARCH_PROGRAMS, payload })

export const updateStep = (payload) => (dispatch) => dispatch({ type: types.UPDATE_STEP, payload })

export const updateAction = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ACTION, payload })

export const addLibraryField = (payload) => (dispatch) => dispatch({ type: types.ADD_LIBRARY_FIELD, payload })

export const removeLibraryField = (payload) => (dispatch) => dispatch({ type: types.REMOVE_LIBRARY_FIELD, payload })

export const addUserDefinedField = () => (dispatch) => dispatch({ type: types.ADD_USER_DEFINED_FIELD })

export const removeUserDefinedField = (payload) => (dispatch) => dispatch({ type: types.REMOVE_USER_DEFINED_FIELD, payload })

export const updateElementCaption = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ELEMENT_CAPTION, payload })

export const updateSectionCaption = (payload) => (dispatch) => dispatch({ type: types.UPDATE_SECTION_CAPTION, payload })

export const updateSectionSubCaption = (payload) => (dispatch) => dispatch({ type:  types.UPDATE_SECTION_SUB_CAPTION, payload })

export const updateUserDefinedCaption = (payload) => (dispatch) => dispatch({ type: types.UPDATE_USER_DEFINED_CAPTION, payload })

export const updateElementFieldType = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ELEMENT_FIELD_TYPE, payload })

export const updateElementOptions = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ELEMENT_OPTIONS, payload })

export const updateAllElementsChecked = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ALL_ELEMENTS_CHECKED, payload })

export const updateElementChecked = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ELEMENT_CHECKED, payload })

export const clearUpdateProviderSection = () => (dispatch) => dispatch({ type: types.CLEAR_UPDATE_PROVIDER_SECTION })

export const clearUpdatePatientSection = () => (dispatch) => dispatch({ type: types.CLEAR_UPDATE_PATIENT_SECTION })

export const updateOptionCaption = (payload) => (dispatch) => dispatch({ type: types.UPDATE_OPTION_CAPTION, payload })

export const updateAttestationElementCaption = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ATTESTATION_ELEMENT_CAPTION, payload })

export const updateDemographicCheckbox = (payload) => (dispatch) => dispatch({ type: types.UPDATE_DEMOGRAPHIC_CHECKBOX, payload })

export const updateAllDemographicCheckbox = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ALL_DEMOGRAPHIC_CHECKBOX, payload })

export const clearCreateAndUpdateProgram = () => (dispatch) => dispatch({ type: types.CLEAR_CREATE_AND_UPDATE_PROGRAM })