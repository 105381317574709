import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { BsPlus, BsTrash } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import OptionsModal from '../../../components/OptionsModal'
import PrimaryButton from '../../../components/PrimaryButton'
import StyledH3 from '../../../components/StyledH3'
import { addLibraryField, addUserDefinedField, getFormSection, removeLibraryField, removeUserDefinedField, updateAllElementsChecked, updateElementCaption, updateElementChecked, updateElementFieldType, updateElementOptions, updateSectionCaption, updateUserDefinedCaption, saveProviderSection } from '../../../features/admin/adminAction'

const EditSection = () => {
  const [show, setShow] = useState(false)
  const [elementId, setElementId] = useState(0)

  const { formSectionId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { program, getFormSectionState, section, allElementsActive, elements, library, userDefined, saveProviderSectionState } = useSelector((state) => ({
    program: state.admin.program,
    getFormSectionState: state.admin.getFormSection,
    section: state.admin.section,
    allElementsActive: state.admin.allElementsActive,
    elements: state.admin.elements,
    library: state.admin.library,
    userDefined: state.admin.userDefined,
    saveProviderSectionState: state.admin.saveProviderSection
  }))

  useEffect(() => {
    dispatch(getFormSection({ formSectionId, programId: program.programId }))
  }, [])

  useEffect(() => {
    if (saveProviderSectionState.success) {
      navigate(-1)
    }
  }, [saveProviderSectionState.success])

  const handleUpdateAllChecked = () => {
    dispatch(updateAllElementsChecked({ checked: !allElementsActive }))
  }
  
  const handleChangeSectionCaption = (event) => {
    dispatch(updateSectionCaption(event.target.value))
  }

  const handleUpdateElementChecked = (elementId, checked) => {
    dispatch(updateElementChecked({ elementId, checked }))
  }

  const handleRemoveField = (elementId) => {
    dispatch(removeLibraryField(elementId))
  }

  const handleUpdateElementFieldType = (value, id) => {
    dispatch(updateElementFieldType({ value, id }))
  }

  const handleChangeElementCaption = (value, id) => {
    dispatch(updateElementCaption({ value, id }))
  }

  const handleSelectField = (event) => {
    dispatch(addLibraryField(event.target.value))
  }

  const handleAddUserDefinedField = () => {
    dispatch(addUserDefinedField())
  }

  const handleChangeUserDefinedCaption = (value, id) => {
    dispatch(updateUserDefinedCaption({ value, id }))
  }

  const handleRemoveUserDefinedField = (id) => {
    dispatch(removeUserDefinedField(id))
  }

  const handleOpenOptionsModal = (id) => {
    setShow(true)
    setElementId(id)
  }

  const handleCloseOptionsModal = () => {
    setShow(false)
    setElementId(0)
  }

  const handleUpdateOptions = (options, includeOther) => {
    dispatch(updateElementOptions({ options, includeOther, elementId }))
    setShow(false)
    setElementId(0)
  }

  const renderFieldTypeSelector = (element) => {
    if ([3, 4, 5, 6].includes(element.elementTypeId) && element.elementId > 0) {
      return (
        <Form.Control
          as='select'
          size='sm'
          className='me-2'
          value={element.elementTypeId}
          onChange={(event) => handleUpdateElementFieldType(parseInt(event.target.value), element.elementId)}
        >
          <option value={3}>Dropdown</option>
          <option value={4}>Radio Group</option>
          <option value={5}>Single Choice - Checkbox</option>
          <option value={6}>Multi Choice - Checkbox</option>
        </Form.Control>
      )
    }
    return element.elementType
  }

  const handleSaveSection = () => {
    dispatch(saveProviderSection({ programId: program.programId, section, elements, userDefined }))
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <Link
            to='/'
          >
            <u>
              <StyledH3 color='#070f0d'>
                Forms
              </StyledH3>
            </u>
          </Link>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getFormSectionState.loading && (
          <div>
            Fetching Section...
          </div> 
        )
      }
      {
        getFormSectionState.success && (
          <>
            <Row>
              <Col md={10} xs={9}>
                <StyledH3>
                  Clinical Form: {section.caption}
                </StyledH3>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleSaveSection}
                  disabled={saveProviderSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  responsive
                >
                  <thead>
                    <tr>
                      <th>
                        <div className="form-checkbox form-checkbox-secondary pb-2">
                          <input 
                            id="all" 
                            type="checkbox" 
                            checked={allElementsActive} 
                            onChange={handleUpdateAllChecked}
                          />
                          <label htmlFor="all">

                          </label>
                        </div>
                      </th>
                      <th>
                        Field Type
                      </th>
                      <th style={{ width: '36rem' }}>
                        Default Caption
                      </th>
                      <th style={{ width: '20rem' }}>
                        Custom Caption
                      </th>
                      <th>
                        Auto-Fill
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td>Section Header</td>
                      <td>{section.caption}</td>
                      <td>
                        <Form.Control
                          size='sm'
                          value={section.customCaption}
                          onChange={handleChangeSectionCaption}
                        />
                      </td>
                      <td />
                    </tr>
                    {
                      elements.map((element) => (
                        <>
                          <tr key={`element-${element.elementId}`}>
                            {
                              element.configurable === 1 && element.default === 1 ? (
                                <td className='pt-0'>
                                  <div className="form-checkbox form-checkbox-primary">
                                    <input 
                                      id={element.elementId} 
                                      type="checkbox" 
                                      checked={element.checked}
                                      onChange={() => handleUpdateElementChecked(element.elementId, !element.checked)}
                                    />
                                    <label htmlFor={element.elementId}>

                                    </label>
                                  </div>
                                </td>
                              ) : element.default === 0 ? (
                                <td>
                                  <Button
                                    size='sm'
                                    variant='outline-danger'
                                    onClick={() => handleRemoveField(element.elementId)}

                                  >
                                    <BsTrash />
                                  </Button>
                                </td>
                              ) : <td></td>
                            }
                            <td>
                              <Row>
                                <Col>
                                  {renderFieldTypeSelector(element)}
                                </Col>
                                <Col>
                                  {
                                    [3, 4, 5, 6].includes(element.elementTypeId) && element.elementId > 0 && (
                                      <Button
                                        size='sm'
                                        variant='link'
                                        onClick={() => handleOpenOptionsModal(element.elementId)}
                                      >
                                        Edit
                                      </Button>
                                    )
                                  }
                                </Col>
                              </Row>
                            </td>
                            <td>{element.caption}</td>
                            <td>
                              {
                                !element.customCaption.includes('N/A') ? (
                                  <Form.Control
                                    size='sm'
                                    value={element.customCaption}
                                    onChange={(event) => handleChangeElementCaption(event.target.value, element.elementId)}
                                  />
                                ) : element.customCaption
                              }
                              
                            </td>
                            <td>
                              {element.autoFilled === 1 && 'Yes'}
                            </td>
                          </tr>
                        </>
                      ))
                    }
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <Form.Control
                          as='select'
                          size='sm'
                          aria-label='Select Field From Library'
                          onChange={handleSelectField}
                        >
                          <option hidden>Select Field From Library</option>
                          {
                            library.map((l) => (
                              <option key={l.caption} value={l.elementId}>{l.caption}</option>
                            ))
                          }
                        </Form.Control>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    {
                      userDefined.map((ud) => (
                        <tr key={ud.userDefinedId}>
                          <td>
                            <Button
                              size='sm'
                              variant='outline-danger'
                              onClick={() => handleRemoveUserDefinedField(ud.userDefinedId)}
                            >
                              <BsTrash />
                            </Button>
                          </td>
                          <td>
                            Text
                          </td>
                          <td>
                            {ud.caption}
                          </td>
                          <td>
                            <Form.Control
                              size='sm'
                              value={ud.customCaption}
                              onChange={(event) => handleChangeUserDefinedCaption(event.target.value, ud.userDefinedId)}
                            />
                          </td>
                          <td></td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span className='mr-2'>User Defined</span>
                        <Button variant='outline-success' size='sm' onClick={handleAddUserDefinedField}>
                          <BsPlus className='mr-2' />
                          Add
                        </Button>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button 
                  variant='outline-danger' 
                  size='sm' 
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleSaveSection}
                  disabled={saveProviderSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
            <OptionsModal
              show={show}
              elementId={elementId}
              elements={elements}
              onClose={handleCloseOptionsModal}
              onSubmit={handleUpdateOptions}
            />
          </>
        )
      }
    </div>
  )
}

export default EditSection