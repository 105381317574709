import React from 'react'
import AppRoutes from './routes'
import { Provider } from 'react-redux'
import configureStore from './app/store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/App.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-datepicker/dist/react-datepicker.css'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  )
}

export default App
