import React, { Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../../components/PrimaryButton'
import StyledH3 from '../../../../components/StyledH3'
import StyledH4 from '../../../../components/StyledH4'
import { useDispatch, useSelector } from 'react-redux'
import { clearUpdatePatientSection, clearUpdateProviderSection, updateProgram, updateStep, resetClinicalForm, resetPatientForm } from '../../../../features/admin/adminAction'

const getSectionStatus = (status) => {
  if (status === 'TO DO') {
    return 'text-success'
  }
  return 'text-secondary'
}

const Landing = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { program, sections, patientSections, updateProgramState, resetClinicalFormState, resetPatientFormState } = useSelector((state) => ({
    program: state.admin.program,
    sections: state.admin.sections,
    patientSections: state.admin.patientSections,
    updateProgramState: state.admin.updateProgram,
    resetClinicalFormState: state.admin.resetClinicalForm,
    resetPatientFormState: state.admin.resetPatientForm
  }))

  const handleNavigateToSection = (section) => {
    dispatch(clearUpdatePatientSection())
    const action = section.formSection ? 'edit' : 'add'
    navigate(`/form/${action}/patient/${section.formSection ? section.formSection.formSectionId : section.sectionId}`)
  }

  const fontColor = (status) => {
    if (status === 'Draft') return 'text-secondary'

    if (status === 'Inactive') return 'text-danger'

    return 'text-success'
  }

  const handleUpdateProgram = () => {
    const status = program.status === 'Draft' || program.status === 'Inactive' ? 'Active' : 'Inactive'
    dispatch(updateProgram({ programId: program.programId, status }))
  }

  const handleResetClinicalForm = () => {
    dispatch(resetClinicalForm(program.programId))
  }

  const handleResetPatientForm = () => {
    dispatch(resetPatientForm(program.programId))
  }

  const buildNavigationSectionLink = (section) => {
    const action = section.formSection ? 'edit' : 'add'
    const page = section.sectionId === 4
      ? 'prescription' 
      : section.sectionId === 12
        ? 'attestation' 
        : 'section'

    return `/form/${action}/${page}/${section.formSection ? section.formSection.formSectionId : section.sectionId}`
  }

  const clinicalPreviewDisabled = sections.some((section) => section.status === 'TO DO')
  const clinicalResetDisabled = sections.every((section) => section.status === 'TO DO') || resetClinicalFormState.loading

  const patientPreviewDisabled = patientSections.some((section) => section.status === 'TO DO')
  const patientResetDisabled = patientSections.every((section) => section.status === 'TO DO') || resetPatientFormState.loading

  const statusButtonDisabled = sections.some((section) => section.status === 'TO DO') || patientSections.some((section) => section.status === 'TO DO') || updateProgramState.loading

  return (
    <div className='mt-2'>
      <Row>
        <Col md={1} xs={3}>
          <Link
            to='/'
          >
            <u>
              <StyledH3 color='#070f0d'>
                Forms
              </StyledH3>
            </u>
          </Link>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      <Row>
        <Col>
          <Row>
            <Col md={7}>
              <StyledH3 color='#070f0d' className='font-weight-bold'>
                Program Status
              </StyledH3>
            </Col>
            <Col md={2}>
              <StyledH4 
                className={`h5 font-weight-bold ${fontColor(program.status)}`}
              >
                {program.status}
              </StyledH4>
            </Col>
            <Col md={2}>
              <PrimaryButton
                color={statusButtonDisabled ? '#a5a9af' : '#3B6E35'}
                secondcolor={statusButtonDisabled ? '#a5a9af' : '#5FAD56'}
                disabled={statusButtonDisabled}
                size='sm'
                onClick={handleUpdateProgram}
                className='ms-3'
              >
                {program.status === 'Draft' || program.status === 'Inactive' ? 'ACTIVATE' : 'INACTIVATE'}
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={8}>
              <StyledH3 color='#070f0d' className='font-weight-bold'>
                Program Settings
              </StyledH3>
            </Col>
            <Col>
              <PrimaryButton
                color='#3B6E35'
                secondcolor='#5FAD56'
                size='sm'
                onClick={() => dispatch(updateStep('settings'))}
              >
                Edit
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={9}>
              <StyledH3 color='#070f0d' className='font-weight-bold'>
                Patient Demographics
              </StyledH3>
            </Col>
            <Col>
              <PrimaryButton
                color='#3B6E35'
                secondcolor='#5FAD56'
                size='sm'
                onClick={() => dispatch(updateStep('demographics'))}
              >
                Edit
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className='mt-1' />
      <Row>
        <Col md={6}>
          <Row>
            <Col md={4}>
              <StyledH3 color='#070f0d'>Clinical Form</StyledH3>
            </Col>
            <Col md={4}>
              <div className='mt-1'>
                Form Type: {program?.programType?.name}
              </div>
            </Col>
            <Col md={4} className='float-md-right'>
              <Button
                variant={clinicalPreviewDisabled ? 'outline-secondary' : 'outline-success'}
                disabled={clinicalPreviewDisabled}
                size='sm'
                className='mr-2'
                onClick={() => navigate(`/form/provider/${program.programId}`)}
              >
                Preview
              </Button>
              <Button
                variant={clinicalResetDisabled ? 'outline-secondary' : 'outline-danger'}
                disabled={clinicalResetDisabled}
                size='sm'
                onClick={handleResetClinicalForm}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col className='h5 text-muted'>
                Column 1
            </Col>
          </Row>
          {
            sections
              .filter((section) => section.column === 1)
              .map((section) => (
                <Row key={section.text} className='mt-1'>
                  <Col className='h5' xs={9}>
                    {section.formSection ? section.formSection.text.replace('[Medication Name]', program.medicationName.toUpperCase()) : section.text}
                  </Col>
                  <Col xs={1}>
                    <Link
                      to={buildNavigationSectionLink(section)}
                      onClick={() => dispatch(clearUpdateProviderSection())}
                    >
                      <PrimaryButton
                        color='#3B6E35'
                        secondcolor='#5FAD56'
                        size='sm'
                      >
                      Edit
                      </PrimaryButton>
                    </Link>
                  </Col>
                  <Col xs={2}>
                    <span className={`${getSectionStatus(section.status)} font-weight-bold`}>
                      {section.status}
                    </span>
                  </Col>
                </Row>
              ))
          }
          <Row className='mt-2'>
            <Col className='h5 text-muted'>
                Column 2
            </Col>
          </Row>
          {
            sections
              .filter((section) => section.column === 2)
              .map((section) => (
                <Row key={section.text} className='mt-1'>
                  <Col className='h5' xs={9}>
                    {section.text}
                  </Col>
                  <Col xs={1}>
                    <Link
                      to={buildNavigationSectionLink(section)}
                      onClick={() => dispatch(clearUpdateProviderSection())}
                    >
                      <PrimaryButton
                        color='#3B6E35'
                        secondcolor='#5FAD56'
                        size='sm'
                      >
                        Edit
                      </PrimaryButton>
                    </Link>
                  </Col>
                  <Col xs={2}>
                    <span className={`${getSectionStatus(section.status)} font-weight-bold`}>
                      {section.status}
                    </span>
                  </Col>
                </Row>
              ))
          }
        </Col>
        <Col md={6}>
          <Row>
            <Col md={8}>
              <StyledH3 color='#070f0d'>Patient Enrollment Form</StyledH3>
            </Col>
            <Col md={4} className='float-md-right'>
              <Button
                variant={patientPreviewDisabled ? 'outline-secondary' : 'outline-success'}
                disabled={patientPreviewDisabled}
                size='sm'
                className='mr-2'
                onClick={() => navigate(`/form/patient/intro/${program.programId}`)}
              >
                Preview
              </Button>
              <Button
                variant={patientResetDisabled ? 'outline-secondary' : 'outline-danger'}
                disabled={patientResetDisabled}
                size='sm'
                onClick={handleResetPatientForm}
              >
                Reset
              </Button>
            </Col>
          </Row>
          {
            patientSections.map((patientSection, index) => (
              <Fragment key={`patientSection-${patientSection.sectionId}`}>
                <Row className='mt-2'>
                  <Col className='h5 text-muted'>
                    {index === 0 ? 'Introductory Page' : `Step ${index}`}
                  </Col>
                </Row>
                <Row>
                  <Col className='h5' xs={9}>
                    {patientSection.text}
                  </Col>
                  <Col xs={1}>
                    <PrimaryButton
                      color='#3B6E35'
                      secondcolor='#5FAD56'
                      size='sm'
                      onClick={() => handleNavigateToSection(patientSection)}
                    >
                    Edit
                    </PrimaryButton>
                  </Col>
                  <Col xs={2}>
                    <span className={`${getSectionStatus(patientSection.status)} font-weight-bold`}>
                      {patientSection.status}
                    </span>
                  </Col>
                </Row>
              </Fragment>
            ))
          }
        </Col>
      </Row>
    </div>
  )
}

export default Landing