/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import format from 'date-fns/format'

const FormCheckOrGroup = ({ name, type, options, values, updateValue }) => {
  return (
    <>
      <Form.Group>
        <Form.Check
          id={name}
          type={type}
        >
          <Form.Check.Input 
            name={name}
            type={type}
            value={options[0].option.value}
            checked={values ? values[name].value === options[0].option.value : false}
            onChange={(event) => updateValue(name, event.target.value)}
          />
          <Form.Check.Label dangerouslySetInnerHTML={{ __html: options[0].option.label.replace('[DATE]', format(new Date(), 'MM/dd/yyyy')) }} className='fw-bold' />
        </Form.Check>
      </Form.Group>
      <p>OR</p>
      <Form.Group>
        <Form.Check
          id={name}
          type={type}
          className='mb-3'
        >
          <Form.Check.Input 
            name={name}
            type={type}
            value={options[1].option.value}
            checked={values ? values[name].value === options[1].option.value : false}
            onChange={(event) => updateValue(name, event.target.value)}
          />
          <Form.Check.Label dangerouslySetInnerHTML={{ __html: options[1].option.label.replace('[DATE]', format(new Date(), 'MM/dd/yyyy')) }} />
        </Form.Check>
      </Form.Group>
    </>
  )
}

FormCheckOrGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.any,
  values: PropTypes.any,
  updateValue: PropTypes.func
}

export default FormCheckOrGroup