import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHealthSystem, startSession } from '../../features/user/userAction'
import { Alert, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { getAccessToken } from '../../features/auth/authAction'
import { Helmet } from 'react-helmet'
import { getApiServer } from '../../utils/helpers'

import '../../assets/styles/rxenroll.css'

const server = getApiServer()

const PatientLayout = () => {
  const dispatch = useDispatch()

  const {
    accessTokenRecieved,
    isFetching,
    userFriendlyStatus,
    healthSystem,
    sessionState,
    authError
  } = useSelector((state) => ({
    accessTokenRecieved: state.auth.accessTokenRecieved,
    isFetching: state.auth.isFetching,
    userFriendlyStatus: state.auth.userFriendlyStatus,
    healthSystem: state.user.healthSystem,
    sessionState: state.user.startSession,
    authError: state.auth.error
  }))

  useEffect(() => {
    if (accessTokenRecieved) {
      dispatch(getHealthSystem())
    } else {
      dispatch(getAccessToken(window.location.href))
    }
  }, [accessTokenRecieved])

  useEffect(() => {
    if (healthSystem.success && !sessionState.success) {
      dispatch(startSession('patient'))
    }
  }, [healthSystem.success, sessionState.success])

  const getLoadingMessage = () => (healthSystem.loading || sessionState.loading) ? 'Fetching information...' : userFriendlyStatus

  const getErrorMessage = () => (healthSystem.error !== false || sessionState.error !== false) ? 'Unable to gather information' : authError.description

  return (
    <Container fluid className='m-0 p-0' id='rx'>
      <Helmet>
        <title>Rx Enrollment :: Patient</title>
      </Helmet>
      <header>
        <Container className='pt-3'>
          <Row>
            <Col>
              <h1>Rx Enrollment Patient</h1>
              <p>
                <Image src={`${server}/static/images/pocp/RxEnrollment.png`} width='113' height='64' alt="Rx Enrollment Logo" />
              </p>
            </Col>
          </Row>
        </Container>
      </header>
      <>
        {
          (isFetching || healthSystem.loading || sessionState.loading) ? (
            <div className='text-center'>
              <Spinner animation='border' role='status' />
              <div>
                {getLoadingMessage()}
              </div>
            </div>
          ) : (authError !== null || healthSystem.error !== false || sessionState.error !== false) ? (
            <Container className='pt-5'>
              <Alert variant='danger'>
                <Alert.Heading>Error</Alert.Heading>
                <div>{getErrorMessage()}</div>
              </Alert>
            </Container>
          ) : <Outlet />
        }
      </>
    </Container>
    // <Container fluid className='m-0 p-0' id='program'>
    //   {
    //     getProgramState.success && (
    //       <>
    //         <Helmet>
    //           <title>
    //             Rx Enrollment :: {program.name}
    //           </title>
    //         </Helmet>
    //         <header>
    //           <ProgramHeader />
    //         </header>
    //         <div>
    //           <Container className='shadow p-0 bg-white mb-4 rounded'>
    //             <Container className='m-0 p-0'>
    //               <PatientHeader
    //                 color={program.tertiaryColor}
    //                 className='px-3 py-2'
    //               >
    //                 {
    //                   programHasDemographic(1, program.patientDemographics) && (
    //                     <>
    //                       <dt>Patient Name:</dt>
    //                       <dd> {getPatientName(patient)}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(2, program.patientDemographics) && (
    //                     <>
    //                       <dt>MRN:</dt>
    //                       <dd> {patient.mrn}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(3, program.patientDemographics) && (
    //                     <>
    //                       <dt>Gender:</dt>
    //                       <dd> {getPatientGender(patient)}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(4, program.patientDemographics) && (
    //                     <>
    //                       <dt>DOB:</dt>
    //                       <dd> {getPatientBirthDate(patient)}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(5, program.patientDemographics) && (
    //                     <>
    //                       <dt>Age:</dt>
    //                       <dd> {calculateAge(getPatientBirthDate(patient))}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(6, program.patientDemographics) && (
    //                     <>
    //                       <dt>Weight:</dt>
    //                       <dd> {patient && patient.measurements ? generateWeightMeasurement(patient, 'kg') : ''}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(7, program.patientDemographics) && (
    //                     <>
    //                       <dt>Weight:</dt>
    //                       <dd> {patient && patient.measurements ? generateWeightMeasurement(patient, 'ibs') : ''}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(8, program.patientDemographics) && (
    //                     <>
    //                       <dt>Height:</dt>
    //                       <dd> {patient && patient.measurements ? generateHeightMeasurement(patient, 'cm') : ''}</dd>
    //                     </>
    //                   )
    //                 }
    //                 {
    //                   programHasDemographic(9, program.patientDemographics) && (
    //                     <>
    //                       <dt>Height:</dt>
    //                       <dd> {patient && patient.measurements ? generateHeightMeasurement(patient, 'ft') : ''}</dd>
    //                     </>
    //                   )
    //                 }
    //               </PatientHeader>
    //             </Container>
    //             <Container className='m-0 pt-0 pl-3 pb-2'>
    //               <Outlet />
    //             </Container>
    //           </Container>
    //         </div>
    //         <footer>
    //           <Container className='small'>
    //             <Row>
    //               <Col xs={12}>
    //                 <div className='text-right' dangerouslySetInnerHTML={{ __html:  program.footerTemplate ? program.footerTemplate : <div /> }}></div>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </footer>
    //       </>
    //     )
    //   }
    // </Container>
  )
}

export default PatientLayout