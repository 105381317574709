import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const PrimaryButton = styled(Button)`
    background-color: ${props => props.color};
    border: 1px solid ${props => props.color};
    &:hover {
        background-color: ${props => props.secondcolor};
        border: 1px solid ${props => props.secondcolor};
    }
    &:disabled {
        background-color: ${props => props.secondcolor};
	    border: 1px solid ${props => props.secondcolor};
    }
`

export default PrimaryButton