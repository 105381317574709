import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import PatientHeader from '../../../components/PatientHeader'
import { getPatientBirthDate, getPatientName } from '../../../utils/helpers'
import Enrollment from '../../../components/Enrollment'
import CoPay from '../../../components/CoPay'

const ALERTED_STATUSES = ['CO_PAY_OFFER_SENT', 'SENT_TO_POOL', 'PRACTICE_SIGNED']

const Patient = () => {
  const { session } = useSelector((state) => ({
    session: state.user.session
  }))

  const filteredActions = session ? session.actions.filter((action) => action.logs.some((log) => ALERTED_STATUSES.includes(log.status))) : []

  const renderComponent = (action) => {
    switch (action.applicationId) {
    case 1:
      return <Enrollment action={action} />
    case 2:
      return <CoPay action={action} />
    default:
      return <div>Hi</div>
    }
  }

  return (
    <Container className='shadow p-0 bg-white mb-4'>
      <Container className='m-0 p-0'>
        <PatientHeader
          className='px-3 py-2'
          color='#e0e1e1'
        >
          <dt>Patient Name:</dt>
          <dd> {session ? getPatientName(session.patient) : ''}</dd>
          <dt>MRN:</dt>
          <dd> {session ? session.patient.mrn : ''}</dd>
          <dt>DOB:</dt>
          <dd> {session ? getPatientBirthDate(session.patient) : ''}</dd>
        </PatientHeader>
      </Container>
      <Container className='m-0 pt-0 pl-3 pb-2'>
        <div>
          <div className='d-flex justify-content-between'>
            <div>
              Program/Offer
            </div>
            <div>
              Action(s)
            </div>
          </div>
          <hr className='custom-hr mt-0' />
        </div>
        {
          filteredActions.map((action) => (
            <div key={`action_${action.sessionActionId}`}>
              {renderComponent(action)}
              <hr />
            </div>
          ))
        }
      </Container>
    </Container>

  )
}

export default Patient