import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../../../components/PrimaryButton'
import StyledH3 from '../../../../components/StyledH3'
import { BsPlus, BsTrash } from 'react-icons/bs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { FieldArray, useFormik, FormikProvider } from 'formik'
import { NewFormSchema } from '../../../../utils/validation'
import PropTypes from 'prop-types'

const Settings = ({ program, action, programTypes, manufacturers, createNewProgramState, createNewProgram, getProgramState, updateProgramSettings, updateProgramSettingsState }) => {

  const [loading, setLoading] = useState(action === 'edit')

  const form = useFormik({
    enableReinitialize: false,
    initialValues: {
      drugName: '',
      includeRegisteredTrademark: false,
      programName: '',
      appendToDrugName: false,
      manufacturer: '',
      programType: '',
      diagnosisCode: '',
      diagnosisDescription: '',
      primaryDiagnoses: [],
      rxCode: '',
      rxDescription: '',
      rxNormCodes: [],
      programContactName: '',
      programContactPhone: '',
      programContactEmail: '',
      includeProgramFooter: false,
      programFooter: EditorState.createEmpty()
    },
    validationSchema: NewFormSchema,
    onSubmit: (values) => {
      if (action === 'edit') {
        updateProgramSettings({
          programId: program.programId,
          ...values,
          programFooter: values.includeProgramFooter ? draftToHtml(convertToRaw(values.programFooter.getCurrentContent())) : null
        })
      } else {
        createNewProgram({
          ...values,
          programFooter: values.includeProgramFooter ? draftToHtml(convertToRaw(values.programFooter.getCurrentContent())) : null
        })
      }
    }
  })

  useEffect(() => {
    if (action === 'edit') {
      if (getProgramState.success) {
        const diagnosisCodes = []
        const rxNormCodes = []
        for (let i = 0; i < program.codes.length; i += 1) {
          const { code } = program.codes[i]
  
          if (code.type === 'diagnosis') {
            diagnosisCodes.push({
              code: code.code,
              description: code.name
            })
          }
  
          if (code.type === 'rx') {
            rxNormCodes.push({
              code: code.code,
              description: code.name
            })
          }
        }
        let editorState = EditorState.createEmpty()
  
        if (program.footerTemplate !== null) {
          const blocksFromHtml = htmlToDraft(program.footerTemplate)
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
          editorState = EditorState.createWithContent(contentState)
        }
        
        form.setValues({
          drugName: program.medicationName,
          includeRegisteredTrademark: program.includeRegisteredTrademark === 1,
          programName: program.name,
          appendToDrugName: false,
          manufacturer: program.manufacturerId,
          programType: program.programTypeId,
          primaryDiagnoses: diagnosisCodes,
          rxNormCodes: rxNormCodes,
          programContactName: program.contactName,
          programContactPhone: program.contactPhone,
          programContactEmail: program.contactEmail,
          includeProgramFooter: program.footerTemplate !== null,
          programFooter: editorState
        })

        setLoading(false)
      }
    }
  }, [action, getProgramState.success])

  const handleAddRxCode = (push) => {
    if (form.values.rxCode && form.values.rxDescription) {
      const field = {
        code: form.values.rxCode,
        description: form.values.rxDescription,
        edit: false
      }

      form.setValues({
        ...form.values,
        rxCode: '',
        rxDescription: ''
      })

      push(field)
    } else {
      
      if (!form.values.rxCode) {
        form.setFieldError('rxCode', 'This field is required.')
      }
      
      if (!form.values.rxDescription) {
        form.setFieldError('rxDescription', 'This field is required.')  
      }
    }
  }

  const handleAddDiagnosisCode = (push) => {
    if (form.values.diagnosisCode && form.values.diagnosisDescription) {
      const field = {
        code: form.values.diagnosisCode,
        description: form.values.diagnosisDescription,
        edit: false
      }

      form.setTouched({
        ...form.touched,
        diagnosisCode: false,
        diagnosisDescription: false
      })

      form.setValues({
        ...form.values,
        diagnosisCode: '',
        diagnosisDescription: ''
      })

      push(field)
    } else {

      if (!form.values.diagnosisCode) {
        form.setFieldError('diagnosisCode', 'This field is required.')
      }
      
      if (!form.values.diagnosisDescription) {
        form.setFieldError('diagnosisDescription', 'This field is required.')
      }
    }
  }

  const handleEditRxCode = (replace, index) => {
    if (!form.values.rxNormCodes[index].edit) {
      replace(index, {
        ...form.values.rxNormCodes[index],
        edit: true
      })
    }

    if (form.values.rxNormCodes[index].edit) {
      if (form.values.rxNormCodes[index].code && form.values.rxNormCodes[index].description) {
        const field = {
          code: form.values.rxNormCodes[index].code,
          description: form.values.rxNormCodes[index].description,
          edit: false
        }
  
        replace(index, field)
      } else {
        form.setTouched({
          ...form.touched,
          rxNormCodes: [
            ...form.touched.rxNormCodes.slice(0, index),
            {
              code: true,
              description: true
            },
            ...form.touched.rxNormCodes.slice(index + 1)
          ]
        })
  
        if (!form.values.rxNormCodes[index].code) {
          form.setErrors({
            ...form.errors,
            rxNormCodes: [
              ...form.errors.rxNormCodes.slice(0, index),
              {
                ...form.errors.rxNormCodes[index],
                code: 'This field is required.'
              },
              ...form.touched.rxNormCodes.slice(index + 1)
            ]
          })
        }
        
        if (!form.values.rxNormCodes[index].description) {
          form.setErrors({
            ...form.errors,
            rxNormCodes: [
              ...form.errors.rxNormCodes.slice(0, index),
              {
                ...form.errors.rxNormCodes[index],
                description: 'This field is required.'
              },
              ...form.touched.rxNormCodes.slice(index + 1)
            ]
          })
        }
      }
    }
  }

  const handleEditDiagnosisCode = (replace, index) => {
    if (!form.values.primaryDiagnoses[index].edit) {
      replace(index, {
        ...form.values.primaryDiagnoses[index],
        edit: true
      })
    }

    if (form.values.primaryDiagnoses[index].edit) {
      if (form.values.primaryDiagnoses[index].code && form.values.primaryDiagnoses[index].description) {
        const field = {
          code: form.values.primaryDiagnoses[index].code,
          description: form.values.primaryDiagnoses[index].description,
          edit: false
        }
  
        replace(index, field)
      } else {
        form.setTouched({
          ...form.touched,
          primaryDiagnoses: [
            ...form.touched.primaryDiagnoses.slice(0, index),
            {
              code: true,
              description: true
            },
            ...form.touched.primaryDiagnoses.slice(index + 1)
          ]
        })
  
        if (!form.values.primaryDiagnoses[index].code) {
          form.setErrors({
            ...form.errors,
            primaryDiagnoses: [
              ...form.errors.primaryDiagnoses.slice(0, index),
              {
                ...form.errors.primaryDiagnoses[index],
                code: 'This field is required.'
              },
              ...form.touched.primaryDiagnoses.slice(index + 1)
            ]
          })
        }
        
        if (!form.values.primaryDiagnoses[index].description) {
          form.setErrors({
            ...form.errors,
            primaryDiagnoses: [
              ...form.errors.primaryDiagnoses.slice(0, index),
              {
                ...form.errors.primaryDiagnoses[index],
                description: 'This field is required.'
              },
              ...form.touched.primaryDiagnoses.slice(index + 1)
            ]
          })
        }
      }
    }
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col md={1} xs={3}>
          <Link
            to='/'
          >
            <u>
              <StyledH3 color='#070f0d'>
                Forms
              </StyledH3>
            </u>
          </Link>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {
              action === 'edit' ? 
                <div>
                  {program.medicationName ? program.medicationName : ''}{program.includeRegisteredTrademark ? program.includeRegisteredTrademark ? (<sup>&reg;</sup>) : '' : ''} {program.name ? program.name : ''}
                </div>
                : 'New Program'
            }
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-0' />
      {
        createNewProgramState.error && (
          <Alert variant='danger'>
            Unable to create new program.
          </Alert>
        )
      }
      {
        updateProgramSettingsState.error && (
          <Alert variant='danger'>
            Unable to edit program.
          </Alert>
        )
      }
      {
        loading ? (
          <div>
            Loading Program Details...
          </div>
        ) : (
          <FormikProvider value={form}>
            <Form noValidate onSubmit={form.handleSubmit}>
              <Row>
                <Col>
                  <StyledH3 
                    color='#070f0d' 
                    className='font-weight-bold'
                  >
            Program Settings
                  </StyledH3>
                </Col>
                <Col>
                  <PrimaryButton
                    color='#3B6E35'
                    secondcolor='#5FAD56'
                    className='float-md-right'
                    type='submit'
                    disabled={createNewProgramState.loading || updateProgramSettingsState.loading}
                  >
                    {
                      createNewProgramState.loading || updateProgramSettingsState.loading ? (
                        <Spinner />
                      ) : action === 'edit' ? 
                        'Save Changes' 
                        : 'Create New Program'
                    }
                  </PrimaryButton>
                </Col>
              </Row>
              <Row>
                <Form.Group as={Col} controlId='drugName' md={3} xs={12}>
                  <Form.Label>Drug Name</Form.Label>
                  <Form.Control
                    name='drugName'
                    value={form.values.drugName}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.drugName && form.touched.drugName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.drugName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId='includeRegisteredTrademark' md={1} xs={12}>
                  <Form.Check
                    style={{
                      marginTop: '2.2rem'
                    }}
                    type='checkbox'
                    label={<div className='h5'>&reg;</div>}
                    name='includeRegisteredTrademark'
                    checked={form.values.includeRegisteredTrademark}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='programName' md={3} xs={12}>
                  <Form.Label>Program Name</Form.Label>
                  <Form.Control
                    name='programName'
                    value={form.values.programName}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.programName && form.touched.programName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.programName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId='appendToDrugName' md={2}>
                  <Form.Check
                    style={{
                      marginTop: '2.2rem'
                    }}
                    type='checkbox'
                    label='Append to drug name'
                    name='appendToDrugName'
                    checked={form.values.appendToDrugName}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Group>
              </Row>
              <Row className='mt-2'>
                <Form.Group as={Col} md={3} controlId='manufacturer'>
                  <Form.Label>Manufacturer</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Manufacturer'
                    name='manufacturer'
                    value={form.values.manufacturer}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.manufacturer && form.touched.manufacturer}
                  >
                    <option hidden>Select</option>
                    {
                      manufacturers.map((manufacturer) => (
                        <option
                          key={`${manufacturer.name}-${manufacturer.manufacturerId}`} 
                          value={manufacturer.manufacturerId}
                        >
                          {manufacturer.name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.manufacturer}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mt-2'>
                <Form.Group as={Col} md={3} controlId='programType'>
                  <Form.Label>Program Type</Form.Label>
                  <Form.Control
                    as='select'
                    aria-label='Program Type'
                    name='programType'
                    value={form.values.programType}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.programType && form.touched.programType}
                  >
                    <option hidden>Select</option>
                    {
                      programTypes.map((programType) => (
                        <option 
                          key={`${programType.name}-${programType.programTypeId}`} 
                          value={programType.programTypeId}
                        >
                          {programType.name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.programType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr />
              <FieldArray name='rxNormCodes'>
                {
                  (arrayHelpers) => (
                    <>
                      <Row className='mt-4'>
                        <Col xs={12} className='h4'>
                          Drug Product(s)
                        </Col>
                      </Row>
                      <Row className='mt-2'>
                        <Form.Group as={Col} md={2} controlId='rxCode'>
                          <Form.Label>RxNorm Code</Form.Label>
                          <Form.Control 
                            name='rxCode'
                            value={form.values.rxCode}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            isInvalid={!!form.errors.rxCode}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {form.errors.rxCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={5} controlId='description'>
                          <Form.Label>Drug Product Display</Form.Label>
                          <Form.Control 
                            name='rxDescription'
                            value={form.values.rxDescription}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            isInvalid={!!form.errors.rxDescription}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {form.errors.rxDescription}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Col>
                          <Button variant='outline-info' size='sm' onClick={() => handleAddRxCode(arrayHelpers.push)} style={{ marginTop: '2.2rem'}}>
                            <BsPlus /> Add
                          </Button>
                        </Col>
                      </Row>
                      {
                        form.values.rxNormCodes.map((rxNormCode, index) => (
                          <Row key={`rx-${index}`} className='mt-3'>
                            <Form.Group as={Col} md={2} controlId={`rx-${index}-code`}>
                              {
                                form.values.rxNormCodes[index].edit ? (
                                  <>
                                    <Form.Control
                                      name={`rxNormCodes.${index}.code`}
                                      value={form.values.rxNormCodes[index].code}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      isInvalid={form.errors.rxNormCodes && form.errors.rxNormCodes[index] &&  !!form.errors.rxNormCodes[index].code && form.touched.rxNormCodes && form.touched.rxNormCodes[index].code}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      {form.errors.rxNormCodes && form.errors.rxNormCodes[index] && form.errors.rxNormCodes[index].code}
                                    </Form.Control.Feedback>
                                  </>

                                ) : (
                                  <>{form.values.rxNormCodes[index].code}</>
                                )
                              }
                            </Form.Group>
                            <Form.Group as={Col} md={5} controlId={`rx-${index}-description`}>
                              {
                                form.values.rxNormCodes[index].edit ? (
                                  <>
                                    <Form.Control
                                      name={`rxNormCodes.${index}.description`}
                                      value={form.values.rxNormCodes[index].description}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      isInvalid={form.errors.rxNormCodes && form.errors.rxNormCodes[index] && !!form.errors.rxNormCodes[index].description && form.touched.rxNormCodes && form.touched.rxNormCodes[index].description}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      {form.errors.rxNormCodes && form.errors.rxNormCodes[index] && form.errors.rxNormCodes[index].description}
                                    </Form.Control.Feedback>
                                  </>

                                ) : (
                                  <>{form.values.rxNormCodes[index].description}</>
                                )
                              }
                            </Form.Group>
                            <Col md={3}>
                              <Button 
                                variant={form.values.rxNormCodes[index].edit ? 'outline-primary' : 'outline-warning'}
                                size='sm'
                                className='mr-2'
                                onClick={() => handleEditRxCode(arrayHelpers.replace, index)}
                              >
                                {form.values.rxNormCodes[index].edit ? 'Save' : 'Edit'}
                              </Button>
                              <Button 
                                variant='outline-danger'
                                size='sm'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <BsTrash />
                              </Button>
                            </Col>
                          </Row>
                        ))
                      }
                      {
                        !!form.errors.rxNormCodes && form.touched.rxNormCodes && (
                          <Form.Text className='mt-2 text-danger'>
                            {form.errors.rxNormCodes}
                          </Form.Text>
                        )
                      }
                    </>
                  )
                }
              </FieldArray>
              <hr />
              <FieldArray name='primaryDiagnoses'>
                {
                  (arrayHelpers) => (
                    <>
                      <Row className='mt-4'>
                        <Col xs={12} className='h4'>
                        Primary Diagnosis(es)
                        </Col>
                      </Row>
                      <Row className='mt-2'>
                        <Form.Group as={Col} md={2} controlId='diagnosisCode'>
                          <Form.Label>ICD-10 Code</Form.Label>
                          <Form.Control 
                            name='diagnosisCode'
                            value={form.values.diagnosisCode}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            isInvalid={!!form.errors.diagnosisCode}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {form.errors.diagnosisCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={5} controlId='diagnosisDescription'>
                          <Form.Label>Diagnosis Display Description</Form.Label>
                          <Form.Control 
                            name='diagnosisDescription'
                            value={form.values.diagnosisDescription}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            isInvalid={!!form.errors.diagnosisDescription}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {form.errors.diagnosisDescription}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Col>
                          <Button variant='outline-info' size='sm' onClick={() => handleAddDiagnosisCode(arrayHelpers.push)} style={{ marginTop: '2.2rem'}}>
                            <BsPlus /> Add
                          </Button>
                        </Col>
                      </Row>
                      {
                        form.values.primaryDiagnoses.map((primaryDiagnosis, index) => (
                          <Row key={`diagnosis-${index}`} className='mt-3'>
                            <Form.Group as={Col} md={2} controlId={`diagnosis-${index}-code`}>
                              {
                                form.values.primaryDiagnoses[index].edit ? (
                                  <>
                                    <Form.Control
                                      name={`primaryDiagnoses.${index}.code`}
                                      value={form.values.primaryDiagnoses[index].code}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      isInvalid={form.errors.primaryDiagnoses && form.errors.primaryDiagnoses[index] &&  !!form.errors.primaryDiagnoses[index].code && form.touched.primaryDiagnoses && form.touched.primaryDiagnoses[index].code}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      {form.errors.primaryDiagnoses && form.errors.primaryDiagnoses[index] && form.errors.primaryDiagnoses[index].code}
                                    </Form.Control.Feedback>
                                  </>

                                ) : (
                                  <>{form.values.primaryDiagnoses[index].code}</>
                                )
                              }
                            </Form.Group>
                            <Form.Group as={Col} md={5} controlId={`diagnosis-${index}-description`}>
                              {
                                form.values.primaryDiagnoses[index].edit ? (
                                  <>
                                    <Form.Control
                                      name={`primaryDiagnoses.${index}.description`}
                                      value={form.values.primaryDiagnoses[index].description}
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                      isInvalid={form.errors.primaryDiagnoses && form.errors.primaryDiagnoses[index] && !!form.errors.primaryDiagnoses[index].description && form.touched.primaryDiagnoses && form.touched.primaryDiagnoses[index].description}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      {form.errors.primaryDiagnoses && form.errors.primaryDiagnoses[index] && form.errors.primaryDiagnoses[index].description}
                                    </Form.Control.Feedback>
                                  </>

                                ) : (
                                  <>{form.values.primaryDiagnoses[index].description}</>
                                )
                              }
                            </Form.Group>
                            <Col md={3}>
                              <Button 
                                variant={form.values.primaryDiagnoses[index].edit ? 'outline-primary' : 'outline-warning'}
                                size='sm'
                                className='mr-2'
                                onClick={() => handleEditDiagnosisCode(arrayHelpers.replace, index)}
                              >
                                {form.values.primaryDiagnoses[index].edit ? 'Save' : 'Edit'}
                              </Button>
                              <Button 
                                variant='outline-danger'
                                size='sm'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <BsTrash />
                              </Button>
                            </Col>
                          </Row>
                        ))
                      }
                      {
                        !!form.errors.primaryDiagnoses && form.touched.primaryDiagnoses && (
                          <Form.Text className='mt-2 text-danger'>
                            {form.errors.primaryDiagnoses}
                          </Form.Text>
                        )
                      }
                    </>
                  )
                }
              </FieldArray>
              <hr />
              <Row className='mt-4'>
                <Form.Group as={Col} md={3} controlId='programContactName'>
                  <Form.Label>Program Contact Name</Form.Label>
                  <Form.Control
                    name='programContactName'
                    value={form.values.programContactName}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.programContactName && form.touched.programContactName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.programContactName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3} controlId='programContactPhone'>
                  <Form.Label>Program Contact Phone</Form.Label>
                  <Form.Control
                    name='programContactPhone'
                    value={form.values.programContactPhone}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.programContactPhone && form.touched.programContactPhone}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.programContactPhone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={3} controlId='programContactEmail'>
                  <Form.Label>Program Contact Email</Form.Label>
                  <Form.Control
                    name='programContactEmail'
                    value={form.values.programContactEmail}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    isInvalid={!!form.errors.programContactEmail && form.touched.programContactEmail}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {form.errors.programContactEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mt-2'>
                <Form.Group as={Col} controlId='footer' md={12}>
                  <Form.Check
                    type='checkbox'
                    label='Program Footer'
                    name='includeProgramFooter'
                    checked={form.values.includeProgramFooter}
                    onChange={() => {
                      form.setFieldValue('includeProgramFooter', !form.values.includeProgramFooter)
                      if (form.values.includeProgramFooter) {
                        form.setFieldValue('programFooter', EditorState.createEmpty())
                      }
                    }}
                    onBlur={form.handleBlur}
                  />
                  <Editor
                    editorState={form.values.programFooter} 
                    onEditorStateChange={(editorState) => form.setFieldValue('programFooter', editorState)} 
                    wrapperClassName='border rounded'
                    name='programFooter'
                    editorClassName='p-1'
                    readOnly={!form.values.includeProgramFooter}
                    toolbarHidden={!form.values.includeProgramFooter}
                    toolbar={{ 
                      options: ['inline', 'link'],
                      inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline']
                      },
                      link: {
                        inDropdown: false,
                        options: ['link']
                      }
                    }}
                  />
                </Form.Group>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Link to='/'>
                    <Button variant='outline-danger'>
                Cancel
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <PrimaryButton
                    color='#3B6E35'
                    secondcolor='#5FAD56'
                    className='float-md-right'
                    type='submit'
                    disabled={createNewProgramState.loading || updateProgramSettingsState.loading}
                  >
                    {
                      createNewProgramState.loading || updateProgramSettingsState.loading ? (
                        <Spinner />
                      ) : action === 'edit' ? 
                        'Edit Program' 
                        : 'Create New Program'
                    }
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        )
      }
    </div>
  )
}

Settings.propTypes = {
  program: PropTypes.any,
  action: PropTypes.string,
  programTypes: PropTypes.arrayOf(
    PropTypes.shape({
      programTypeId: PropTypes.number,
      name: PropTypes.string
    })
  ),
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      manufacturerId: PropTypes.number,
      name: PropTypes.string
    })
  ),
  createNewProgramState: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string])
  }),
  getProgramState: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string])
  }),
  createNewProgram: PropTypes.func,
  updateProgramSettings: PropTypes.func,
  updateProgramSettingsState: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string])
  }),
}

export default Settings 