import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Element from '../Element'
import StyledH2 from '../StyledH2'

const FormSection = ({ section, primaryColor, formValues, updateValue, showHeader, optionsInOneColumn }) => {
  return (
    <Row className='mb-2'>
      {
        showHeader && (
          <>
            {
              section.text && (
                <Col xs={12}>
                  <StyledH2
                    color={primaryColor}
                    className={section.className ? section.className : 'h5 font-weight-bold'}
                  >
                    {section.text}
                  </StyledH2>
                </Col>
              )
            }
            {
              section.subText && (
                <Col xs={12}>
                  <p><strong>{section.subText}</strong></p>
                </Col>
              )
            }
          </>
        )
      }
      {
        section.elements.map((element) => (
          <Col  
            key={`form_element_${element.formElementId}`}
            xl={element.xlBreakpoint ? element.xlBreakpoint : element.element.xlBreakpoint}
            lg={element.lgBreakpoint ? element.lgBreakpoint : element.element.lgBreakpoint}
            md={element.mdBreakpoint ? element.mdBreakpoint : element.element.mdBreakpoint}
            sm={element.smBreakpoint ? element.smBreakpoint : element.element.smBreakpoint}
            xs={element.xsBreakpoint ? element.xsBreakpoint : element.element.xsBreakpoint}
            className={element.className ? element.className : ''}
          >
            <Element 
              element={element} 
              values={formValues}
              updateValue={updateValue}
              primaryColor={primaryColor}
              optionsInOneColumn={optionsInOneColumn}
            />
          </Col>
        ))
      }
    </Row>
  )
}

FormSection.propTypes = {
  primaryColor: PropTypes.string,
  formValues: PropTypes.any,
  updateValue: PropTypes.func,
  showHeader: PropTypes.bool,
  optionsInOneColumn: PropTypes.bool,
  section: PropTypes.shape({
    text: PropTypes.string,
    subText: PropTypes.string,
    className: PropTypes.string,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        elementId: PropTypes.number,
        formSectionId: PropTypes.number,
        elementTypeId: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
        xlBreakpoint: PropTypes.number,
        lgBreakpoint: PropTypes.number,
        mdBreakpoint: PropTypes.number,
        smBreakpoint: PropTypes.number,
        xsBreakpoint: PropTypes.number,
        value: PropTypes.string,
        imageLocation: PropTypes.string,
        labelOrientation: PropTypes.string,
        rows: PropTypes.number,
        mask: PropTypes.string,
        hide: PropTypes.number,
        placeholder: PropTypes.string,
        disabled: PropTypes.number,
        required: PropTypes.number,
        className: PropTypes.string,
        elementType: PropTypes.shape({
          elementTypeId: PropTypes.number,
          name: PropTypes.string,
          isFormElement: PropTypes.number,
          orientation: PropTypes.string
        }),
        options: PropTypes.arrayOf(
          PropTypes.shape({
            elementListValueId: PropTypes.number,
            elementId: PropTypes.number,
            label: PropTypes.string,
            value: PropTypes.string
          })
        )
      })
    )
  })
}

export default FormSection