import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Container, Image } from 'react-bootstrap'
import { getApiServer } from '../../utils/helpers'
import { Helmet } from 'react-helmet'
import ProgramBanner from '../../components/ProgramBanner'
import StyledH2 from '../../components/StyledH2'

import '../../assets/styles/rxenroll.css'


const MainLayout = () => {
  const server = getApiServer()
  const location = useLocation()
  
  const isLaunching = location.pathname.includes('launch') || location.pathname.includes('consent')

  return (
    <div id='rx'>
      <Container fluid>
        <Helmet>
          {
            isLaunching ? (
              <title>
                Rx Enrollment
              </title>
            ) : (
              <title>
                Rx Enrollment - Form Builder
              </title>
            )
          }
        </Helmet>
        <header>
          <Container fluid className='pt-3'>
            <h1>Rx Enrollment { !isLaunching && '- Form Builder'}</h1>
            <div className='header'>
              <Image src={`${server}/static/images/pocp/RxEnrollment.png`} width='113' height='64' alt="Rx Enrollment Logo" className='logo' />
              {
                !isLaunching && (
                  <ProgramBanner
                    borderLeft={1}
                    color='#070f0d'
                    marginLeft={1.2}
                    paddingLeft={1.2}
                  >
                    <StyledH2
                      color='#070f0d'
                      className='font-weight-bold mt-2'
                    >
                    Form Builder
                    </StyledH2>
                  </ProgramBanner>
                )
              }
            </div>
          </Container>
        </header>
        <Container fluid className='shadow p-0 bg-white mb-4 rounded'>
          <Container fluid className='m-0 pt-1 pl-3 pb-2'>
            <Outlet />
          </Container>
        </Container>
      </Container>
    </div>
  )
}

export default MainLayout