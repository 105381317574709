import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import ProgramHeader from '../../components/ProgramHeader'
import { Helmet } from 'react-helmet'

import '../../assets/styles/program.css'
import { getProgram } from '../../features/user/userAction'

const ProgramLayout = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { 
    program,
    getProgramState
  } = useSelector((state) => ({
    program: state.user.program,
    getProgramState: state.user.getProgram
  }))

  useEffect(() => {
    dispatch(getProgram(params.id))
  }, [])

  return (
    <div>
      {getProgramState.loading && (
        <div>
          Loading Program...
        </div>
      )}
      {
        getProgramState.success && (
          <Container fluid className='p-0' id='program'>
            <Helmet>
              <title>
                Rx Enrollment :: {program.medicationName}{program.includeRegisteredTrademark === 1 ? ('®') : ''} {program.name}
              </title>
            </Helmet>
            <header>
              <ProgramHeader />
            </header>
            <div>
              <Outlet />
            </div>
            <footer>
              <Container className='small'>
                <Row>
                  <Col className='text-right'>
                    <div dangerouslySetInnerHTML={{ __html: program.footerTemplate ? program.footerTemplate : null }} />
                  </Col>
                </Row>
              </Container>
            </footer>
          </Container>
        )
      }
    </div>
    
  )
}

export default ProgramLayout