import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../PrimaryButton'
import userService from '../../features/user/userService'
import providerService from '../../features/provider/providerService'
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setSession } from '../../features/user/userAction'

const CoPay = ({ action }) => {
  const [loading, setLoading] = useState(true)
  const [offer, setOffer] = useState({})

  const [sentCopayOffer, setSentCopayOffer] = useState({
    loading: false,
    success: false,
    error: false
  })
  const [showAlreadySent, setShowAlreadySent] = useState(action.logs.some((l) => l.status === 'CO_PAY_OFFER_SENT'))

  const user = useSelector((state) => state.user.user)
  useEffect(async () => {
    const copay = await userService.getCopayOffer(action.rxNormCode)
    setOffer(copay.data)
    setLoading(false)
  }, [])

  const dispatch = useDispatch()
  const healthSystem = useSelector((state) => state.user.healthSystem.data)

  const handleSendOffer = async () => {
    setShowAlreadySent(false)
    setSentCopayOffer((ps) => ({ ...ps, loading: true }))
    try {
      const sentOffer = await providerService.sendPatientCopayOffer(healthSystem.healthSystemId, action.sessionActionId)
      if (sentOffer.status === 200) {
        dispatch(setSession(sentOffer.data))
        setSentCopayOffer((ps) => ({ ...ps, loading: false, success: true }))
      }
    } catch (error) {
      setSentCopayOffer((ps) => ({ ...ps, loading: false, error: true }))
    }
  }

  if (user === 'PATIENT') {
    return (
      <div>
        <Row>
          <Col>
            <h2 className='h4 font-weight-bold'>{action.rxName} Copay Offer</h2>
          </Col>
          <Col className='text-right'>
            <PrimaryButton
              color='#3B6E35'
              secondcolor='#5FAD56'
              size='sm'
              href={offer && offer.savingsInfoUrl}
              className='mr-2'
              target="_blank"
              style={{ color: 'white' }}
              disabled={loading}
            >
              Savings Info
            </PrimaryButton>
            <PrimaryButton
              color='#3B6E35'
              secondcolor='#5FAD56'
              size='sm'
              href={offer && offer.savingsSignupUrl}
              target="_blank"
              style={{ color: 'white' }}
              disabled={loading}
            >
              Savings Sign Up
            </PrimaryButton>
          </Col>
        </Row>
        {
          loading ? <div className='text-center'><Spinner animation='border' role='status' /> Loading Offer...</div> : <p className='mt-3'>{offer.providerOfferText ? offer.providerOfferText : ''}</p>
        }
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col>
          <h2 className='h4 font-weight-bold'>{action.rxName} Copay Offer</h2>
        </Col>
        <Col xs={6}>
          {
            sentCopayOffer.success && (
              <Alert variant='success' size='sm'>
              MyChart announcement successfully sent to patient!
              </Alert>
            )
          }
          {
            sentCopayOffer.error && (
              <Alert variant='danger'>
              Unable to send MyChart announcement to patient!
              </Alert>
            )
          }
        </Col>
        <Col className='text-right'>
          {
            !loading && (
              <>
                {
                  showAlreadySent ? (
                    <Button
                      variant='outline-secondary'
                      size='sm'
                      disabled={showAlreadySent}
                    >
                      Send Offer to Patient in MyChart
                    </Button>
                  ) : (
                    <PrimaryButton
                      color='#3B6E35'
                      secondcolor='#5FAD56'
                      size='sm'
                      onClick={handleSendOffer}
                      disabled={sentCopayOffer.loading}
                    >
                      Send Offer to Patient in MyChart
                    </PrimaryButton>
                  )
                }
              </>
            )
          }
        </Col>
      </Row>
      {
        loading ? <div className='text-center'><Spinner animation='border' role='status' /> Loading Offer...</div> : <p className='mt-3'>{offer.providerOfferText ? offer.providerOfferText : ''}</p>
      }
    </div>
  )
}

CoPay.propTypes = {
  action: PropTypes.any
}
  

export default CoPay