import types from '../types'

const initialState = {
  getPatientLandingPage: {
    loading: false,
    success: false,
    error: false
  },
  landingPage: [],
  getAuthorizationForm: {
    loading: false,
    success: false,
    error: false
  },
  authorizationForm: [],
  authorization: {},
  authorize: {
    loading: false,
    success: false,
    error: false
  },
  getContactForm: {
    loading: false,
    success: false,
    error: false
  },
  contactForm: [],
  contact: {},
  addContact: {
    loading: false,
    success: false,
    error: false,
    data: []
  },
  getInsuranceForm: {
    loading: false,
    success: false,
    error: false
  },
  insuranceForm: [],
  insurance: {},
  addInsurance: {
    loading: false,
    success: false,
    error: false,
    data: []
  },
  getAssistanceForm: {
    loading: false,
    success: false,
    error: false
  },
  assistanceForm: [],
  assistance: {},
  addAssistance: {
    loading: false,
    success: false,
    error: false,
    data: []
  },
  getCertificationForm: {
    loading: false,
    success: false,
    error: false
  },
  certificationForm: [],
  certification: {},
  consent: {
    loading: false,
    success: false,
    error: false,
    data: {}
  }
}

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PATIENT_LANDING_PAGE_REQUESTED: {
    return {
      ...state,
      getPatientLandingPage: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_PATIENT_LANDING_PAGE_FULFILLED: {
    return {
      ...state,
      getPatientLandingPage: {
        loading: false,
        success: true,
        error: false
      },
      landingPage: action.payload
    }
  }
  case types.GET_PATIENT_LANDING_PAGE_REJECTED: {
    return {
      ...state,
      getPatientLandingPage: {
        loading: false,
        success: false,
        error: action.payload
      }
    }
  }
  case types.GET_AUTHORIZATION_FORM_REQUESTED: {
    return {
      ...state,
      getAuthorizationForm: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_AUTHORIZATION_FORM_FULFILLED: {
    const { columns } = action.payload

    const formSections = [].concat(...columns.map((s) => s))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement)

    let authorization = {}
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      authorization[element.name] = {
        elementId: element.formElementId,
        value: ''
      }
    }

    return {
      ...state,
      getAuthorizationForm: {
        loading: false,
        success: true,
        error: false
      },
      authorization,
      authorizationForm: action.payload
    }
  }
  case types.GET_AUTHORIZATION_FORM_REJECTED: {
    return {
      ...state,
      getAuthorizationForm: {
        loadnig: false,
        success: false,
        error: action.payload
      }
    }
  }
  case types.AUTHORIZE_REQUESTED: {
    return {
      ...state,
      authorize: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.AUTHORIZE_FULFILLED: {
    return {
      ...state,
      authorize: {
        loading: false,
        success: true,
        error: false
      },
      authorization: action.payload.values
    }
  }
  case types.AUTHORIZE_REJECTED: {
    return {
      ...state,
      authorize: {
        loading: false,
        error: true
      }
    }
  }
  case types.GET_CONTACT_FORM_REQUESTED: {
    return {
      ...state,
      getContactForm: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_CONTACT_FORM_FULFILLED: {
    const { form, fhirPatient } = action.payload

    const formSections = [].concat(...form.columns.map((s) => s))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement)

    let contact = {}
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      const name = element.name ? element.name : element.element.name
      
      contact[name] = {
        elementId: element.formElementId,
        value: ''
      }

      if (element.element) {
        if (element.element.options.length > 0) {
          for (let k = 0; k < element.element.options.length; k += 1) {
            const option = element.element.options[k]
            if (option.option.value.toLowerCase().includes('other')) {
              contact[`${name}Other`] = {
                elementId: element.formElementId,
                value: ''
              }
            }
          }
        }
      }
    }

    const mobile = fhirPatient.telecom ? fhirPatient.telecom.find((c) => c.use === 'home') : ''
    const alternate = fhirPatient.telecom ? fhirPatient.telecom.find((c) => c.use === 'work') : ''
    const email = fhirPatient.telecom ? fhirPatient.telecom.find((c) => c.use === 'email') : ''

    contact.preferredPhone.value = mobile ? mobile.value : alternate ? alternate.value : ''
    contact.alternatePhone.value = mobile ? alternate ? alternate.value : '' : ''
    contact.email.value = email ? email.value : ''

    const patientAddress = fhirPatient.address.find((a) => a.use === 'home')
    
    contact.streetAddress.value = patientAddress ? patientAddress.line[0] : ''
    contact.streetAddress2.value = patientAddress ? patientAddress.line[1] : ''
    contact.city.value = patientAddress ? patientAddress.city : ''
    contact.state.value = patientAddress ? patientAddress.state : ''
    contact.zip.value = patientAddress ? patientAddress.postalCode : ''

    return {
      ...state,
      authorize: {
        loading: false,
        success: false,
        error: false
      },
      getContactForm: {
        loading: false,
        success: true,
        error: false
      },
      contactForm: form,
      contact
    }
  }
  case types.GET_CONTACT_FORM_REJECTED: {
    return {
      ...state,
      getContactForm: {
        loadng: false,
        error: true,
        success: false
      }
    }
  }
  case types.ADD_CONTACT_REQUESTED: {
    return {
      ...state,
      addContact: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.ADD_CONTACT_FULFILLED: {
    return {
      ...state,
      addContact: {
        loading: false,
        success: true,
        error: false
      },
      contact: action.payload.values
    }
  }
  case types.ADD_CONTACT_REJECTED: {
    return {
      ...state,
      addContact: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.GET_INSURANCE_FORM_REQUESTED: {
    return {
      ...state,
      addContact: {
        loading: false,
        success: false,
        error: false
      },
      getInsuranceForm: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_INSURANCE_FORM_FULFILLED: {
    const { form, coverages } = action.payload
        
    const formSections = [].concat(...form.columns.map((s) => s))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement)

    let insurance = {}
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      const name = element.name ? element.name : element.element.name
          
      insurance[name] = {
        elementId: element.formElementId,
        value: ''
      }

      if (element.element) {
        if (element.element.options.length > 0) {
          for (let k = 0; k < element.element.options.length; k += 1) {
            const option = element.element.options[k]
            if (option.option.value.toLowerCase().includes('other')) {
              insurance[`${name}Other`] = {
                elementId: element.formElementId,
                value: ''
              }
            }
          }
        }
      }
    }

    insurance.noInsurance.value = coverages.total === 0 ? 'Yes' : 'No'

    const primaryCoverage = coverages.entry.find((e) => e.resource.resourceType === 'Coverage' && e.resource.order === 1)
        
    if (primaryCoverage) {
      const { resource } = primaryCoverage
      const payor = resource.payor[0].reference.split('/')[1]
      const payorResource = coverages.entry.find((e) => e.resource.id === payor)
          
      insurance.primaryMedicalInsuranceName.value = payorResource ? payorResource.resource.name : ''
      insurance.primaryMedicalInsurancePhone.value = payorResource ? payorResource.telecom ? payorResource.telecom.find((t) => t.system === 'phone').value : '' : ''

      insurance.policyholderName.value = resource.subscriber.display
      insurance.relationshipToPatient.value = resource.relationship.text

      const group = resource.class.find((c) => c.type.coding.find((code) => code.code === 'group'))
      const policyId = resource.class.find((c) => c.type.coding.find((code) => code.code === 'id'))
        
      insurance['group#'].value = group ? group.value : ''
      insurance.policyId.value = policyId ? policyId.value : ''
    }
        
    return {
      ...state,
      getInsuranceForm: {
        loading: false,
        success: true,
        error: false
      },
      insuranceForm: form,
      insurance
    }
  }
  case types.GET_INSURANCE_FORM_REJECTED: {
    return {
      ...state,
      getInsuranceForm: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.ADD_INSURANCE_REQUESTED: {
    return {
      ...state,
      addInsurance: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.ADD_INSURANCE_FULFILLED: {
    return {
      ...state,
      addInsurance: {
        loading: false,
        success: true,
        error: false
      },
      insurance: action.payload.values
    }
  }
  case types.ADD_INSURANCE_REJECTED: {
    return {
      ...state,
      addInsurance: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.GET_ASSISTANCE_FORM_REQUESTED: {
    return {
      ...state,
      addInsurance: {
        loading: false,
        success: false,
        error: false
      },
      getAssistanceForm: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_ASSISTANCE_FORM_FULFILLED: {
    const { form, patient } = action.payload

    const formSections = [].concat(...form.columns.map((s) => s))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement)

    let assistance = {}
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      const name = element.name ? element.name : element.element.name
          
      assistance[name] = {
        elementId: element.formElementId,
        value: ''
      }

      if (element.element) {
        if (element.element.options.length > 0) {
          for (let k = 0; k < element.element.options.length; k += 1) {
            const option = element.element.options[k]
            if (option.option.value.toLowerCase().includes('other')) {
              assistance[`${name}Other`] = {
                elementId: element.formElementId,
                value: ''
              }
            }
          }
        }
      }
    }

    const ssn = patient.identifier.find((id) => id.system === 'urn:oid:2.16.840.1.113883.4.1')
    if (ssn) {
      if (assistance.patientSSN) {
        assistance.patientSSN.value = ssn.value.slice(-4)
      }
    }
  
    return {
      ...state,
      getAssistanceForm: {
        loading: false,
        success: true,
        error: false
      },
      assistance,
      assistanceForm: form
    }
  }
  case types.GET_ASSISTANCE_FORM_REJECTED: {
    return {
      ...state,
      getAssistanceForm: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.ADD_ASSISTANCE_REQUESTED: {
    return {
      ...state,
      addAssistance: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.ADD_ASSISTANCE_FUFILLED: {
    return {
      ...state,
      addAssistance: {
        loading: false,
        success: true,
        error: false
      }
    }
  }
  case types.ADD_ASSISTANCE_REJECTED: {
    return {
      ...state,
      addAssistance: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.GET_CERTIFICATION_FORM_REQUESTED: {
    return {
      ...state,
      getCertificationForm: {
        loading: true,
        error: false,
        success: false
      },
      addAssistance: {
        loading: false,
        error: false,
        success: false
      }
    }
  }
  case types.GET_CERTIFICATION_FORM_FULFILLED: {
    const { columns } = action.payload

    const formSections = [].concat(...columns.map((s) => s))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement)

    let certification = {}
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      certification[element.name] = {
        elementId: element.formElementId,
        value: ''
      }
    }
        
    return {
      ...state,
      getCertificationForm: {
        loading: false,
        success: true,
        error: false
      },
      certification,
      certificationForm: action.payload
    }
  }
  case types.GET_CERTIFICATION_FORM_REJECTED: {
    return {
      ...state,
      getCertificationForm: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.CONSENT_REQUESTED: {
    return {
      ...state,
      consent: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.CONSENT_FULFILLED: {
    return {
      ...state,
      consent: {
        loading: false,
        success: true,
        error: false,
        data: action.payload.consent
      },
      certification: action.payload.values
    }
  }
  case types.CONSENT_REJECTED: {
    return {
      ...state,
      consent: {
        error: true,
        success: false,
        loading: false
      }
    }
  }
  case types.SET_STEP: {
    return {
      ...state,
      step: action.payload
    }
  }
  case types.UPDATE_AUTHORIZATION_VALUE: {
    const { name, value } = action.payload
    const newAuthorization = state.authorization

    newAuthorization[name].value = value
    return {
      ...state,
      authorization: newAuthorization
    }
  }
  case types.UPDATE_CONTACT_VALUE: {
    const { name, value } = action.payload
    const contact = state.contact

    contact[name].value = value
    return {
      ...state,
      contact
    }
  }
  case types.UPDATE_INSURANCE_VALUE: {
    const { name, value } = action.payload

    const newInsurance = state.insurance

    if (name === 'noInsurance' && value === 'Yes') {
      const insuranceKeys = Object.keys(newInsurance)
      for (let i = 0; i < insuranceKeys.length; i += 1) {
        const key = insuranceKeys[i]
        newInsurance[key].value = ''
      }
    }

    newInsurance[name].value = value

    return {
      ...state,
      insurance: newInsurance
    }
  }
  case types.UPDATE_ASSISTANCE_VALUE: {
    const { name, value } = action.payload

    const assistance = state.assistance
    assistance[name].value = value

    return {
      ...state,
      assistance
    }
  }
  case types.UPDATE_CERTIFICATION_VALUE: {
    const { name, value } = action.payload
    
    const certification = state.certification
    certification[name].value = value

    return {
      ...state,
      certification
    }
  }
  default:
    return state
  }
}

export default patientReducer