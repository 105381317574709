/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import FormCheckGroup from '../FormCheckGroup'
import FormTextbox from '../FormTextbox'
import FormCheck from '../FormCheck'
import Image from '../Image'
import FormCheckOrGroup from '../FormCheckOrGroup'
import FormSelect from '../FormSelect'
import ProviderAttestation from '../ProviderAttestation'
import FormDatePicker from '../FormDatePicker'

const Element = ({ element, values, updateValue, primaryColor, optionsInOneColumn }) => {
  switch(element.elementType.name) {
  case 'Checkbox':
    return (
      <FormCheck 
        type='checkbox' 
        name={element.name ? element.name : element.element.name} 
        label={element.label} 
        value={element.value}
        required={element.required}
        checked={values ? (values[element.name ? element.name : element.element.name].value) : ''} 
        updateValue={updateValue}
      />
    )
  case 'Text':
  {
    const placeholder = element.element ? element.element.placeholder ? element.element.placeholder : '' : ''
    return (
      <FormTextbox
        name={element.name ? element.name : element.element.name} 
        label={element.label}
        labelOrientation={element.labelOrientation}
        required={element.required}
        rows={element.rows}
        formValue={values ? (values[element.name ? element.name : element.element.name].value) : ''} 
        updateValue={updateValue}
        placeholder={placeholder}
      />
    )
  }
  case 'Single Choice - Radio Group':
  case 'Yes/No Radio Group':
  case 'Yes/No/NA Radio Group':
    return (
      <FormCheckGroup 
        label={element.label} 
        name={element.name ? element.name : element.element.name} 
        type='radio' 
        options={element.element.options} 
        required={element.required}
        inline={element.element.orientation}
        optionsInOneColumn={optionsInOneColumn}
        values={values}
        updateValue={updateValue}
      />
    )
  case 'Single Choice - Dropdown':
    return (
      <FormSelect
        name={element.name ? element.name : element.element.name}
        label={element.label ? element.label : element.element.label}
        required={element.required}
        options={element.element.options} 
        formValue={values ? (values[element.name ? element.name : element.element.name].value) : ''} 
        updateValue={updateValue}
      />
    )
  case 'Dropdown or Paragraph':
    return (
      <FormSelect
        name={element.name ? element.name : element.element.name}
        label={element.label ? element.label : element.element.label}
        required={element.required}
        options={element.element.options}
        checkAny
      />
    )
  case 'Radio Group or Paragragh':
    return (
      <FormCheckGroup
        label={element.label === '' ? element.label : element.element.label}
        name={element.name ? element.name : element.element.name} 
        type='radio' 
        options={element.element.options} 
        required={element.required}
        prescription
      />
    )
  case 'Date Picker': 
    return (
      <FormDatePicker
        label={element.label} 
        name={element.name ? element.name : element.element.name}
        formValue={values ? (values[element.name ? element.name : element.element.name].value) : ''} 
        updateValue={updateValue}
      />
    )
  case 'Single Choice - Checkbox Group':
    return (
      <FormCheckGroup 
        label={element.label} 
        name={element.name ? element.name : element.element.name} 
        type='checkbox'
        options={element.element.options} 
        required={element.required}
        inline={element.element.orientation}
        optionsInOneColumn={optionsInOneColumn}
        values={values}
        updateValue={updateValue}
      />
    )
  case 'Provider Attestation':
    return (
      <ProviderAttestation
        name={element.name ? element.name : element.element.name}
        rows={element.element.rows}
        value={element.value}
        label={element.label}
      />
    )
  case 'Authorization Content':
  case 'Introductory Content':
  case 'Patient Communications Template':
  case 'Patient Consent Template':
  case 'Paragraph':
    return (
      <div dangerouslySetInnerHTML={{ __html: element.value }} />
    )
  // case 'image':
  //   return (
  //     <Image 
  //       src={element.imageLocation}
  //       alt={element.imageAltText}
  //     />
  //   )
  case 'Radio Group with Or':
    return (
      <FormCheckOrGroup 
        name={element.name ? element.name : element.element.name}
        type='radio'
        options={element.element.options}
        values={values}
        updateValue={updateValue}
      />
    )
  case 'Header':
    return <h2 className={element.className ? element.className : element.element.className} style={{ color: primaryColor }}>{element.label}</h2>
  case 'Horizontal Line':
    return <hr />
  default:
    return <div>{element.elementType.name}</div>
  }
}

Element.propTypes = {
  values: PropTypes.any,
  updateValue: PropTypes.func,
  element: PropTypes.any,
  primaryColor: PropTypes.string,
  optionsInOneColumn: PropTypes.bool
}

export default Element