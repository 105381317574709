import axios from 'axios'
import { getApiServer } from '../../utils/helpers'

const server = getApiServer()

const getMetadata = async (iss) => {
  const response = await axios.get(`${iss}/metadata`)
  return response
}

const getAccessToken = async (url) => {
  return await axios.post(`${server}/access-token`, { url })
}

const generateTokenUri = async (payload) => {
  const tokenUri = await axios.post(`${server}/smart-token`, payload)
  return tokenUri.data
}

const authService = {
  getMetadata,
  getAccessToken,
  generateTokenUri
}

export default authService