import React from 'react'
import { Container, Image, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getApiServer } from '../../utils/helpers'
import ProgramBanner from '../ProgramBanner'
import StyledH2 from '../StyledH2'
import { BsXLg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { clearProgram } from '../../features/user/userAction'

const ProgramHeader = () => {
  const program = useSelector((state) => state.user.program)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  if (program === null) {
    return <div />
  }

  const server = getApiServer()

  const handleGoBack = () => {
    dispatch(clearProgram())
    navigate(-1)
  }

  return (
    <Container className='pt-3'>
      <div className="program">
        <div className="program_logo">
          {
            program.programBanner ? (
              <Image src={`${server}${program.programLogo}`} width="123" height="64" alt={`${program.programAlt}`} />
            ) : (
              <Image src={`${server}/static/images/pocp/RxEnrollment.png`} width="113" height="64" alt="Rx Enrollment Logo" />
            )
          }
        </div>
        <div className="cancel_mobile">
          <Button variant='outline-secondary' size='sm' onClick={handleGoBack}>
            <BsXLg />
          </Button>
        </div>
        {
          program.programBanner ? (
            <ProgramBanner
              borderLeft={2}
              color={program.secondaryColor}
              marginLeft={1}
              paddingLeft={1}
            >
              <Image src={`${server}${program.programBanner}`} width="325" height="64" alt={program.name} />
            </ProgramBanner>
          ) : (
            <ProgramBanner
              borderLeft={1}
              color={program.secondaryColor}
              marginLeft={1.2}
              paddingLeft={1.2}
            >
              <StyledH2
                color={program.primaryColor}
                className='font-weight-bold mt-2'
              >
                {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
              </StyledH2>
            </ProgramBanner>
          )
        }
      </div>
      <div className="rxenroll">
        {
          program.programBanner && (
            <Image src={`${server}/static/images/pocp/RxEnrollment.png`} width="113" height="64" alt="Rx Enrollment Logo" />
          )
        }
      </div>
      <div className="leave">
        <Button variant='outline-secondary' size='sm' onClick={handleGoBack}>
          <BsXLg />
        </Button>
      </div>
    </Container>
  )
}

export default ProgramHeader