import React, { useEffect } from 'react'
import { Col, Form, Row, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import PrimaryButton from '../../../components/PrimaryButton'
import { authorize, getAuthorizationForm, setStep, updateAuthorizationValue } from '../../../features/patient/patientAction'
import LinkButton from '../../../components/LinkButton'

const Authorization = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { program, authorizationFormState, programState, authorizationForm, formValues, session, authorizeState, actionId } = useSelector((state) => ({
    program: state.user.program,
    authorizationFormState: state.patient.getAuthorizationForm,
    programState: state.user.getProgram,
    authorizationForm: state.patient.authorizationForm,
    formValues: state.patient.authorization,
    session: state.user.session,
    authorizeState: state.patient.authorize,
    actionId: state.user.actionId
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!authorizationFormState.success && programState.success) {
      dispatch(getAuthorizationForm(program.programId))
    }
  }, [authorizationFormState.success, programState.success])

  const handlePreviousClick = () => {
    dispatch(setStep(0))
    navigate('/patient')
  }

  const handleUpdateFormField = (name, value) => {
    dispatch(updateAuthorizationValue({ name, value }))
  }

  useEffect(() => {
    const element = document.getElementById('representativeName')
    if (formValues.patientUser?.value) {
      if (formValues.patientUser.value === 'legal representative') {
        element.style.display = null
      } else {
        element.style.display = 'none'
      }
    }
  }, [formValues.patientUser?.value])

  useEffect(() => {
    if (authorizeState.success) {
      dispatch(setStep(1))
      navigate('/patient-program/contact')
    }
  }, [authorizeState.success])

  return (
    <div>
      <p className="small text-muted">Step 1 of 5</p>
      {
        authorizationFormState.success && (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              const sessionAction = session.actions.find((action) => action.sessionActionId === actionId)

              dispatch(authorize({
                ...formValues,
                sessionActionId: sessionAction.sessionActionId,
                formId: authorizationForm.formId
              }))
            }}
          >
            {
              authorizeState.error && (
                <Alert variant='danger'>
                  Error authorizing enrollment form!
                </Alert>
              )
            }
            <Row>
              {
                authorizationForm.columns.map((column, index) => (
                  <Col
                    key={`${program.name}_patient_authorization_${index}`}
                    md={12 / authorizationForm.numberOfColumns}
                  >
                    {
                      column.map((section) => (
                        <FormSection
                          key={`${program.name}_patient_authorization_section_${section.formSectionId}`}
                          section={section}
                          primaryColor={program.primaryColor}
                          formValues={formValues}
                          updateValue={handleUpdateFormField}
                          showHeader
                        />
                      ))
                    }
                  </Col>
                ))
              }
            </Row>
            <hr />
            <Row>
              <Col>
                <LinkButton
                  variant='link'
                  color={program.programBanner ? program.primaryColor : '#030A08'}
                  onClick={handlePreviousClick}
                >
                  Previous
                </LinkButton>
              </Col>
              <Col className='text-center py-2'>
                <PrimaryButton
                  color={program.programBanner ? program.primaryColor : '#3B6E35'}
                  secondcolor={program.programBanner ? program.secondaryColor : '#5FAD56'}
                  type='submit'
                >
                  Next
                </PrimaryButton>
              </Col>
              <Col />
            </Row>
          </Form>
        )
      }
    </div>
  )
}

export default Authorization