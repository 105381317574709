import adminService from '../admin/adminService'
import types from '../types'
import userService from './userService'

export const getHealthSystem = () => async (dispatch) => {
  dispatch({ type: types.GET_HEALTH_SYSTEM_REQUESTED })
  try {
    const healthSystem = await userService.getHealthSystem()
    const user = await userService.getHealthSystemUser()
    dispatch({ type: types.GET_HEALTH_SYSTEM_FULFILLED, payload: { healthSystem: healthSystem.data, user: user.data } })
  } catch (error) {
    dispatch({ type: types.GET_HEALTH_SYSTEM_REJECTED, payload: error })
  }
}

export const getPatientMedications = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_PATIENT_MEDICATIONS_REQUESTED })
  const { auth: { client, patientId, accessToken, iss } } = getState()

  try {
    const medicationRequests = await userService.getEpicResource(client, patientId, 'MedicationRequest', iss, accessToken.data.access_token)

    let patientRequests = []

    if (medicationRequests.data.total > 0) {
      const requests = []
      const practitioners = []
      const medications = []

      for (let i = 0; i < medicationRequests.data.entry.length; i += 1) {
        const element = medicationRequests.data.entry[i].resource

        if (element.resourceType === 'MedicationRequest') {
          requests.push(element)
        }

        if (element.resourceType === 'Practitioner') {
          practitioners.push(element)
        }

        if (element.resourceType === 'Medication') {
          medications.push(element)
        }
      }

      patientRequests = requests.map((request) => ({
        request,
        requester: practitioners.find((practitioner) => request.requester.reference && practitioner.id === request.requester.reference.split('/')[1]),
        medication: medications.find((medication) => medication.id === request.medicationReference.reference.split('/')[1])
      }))
    }

    dispatch({ type: types.GET_PATIENT_MEDICATION_FULFILLED, payload: patientRequests })
  } catch (error) {
    dispatch({ type: types.GET_PATIENT_MEDICATION_REJECTED, payload: error })
  }
}

export const getProgram = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_SESSION_PROGRAM_REQUESTED })
  try {
    const program = await adminService.getProgram(programId)
    dispatch({ type: types.GET_SESSION_PROGRAM_FULFILLED, payload: program.data })
  } catch (error) {
    dispatch({ type: types.GET_SESSION_PROGRAM_REJECTED, payload: error })
  }
}

export const getCopayOffer = (rxNormCode) => async (dispatch) => {
  dispatch({ type: types.GET_CO_PAY_OFFER_REQUESTED })
  try {
    const copayOffer = await userService.getCopayOffer(rxNormCode)
    dispatch({ type: types.GET_CO_PAY_OFFER_FULFILLED, payload: copayOffer.data })
  } catch (error) {
    dispatch({ type: types.GET_CO_PAY_OFFER_REJECTED })
  }
}

export const getPatient = (patientId) => async (dispatch) => {
  dispatch({ type: types.GET_PATIENT_REQUESTED })
  try {
    const patient = await userService.getPatient(patientId)
    dispatch({ type: types.GET_PATIENT_FULFILLED, payload: { success: true, data: patient.data } })
  } catch (error) {
    console.log(error)
    dispatch({ type: types.GET_PATIENT_REJECTED, payload: error })
  }
}

export const startSession = (actor) => async (dispatch, getState) => {
  const { user: { healthSystem } } = getState()
  dispatch({ type: types.START_SESSION_REQUESTED })
  try {
    const session = await userService.startSession(actor, healthSystem.data.healthSystemId)
    dispatch({ type: types.START_SESSION_FULFILLED, payload: session.data })
  } catch (error) {
    dispatch({ type: types.START_SESSION_REJECTED, payload: error })
  }
}

export const startAction = (actor) => async (dispatch, getState) => {
  const { user: { session, actionId } } = getState()
  dispatch({ type: types.START_ACTION_REQUESTED })
  try {
    const currentAction = session.actions.find((action) => action.sessionActionId === actionId)
    dispatch({ type: types.SET_CURRENT_ACTION, payload: currentAction })
    const updatedSessionAction = await userService.updateSessionAction(currentAction.sessionActionId, actor)
    dispatch({ type: types.START_ACTION_FULFILLED, payload: updatedSessionAction.data })
  } catch (error) {
    console.log(error)
    dispatch({ type: types.START_ACTION_REJECTED, payload: error })
  }
}

export const getPatientCoverages = () => async (dispatch, getState) => {
  const { user: { fhirPatient } } = getState()
  dispatch({ type: types.GET_PATIENT_COVERAGES_REQUESTED })
  try {
    const coverages = await userService.getPatientCoverages(fhirPatient.id)
    dispatch({ type: types.GET_PATIENT_COVERAGES_FULFILLED, payload: coverages.data })
  } catch (error) {
    dispatch({ type: types.GET_PATIENT_COVERAGES_REJECTED, payload: error })
  }
}

export const updatePatientSession = (payload) => (dispatch) => dispatch({ type: types.UPDATE_PATIENT_SESSION, payload })

export const setProgram = (payload) => (dispatch) => dispatch({ type: types.SET_PROGRAM, payload })

export const setSession = (payload) => (dispatch) => dispatch({ type: types.SET_SESSION, payload })

export const setCurrentActionId = (payload) => (dispatch) => dispatch({ type: types.SET_CURRENT_ACTION_ID, payload })

export const clearProgram = () => (dispatch) => dispatch({ type: types.CLEAR_PROGRAM })