import React from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

const FormDatePicker = ({ name, label, formValue, updateValue }) => {
  return (
    <Form.Group as={Row} className='mb-1'>
      <Col md={8}>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col md={4}>
        <Form.Control
          name={name}
          as={DatePicker}
          size='sm'
          selected={formValue ? new Date(formValue) : formValue}
          onChange={(event) => updateValue(name, event)}
        />
      </Col>
      
    </Form.Group>
  )
}

FormDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formValue: PropTypes.string,
  updateValue: PropTypes.func
}

export default FormDatePicker