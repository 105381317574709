import React, { useEffect } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../../../components/PrimaryButton'
import StyledH3 from '../../../../components/StyledH3'
import { getPatientDemographics, updateDemographicCheckbox, updateStep, updateAllDemographicCheckbox, saveProgramPatientDemographics } from '../../../../features/admin/adminAction'

const Demographics = () => {

  const dispatch = useDispatch()
  const { program, demographics, saveDemographicsState } = useSelector((state) => ({
    program: state.admin.program,
    demographics: state.admin.demographics,
    saveDemographicsState: state.admin.saveDemographics
  }))

  useEffect(() => {
    dispatch(getPatientDemographics())
  }, [])

  const handleUpdateDemographicChecked = (demographicId, checked) => {
    dispatch(updateDemographicCheckbox({ demographicId, checked }))
  }

  const handleUpdateAllChecked = (checked) => {
    dispatch(updateAllDemographicCheckbox(checked))
  }

  const handleSaveDemographics = () => {
    const programPatientDemographics = demographics.filter((demographic) => demographic.checked)
    dispatch(saveProgramPatientDemographics({ programId: program.programId, demographics: programPatientDemographics }))
  }

  const handleGoBack = () => {
    dispatch(updateStep('landing'))
  }

  useEffect(() => {
    if (saveDemographicsState.success) {
      handleGoBack()
    }
  }, [saveDemographicsState.success])

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <StyledH3 
            color='#070f0d'
            onClick={handleGoBack}
            style={{
              cursor: 'pointer'
            }}
          >
            <u>
             Forms
            </u>
          </StyledH3>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program.medicationName}{program.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      <Row>
        <Col md={10} xs={9}>
          <StyledH3>
            Patient Demographics Bar
          </StyledH3>
        </Col>
        <Col>
          <PrimaryButton
            color='#3B6E35'
            secondcolor='#5FAD56'
            className='float-md-right'
            size='sm'
            onClick={handleSaveDemographics}
            disabled={saveDemographicsState.loading}
          >
            Save
          </PrimaryButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            responsive
          >
            <thead>
              <tr>
                <th>
                  <div className="form-checkbox form-checkbox-secondary pb-2">
                    <input 
                      id="all" 
                      type="checkbox" 
                      checked={demographics.some((demographic) => {
                        if (demographic.patientDemographicId !== 1) {
                          return demographic.checked
                        }
                      })}
                      onChange={(event) => handleUpdateAllChecked(event.target.checked)}
                    />
                    <label htmlFor="all">

                    </label>
                  </div>
                </th>
                <th>
                  Field
                </th>
                <th>
                  Auto-Fill
                </th>
              </tr>
            </thead>
            <tbody>
              {
                demographics.map((demographic) => (
                  <tr key={`demographic-${demographic.patientDemographicId}`}>
                    {
                      demographic.default ? (
                        <td />
                      ) : (
                        <td className='pt-0'>
                          <div className="form-checkbox form-checkbox-primary">
                            <input 
                              id={demographic.patientDemographicId} 
                              type="checkbox" 
                              checked={demographic.checked}
                              onChange={() => handleUpdateDemographicChecked(demographic.patientDemographicId, !demographic.checked)}
                            />
                            <label htmlFor={demographic.patientDemographicId}>

                            </label>
                          </div>
                        </td>
                      )
                    }
                    <td>
                      {demographic.name}
                    </td>
                    <td>
                      Yes
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant='outline-danger'
            size='sm'
            onClick={handleGoBack}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <PrimaryButton
            color='#3B6E35'
            secondcolor='#5FAD56'
            className='float-md-right'
            size='sm'
            onClick={handleSaveDemographics}
            disabled={saveDemographicsState.loading}
          >
            Save
          </PrimaryButton>
        </Col>
      </Row>
    </div>
  )
}

export default Demographics