/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'

const FormCheckGroup = ({ name, type, options, required, label, values, updateValue, prescription, inline, optionsInOneColumn }) => {
  if (type === 'checkbox') {
    return (
      <>
        {label && (
          <Row>
            <Col>
              <Form.Label>{label}</Form.Label>
            </Col>
          </Row>
        )}
        <Row>
          {
            optionsInOneColumn ? (
              <Col>
                {
                  options.map((option, index) => (
                    <React.Fragment key={`option-${name}-${index}`}>
                      {
                        option.option.value.toLowerCase().includes('other') ? (
                          <Row>
                            <Col sm={6}>
                              <Form.Check
                                type={type}
                                name={name}
                                id={name}
                                inline='true'
                                label={option.option.label}
                                value={option.option.value}
                                className='mb-1'
                                checked={values[name].value === option.option.value}
                                onChange={(event) => {
                                  updateValue(name, event.target.value)
                                  updateValue(`other${name}`, '')
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Form.Control
                                size='sm'
                                type='text'
                                name={`other${name}`}
                                className='ml-2'
                              />
                            </Col>
                          </Row>
                        ) : (
                          <>
                            <Form.Check
                              type={type}
                              name={name}
                              id={name}
                              inline='true'
                              label={option.option.label}
                              value={option.option.value}
                              className='mb-1'
                              checked={values[name].value === option.option.value}
                              onChange={(event) => {
                                updateValue(name, event.target.value)
                                if (values[`other${name}`]) {
                                  updateValue(`other${name}`, '')
                                }
                              }}
                            />
                          </>
                        )
                      }
                    </React.Fragment>
                  ))
                }
              </Col>
            ) : (
              <>
                {
                  options.map((option, index) => (
                    <React.Fragment key={`option-${name}-${index}`}>
                      <Col>
                        {
                          option.option.value.toLowerCase().includes('other') ? (
                            <Row>
                              <Col sm={7}>
                                <Form.Check
                                  type={type}
                                  name={name}
                                  id={name}
                                  inline='true'
                                  label={option.option.label}
                                  value={option.option.value}
                                  className='mb-1'
                                  checked={values ? values[name].value === option.option.value : false}
                                  onChange={(event) => {
                                    if (values) {
                                      updateValue(name, event.target.value)
                                    }
                                  }}
                                />
                              </Col>
                              <Col sm={4}>
                                <Form.Control
                                  size='sm'
                                  type='text'
                                  name={`${name}Other`}
                                  className='ml-2'
                                  value={values ? values[`${name}Other`].value : ''}
                                  onChange={(event) => {
                                    if (values) {
                                      updateValue(`${name}Other`, event.target.value)
                                    }
                                  }}
                                  disabled={values ? values[name].value !== option.option.value : true}
                                />
                              </Col>
                            </Row>
                          ) : (
                            <Form.Check className='mb-1'>
                              <Form.Check.Input
                                type={type}
                                name={name}
                                id={name}
                                inline='true'
                                value={option.option.value}
                                checked={values ? values[name].value === option.option.value : false}
                                onChange={(event) => {
                                  if (values) {
                                    if (event.target.checked) {
                                      updateValue(name, event.target.value)

                                      const otherValue = values[`${name}Other`]
                                      if (otherValue) {
                                        updateValue(`${name}Other`, '')
                                      }
                                    } else {
                                      updateValue(name, '')
                                    }
                                  }
                                }}
                              />
                              <Form.Check.Label dangerouslySetInnerHTML={{ __html: option.option.label }} />
                            </Form.Check>
                          )
                        }
                      </Col>
                    </React.Fragment>
                  ))
                }
              </>
            )
          }
        </Row>
      </>
    )
  }

  if (prescription) {
    if (options.length === 0) {
      return (
        <div>
          {label && label}
          <p>None</p>
        </div>
      )
    }

    if (options.length === 1) {
      return (
        <div style={{
          marginBottom: -10
        }}>
          {label && label}
          <p dangerouslySetInnerHTML={{ __html: options[0].option.label }} />
        </div>
      )
    }
  }

  return (
    <>
      {label && (
        <Row>
          <Col>
            <Form.Label>{label}</Form.Label>
          </Col>
        </Row>
      )}
      <Form.Group as={Row}>
        {
          options.map((option, index) => (
            <React.Fragment key={`option-${name}-${index}`}>
              {
                optionsInOneColumn ? (
                  <Col md={option.option.value.toLowerCase().includes('other') ? 2 : 1}>
                    <Row>
                      <Col>
                        <Form.Check
                          type={type}
                          name={name}
                          label={option.option.label}
                          value={option.option.value}
                          required={required}
                          inline={inline === 'inline' ? 'true' : 'false'}
                          checked={values[name].value === option.option.value}
                          onChange={(event) => {
                            updateValue(name, event.target.value)
                            if (values[`${name}Other`]) {
                              updateValue(`${name}Other`, '')
                            }
                          }}
                        />
                      </Col>
                      {
                        option.option.value.toLowerCase().includes('other') && (
                          <Col>
                            <Form.Control
                              size='sm'
                              name={`${name}Other`}
                              className='ml-2'
                              disabled={values[name].value !== 'other'}
                              value={values[`${name}Other`].value}
                              onChange={(event) => updateValue(event.target.name, event.target.value)}
                            />
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                ) : (
                  <Col md={12}>
                    <Row>
                      <Col>
                        <Form.Check>
                          <Form.Check.Input
                            type={type}
                            name={name}
                            id={name}
                            inline={inline === 'inline' ? 'true' : 'false'}
                            value={option.option.value}
                            checked={values ? values[name].value === option.option.value : false}
                            onChange={(event) => {
                              if (values) {
                                updateValue(name, event.target.value)
                              }
                            }}
                          />
                          <Form.Check.Label dangerouslySetInnerHTML={{ __html: option.option.label }} />
                        </Form.Check>
                      </Col>
                      {
                        option.option.value.toLowerCase().includes('other') && (
                          <Col>
                            <Form.Control
                              size='sm'
                              name={`${name}Other`}
                              className='ml-2'
                              disabled={values[name].value !== 'other'}
                              value={values[`${name}Other`].value}
                              onChange={(event) => updateValue(name, event.target.value)}
                            />
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                )
              }
            </React.Fragment>
          ))
        }
      </Form.Group>
    </>
  )
}

FormCheckGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  orientation: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.any,
  required: PropTypes.number,
  values: PropTypes.any,
  updateValue: PropTypes.func,
  prescription: PropTypes.bool,
  inline: PropTypes.any,
  optionsInOneColumn: PropTypes.bool
}

export default FormCheckGroup