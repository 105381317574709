import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Settings from './Settings'
import { createNewProgram, getManufacturers, getProgram, getProgramTypes, updateProgramSettings, updateStep } from '../../../features/admin/adminAction'
import Landing from './Landing'
import Demographics from './Demographics'
import { useParams } from 'react-router-dom'

const Form = () => {
  const { programId } = useParams()
  const dispatch = useDispatch()

  const { step, action, programTypes, manufacturers, createNewProgramState, program, getProgramState, updateProgramSettingsState } = useSelector((state) => ({
    step: state.admin.step,
    action: state.admin.action,
    program: state.admin.program,
    programTypes: state.admin.programTypes,
    manufacturers: state.admin.manufacturers,
    createNewProgramState: state.admin.createNewProgram,
    getProgramState: state.admin.getProgram,
    updateProgramSettingsState: state.admin.updateProgramSettings
  }))

  useEffect(() => {
    if (programTypes.length === 0) {
      dispatch(getProgramTypes())
    }
  }, [programTypes])

  useEffect(() => {
    if (manufacturers.length === 0) {
      dispatch(getManufacturers())
    }
  }, [manufacturers])

  const handleCreateProgram = (values) => {
    dispatch(createNewProgram(values))
  }

  const handleUpdateProgramSettings = (values) => {
    dispatch(updateProgramSettings(values))
  }

  useEffect(() => {
    if (createNewProgramState.success || updateProgramSettingsState.success) {
      dispatch(updateStep('landing'))
    }
  }, [createNewProgramState.success, updateProgramSettingsState.success])

  useEffect(() => {
    if (action === 'edit' && !getProgramState.success) {
      dispatch(getProgram(programId))
    }
  }, [action, getProgramState.success])

  switch (step) {
  case 'settings':
    return (
      <Settings
        program={program}
        getProgramState={getProgramState}
        action={action}
        programTypes={programTypes}
        manufacturers={manufacturers}
        createNewProgramState={createNewProgramState}
        createNewProgram={handleCreateProgram}
        updateProgramSettings={handleUpdateProgramSettings}
        updateProgramSettingsState={updateProgramSettingsState}
      />
    )
  case 'landing':
    return (
      <Landing />
    )
  case 'demographics':
    return (
      <Demographics />
    )
  default:
    return <div>Bad step go back</div>
  }
}

export default Form