import types from '../types'

const initialState = {
  healthSystem: {
    loading: false,
    success: false,
    error: false,
    data: {}
  },
  user: '',
  medications: {
    loading: false,
    success: false,
    error: false,
    data: []
  },
  getProgram: {
    loading: false,
    success: false,
    error: false
  },
  program: null,
  getCopayOffer: {
    loading: false,
    success: false,
    error: false
  },
  copayOffer: null,
  getPatient: {
    loading: false,
    success: false,
    error: false
  },
  patient: null,
  fhirPatient: null,
  conditions: null,
  startSession: {
    loading: false,
    success: false,
    error: false
  },
  session: null,
  practitioner: null,
  getPatientCoverages: {
    loading: false,
    success: false,
    error: false
  },
  coverages: [],
  action: null,
  updateSessionAction: {
    loading: false,
    success: false,
    error: false
  },
  actionId: 0
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_HEALTH_SYSTEM_REQUESTED: {
    return {
      ...state,
      healthSystem: {
        loading: true,
        error: false,
        success: false,
        data: {}
      }
    }
  }
  case types.GET_HEALTH_SYSTEM_FULFILLED: {
    const { healthSystem, user } = action.payload
    return {
      ...state,
      healthSystem: {
        ...state.healthSystem,
        loading: false,
        success: true,
        data: healthSystem
      },
      user
    }
  }
  case types.GET_HEALTH_SYSTEM_REJECTED: {
    return {
      ...state,
      healthSystem: {
        ...state.healthSystem,
        loading: false,
        error: true
      }
    }
  }
  case types.GET_PATIENT_MEDICATIONS_REQUESTED: {
    return {
      ...state,
      medications: {
        loading: true,
        error: false,
        success: false,
        data: []
      }
    }
  }
  case types.GET_PATIENT_MEDICATION_FULFILLED: {
    return {
      ...state,
      medications: {
        ...state.medications,
        loading: false,
        success: true,
        data: action.payload
      }
    }
  }
  case types.GET_PATIENT_MEDICATION_REJECTED: {
    return {
      ...state,
      medications: {
        ...state.medications,
        loading: false,
        error: true
      }
    }
  }
  case types.GET_SESSION_PROGRAM_REQUESTED: {
    return {
      ...state,
      getProgram: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_SESSION_PROGRAM_FULFILLED: {
    return {
      ...state,
      getProgram: {
        ...state.getProgram,
        loading: false,
        success: true
      },
      program: action.payload
    }
  }
  case types.GET_SESSION_PROGRAM_REJECTED: {
    return {
      ...state,
      getProgram: {
        ...state.getProgram,
        loading: false,
        error: true
      }
    }
  }
  case types.GET_PATIENT_REQUESTED: {
    return {
      ...state,
      getPatient: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_PATIENT_FULFILLED: {
    const { data: { patient, conditions, fhirPatient } } = action.payload
    return {
      ...state,
      getPatient: {
        ...state.getPatient,
        loading: false,
        success: true
      },
      patient,
      conditions,
      fhirPatient
    }
  }
  case types.GET_PATIENT_REJECTED: {
    return {
      ...state,
      getPatient: {
        ...state.getPatient,
        loading: false,
        error: true
      }
    }
  }
  case types.START_SESSION_REQUESTED: {
    return {
      ...state,
      startSession: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.START_SESSION_FULFILLED: {
    return {
      ...state,
      startSession: {
        ...state.startSession,
        loading: false,
        success: true
      },
      session: action.payload
    }
  }
  case types.START_SESSION_REJECTED: {
    return {
      ...state,
      startSession: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.GET_PATIENT_COVERAGES_REQUESTED: {
    return {
      ...state,
      getPatientCoverages: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_PATIENT_COVERAGES_FULFILLED: {
    return {
      ...state,
      getPatientCoverages: {
        ...state.getPatientCoverages,
        loading: false,
        success: true
      },
      coverages: action.payload
    }
  }
  case types.GET_PATIENT_COVERAGES_REJECTED: {
    return {
      ...state,
      getPatientCoverages: {
        ...state.getPatientCoverages,
        loading: false,
        error: true
      }
    }
  }
  case types.UPDATE_PATIENT_SESSION: {
    const { patient, session } = action.payload
    return {
      ...state,
      session,
      patient
    }
  }
  case types.SET_PROGRAM: {
    return {
      ...state,
      program: action.payload
    }
  }
  case types.SET_SESSION: {
    const sessionActionIndex = state.session.actions.findIndex((sessionAction) => sessionAction.sessionActionId === action.payload.sessionActionId)
    return {
      ...state,
      session: {
        ...state.session,
        actions: [
          ...state.session.actions.slice(0, sessionActionIndex),
          action.payload,
          ...state.session.actions.slice(sessionActionIndex + 1)
        ]
      }
    }
  }
  case types.GET_CO_PAY_OFFER_REQUESTED: {
    return {
      ...state,
      copayOffer: null,
      getCopayOffer: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_CO_PAY_OFFER_FULFILLED: {
    return {
      ...state,
      copayOffer: action.payload,
      getCopayOffer: {
        loading: false,
        success: true,
        error: false
      }
    }
  }
  case types.GET_CO_PAY_OFFER_REJECTED: {
    return {
      ...state,
      getCopayOffer: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.SET_CURRENT_ACTION: {
    return {
      ...state,
      action: action.payload
    }
  }
  case types.START_ACTION_REQUESTED: {
    return {
      ...state,
      updateSessionAction: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.START_ACTION_FULFILLED: {
    const actionIndex = state.session.actions.findIndex((sessionAction) => sessionAction.sessionActionId === action.payload.sessionActionId)
    return {
      ...state,
      updateSessionAction: {
        loading: false,
        success: true,
        error: false
      },
      session: {
        ...state.session,
        actions: [
          ...state.session.actions.slice(0, actionIndex),
          {
            ...action.payload
          },
          ...state.session.actions.slice(actionIndex + 1)
        ]
      }
    }
  }
  case types.START_ACTION_REJECTED: {
    return {
      ...state,
      updateSessionAction: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.SET_CURRENT_ACTION_ID: {
    return {
      ...state,
      actionId: action.payload
    }
  }
  case types.CLEAR_PROGRAM: {
    return {
      ...state,
      getProgram: {
        loading: false,
        success: false,
        error: false
      },
      program: null,
    }
  }
  default:
    return state
  }
}

export default userReducer