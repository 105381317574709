import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const FormSelect = ({ name, label, required, options, checkAny, formValue, updateValue }) => {
  if (checkAny) {
    if (options.length === 1 && options[0].option.value === 'Any') {
      return (
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <p>{options[0].option.value} {label.split(' ')[1]}</p>
        </Form.Group>
      )
    }
  }
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as='select'
        id={name}
        name={name}
        required={required}
        size='sm'
        value={formValue}
        onChange={(event) => updateValue(name, event.target.value)}
      >
        {
          options.map((option) => (
            <option key={`option-${option.listValueId}`} value={option.option.value}>
              {option.option.label}
            </option>
          ))
        }
      </Form.Control>
    </Form.Group>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.number,
  options: PropTypes.any,
  checkAny: PropTypes.bool,
  formValue: PropTypes.string,
  updateValue: PropTypes.func
}


export default FormSelect