import axios from 'axios'
import { getApiServer } from '../../utils/helpers'

const serverUrl = getApiServer()

const getProviderForm = async (programId) => {
  return await axios.get(`${serverUrl}/form`, { params: { program: programId, formStep: 1 }})
}

const signForm = async (data) => {
  return await axios.post(`${serverUrl}/enroll`, data)
}

const printPdf = async (pdf) => {
  return await axios.post(`${serverUrl}/print`, pdf)
}

const sendToPool = async (payload) => {
  return await axios.post(`${serverUrl}/send-to-pool`, payload)
}

const saveFormValues = async (formValues) => {
  return await axios.post(`${serverUrl}/save-provider-form`, formValues)
}

const sendMessage = async (iss, message, sysLogin, mrn, encounter, accessToken, healthSystem) => {
  const serverUrl = iss.replace('/api/FHIR/R4', '')

  const healthSystemRecipient = healthSystem.pools[0]
  const payload = {
    MessageType: '1',
    MessagePriority: '2',
    SenderID: sysLogin,
    SenderIDType: 'FHIR',
    MessageText: [message],
    PatientID: mrn,
    PatientIDType: 'MRN',//IIT
    ContactID: encounter,
    ContactIDType: 'CSN',
    Recipients: [
      {
        ID: healthSystemRecipient.identifier,
        IDType: healthSystemRecipient.identifierType,
        IsPool: healthSystemRecipient.isPool === 1
      }
    ]
  }

  return await axios.post(`${serverUrl}/api/epic/2014/Common/Utility/SENDMESSAGE/Message`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
}

const sendPatientCopayOffer = async (healthSystemId, sessionActionId) => {
  return await axios.post(`${getApiServer()}/send-copay-offer`, { healthSystemId, sessionActionId })
}

const providerService = {
  getProviderForm,
  signForm,
  printPdf,
  sendMessage,
  saveFormValues,
  sendPatientCopayOffer,
  sendToPool
}

export default providerService