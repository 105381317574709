import React from 'react'
import { Container } from 'react-bootstrap'
import PatientHeader from '../../../components/PatientHeader'
import { useSelector } from 'react-redux'
import { getPatientBirthDate, getPatientName } from '../../../utils/helpers'

const ProviderExit = () => {
  const { session } = useSelector((state) => ({
    session: state.user.session
  }))

  return (
    <Container className='shadow p-0 bg-white mb-4'>
      <Container className='m-0 p-0'>
        <PatientHeader
          className='px-3 py-2'
          color='#e0e1e1'
        >
          <dt>Patient Name:</dt>
          <dd> {session ? getPatientName(session.patient) : ''}</dd>
          <dt>MRN:</dt>
          <dd> {session ? session.patient.mrn : ''}</dd>
          <dt>DOB:</dt>
          <dd> {session ? getPatientBirthDate(session.patient) : ''}</dd>
        </PatientHeader>
      </Container>
      <Container className='m-0 pt-0 pl-3 pb-2'>
        <p className='font-weight-bold text-info'>
        You have exited the current session. Please close the window.
        </p>
      </Container>
    </Container>
  )
}

export default ProviderExit