import types from '../types'

const initialState = {
  sortKey: '',
  sortDirection: 'ASC',
  action: 'edit',
  step: 'landing',
  programTypes: [],
  manufacturers: [],
  getPrograms: {
    loading: false,
    success: false,
    error: false,
    data: []
  },
  getProgram: {
    loading: false,
    success: false,
    error: false
  },
  programs: [],
  createNewProgram: {
    loading: false,
    success: false,
    error: false
  },
  program: {},
  sections: [],
  patientSections: [],
  getSection: {
    loading: false,
    success: false,
    error: false
  },
  section: {},
  elements: [],
  library: [],
  userDefined: [],
  allElementsActive: true,
  allElementsDisabled: false,
  updateProviderSection: {
    loading: false,
    success: false,
    error: false
  },
  getAttestationSection: {
    loading: false,
    success: false,
    error: false
  },
  attestation: [],
  updateAttestationSection: {
    loading: false,
    success: false,
    error: false
  },
  dosingBases: [],
  drugProducts: [],
  updatePrescriptionSection: {
    loading: false,
    success: false,
    error: false
  },
  demographics: [],
  saveDemographics: {
    loading: false,
    success: false,
    error: false
  },
  updatePatientSection: {
    loading: false,
    success: false,
    error: false
  },
  updateProgram: {
    loading: false,
    success: false,
    error: false
  },
  resetClinicalForm: {
    loading: false,
    success: false,
    error: false
  },
  resetPatientForm: {
    loading: false,
    success: false,
    error: false
  },
  updateProgramSettings: {
    loading: false,
    success: false,
    error: false
  },
  getFormSection: {
    loading: false,
    success: false,
    error: false
  },
  saveProviderSection: {
    loading: false,
    success: false,
    error: false
  },
  getAttestationFormSection: {
    loading: false,
    success: false,
    error: false
  },
  editAttestationSection: {
    loading: false,
    success: false,
    error: false
  },
  editPatientSection: {
    loading: false,
    success: false,
    error: false
  },
  editPrescriptionSection: {
    loading: false,
    success: false,
    error: false
  }
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PROGRAM_TYPES: {
    return {
      ...state,
      programTypes: action.payload
    }
  }
  case types.GET_MANUFACTURERS: {
    return {
      ...state,
      manufacturers: action.payload
    }
  }
  case types.GET_PROGRAMS_REQUESTED: {
    return {
      ...state,
      getPrograms: {
        loading: true,
        success: false,
        error: false,
        data: []
      }
    }
  }
  case types.GET_PROGRAMS_FULFILLED: {
    return {
      ...state,
      getPrograms: {
        ...state.getPrograms,
        loading: false,
        success: true,
        data: action.payload
      },
      programs: action.payload
    }
  }
  case types.GET_PROGRAMS_REJECTED: {
    return {
      ...state,
      getPrograms: {
        ...state.getPrograms,
        loading: false,
        error: action.payload
      }
    }
  }
  case types.GET_PROGRAM_REQUESTED: {
    return {
      ...state,
      getProgram: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.GET_PROGRAM_FULFILLED: {
    const { programSections, ...program } = action.payload
    const sections = programSections.map((programSection) => programSection.section)

    const providerSections = []
    const patientSections = []

    for (let i = 0; i < sections.length; i += 1) {
      const section = sections[i]

      if (section.form === 'clinical') {
        providerSections.push({
          ...section,
          status: section.formSection ? 'EDITED' : 'TO DO'
        })
      }

      if (section.form === 'patient') {
        patientSections.push({
          ...section,
          status: section.formSection ? 'EDITED' : 'TO DO'
        })
      }
    }

    return {
      ...state,
      getProgram: {
        loading: false,
        error: false,
        success: true
      },
      sections: providerSections,
      patientSections,
      program: { ...program }
    }
  }
  case types.GET_PROGRAM_REJECTED: {
    return {
      ...state,
      getProgram: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.CREATE_NEW_PROGRAM_REQUESTED: {
    return {
      ...state,
      createNewProgram: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.CREATE_NEW_PROGRAM_FULFILLED: {
    const { programSections, ...program } = action.payload

    const sections = programSections.map((programSection) => programSection.section)

    const providerSections = []
    const patientSections = []

    for (let i = 0; i < sections.length; i += 1) {
      const section = sections[i]

      if (section.form === 'clinical') {
        providerSections.push({
          ...section,
          status: 'TO DO'
        })
      }

      if (section.form === 'patient') {
        patientSections.push({
          ...section,
          status: 'TO DO'
        })
      }
    }

    return {
      ...state,
      createNewProgram: {
        ...state.createNewProgram,
        loading: false,
        success: true
      },
      sections: providerSections,
      patientSections,
      program: { ...program }
    }
  }
  case types.CREATE_NEW_PROGRAM_REJECTED: {
    return {
      ...state,
      createNewProgram: {
        ...state.createNewProgram,
        loading: false,
        error: true
      }
    }
  }
  case types.GET_SECTION_REQUESTED: {
    return {
      ...state,
      getSection: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_SECTION_FULFILLED: {
    const { elements, ...section } = action.payload

    const sectionElements = elements.reduce((acc, curr) => {
      if (curr.default === 1) {
        const element = {
          elementId: curr.elementId,
          elementTypeId: curr.elementTypeId,
          elementType: curr.elementType.name,
          configurable: curr.configurable,
          default: curr.default,
          caption: curr.label,
          customCaption: '',
          options: curr.options.map((o) => o.option),
          appendDate: curr.appendDate,
          autoFilled: curr.autoFilled,
          value: curr.value,
          name: curr.name,
          checked: curr.configurable === 1 && curr.default === 1
        }

        let newElements = [...acc, element]
        if (element.appendDate === 1) {
          const dateElement = {
            elementId: curr.elementId - 1000000,
            parentElementId: curr.elementId,
            elementTypeId: curr.elementTypeId,
            elementType: 'Date / Time',
            configurable: 1,
            default: curr.default,
            caption: curr.label + ' Date',
            customCaption: `N/A - Will append to ${curr.label}`,
            options: [],
            appendDate: curr.appendDate,
            autoFilled: curr.autoFilled,
            checked: true
          }

          newElements = [...newElements, dateElement]
        }
        
        return newElements
      }

      return acc
    }, [])

    const sectionLibrary = elements.reduce((acc, curr) => {
      if (curr.default === 0) {
        const element = {
          elementId: curr.elementId,
          elementTypeId: curr.elementTypeId,
          elementType: curr.elementType.name,
          configurable: curr.configurable,
          default: curr.default,
          caption: curr.label,
          customCaption: '',
          options: curr.options.map((o) => o.option),
          appendDate: curr.appendDate,
          autoFilled: curr.autoFilled
        }
        return [...acc, element]
      }
      return acc
    }, [])

    return {
      ...state,
      getSection: {
        loading: false,
        success: true,
        error: false
      },
      userDefined: [],
      section: {
        sectionId: section.sectionId,
        caption: section.text,
        customCaption: '',
        column: section.column,
        subCaption: section.subText,
        customSubCaption: '',
        order: section.order
      },
      allElementsActive: true,
      allElementsDisabled: [12, 13].includes(section.sectionId),
      elements: sectionElements,
      library: sectionLibrary
    }
  }
  case types.GET_SECTION_REJECTED: {
    return {
      ...state,
      getSection: {
        ...state.getSection,
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.GET_FORM_SECTION_REQUESTED: {
    return {
      ...state,
      getFormSection: {
        loading: true,
        success: false,
        error: false
      },
      userDefined: []
    }
  }
  case types.GET_FORM_SECTION_FULFILLED: {
    const formSection = action.payload

    const elements = []
    const library = []

    formSection.section.elements.forEach((element) => {
      const formElement = formSection.elements.find((formElement) => formElement.elementId === element.elementId)
      if (formElement) {
        if (formElement.elementId !== null) {
          elements.push({
            formElementId: formElement.formElementId,
            elementId: formElement.elementId,
            elementTypeId: formElement.elementTypeId,
            elementType: formElement.elementType.name,
            configurable: formElement.element.configurable,
            default: formElement.element.default,
            caption: formElement.label,
            customCaption: '',
            options: formElement.element.options.map((o) => o.option),
            appendDate: formElement.appendDate,
            autoFilled: formElement.element.autoFilled,
            value: formElement.value,
            name: formElement.name,
            checked: true
          })
        }

        if (element.appendDate) {
          elements.push({
            elementId: formElement.elementId - 1000000,
            parentElementId: formElement.elementId,
            elementTypeId: formElement.elementTypeId,
            elementType: 'Date / Time',
            configurable: 1,
            default: 1,
            caption: formElement.label + ' Date',
            customCaption: `N/A - Will append to ${formElement.label}`,
            options: [],
            appendDate: formElement.appendDate,
            autoFilled: formElement.element.autoFilled,
            checked: formElement.appendDate === 1
          })
        }
      } else {
        if (element.default === 1) {
          elements.push({
            elementId: element.elementId,
            elementTypeId: element.elementTypeId,
            elementType: element.elementType.name,
            configurable: element.configurable,
            default: element.default,
            caption: element.label,
            customCaption: '',
            options: element.options.map((o) => o.option),
            appendDate: element.appendDate,
            autoFilled: element.autoFilled,
            checked: false
          })
        } else {
          library.push({
            elementId: element.elementId,
            elementTypeId: element.elementTypeId,
            elementType: element.elementType.name,
            configurable: element.configurable,
            default: element.default,
            caption: element.label,
            customCaption: '',
            options: element.options.map((o) => o.option),
            appendDate: element.appendDate,
            autoFilled: element.autoFilled,
            checked: false
          })
        }
      }
    })

    return {
      ...state,
      getFormSection: {
        loading: false,
        success: true,
        error: false
      },
      section: {
        sectionId: formSection.formSectionId,
        childSectionId: formSection.sectionId,
        caption: formSection.text,
        customCaption: '',
        column: formSection.column,
        subCaption: formSection.subText,
        customSubCaption: ''
      },
      elements,
      library,
      userDefined: formSection.elements.reduce((acc, curr) => {
        let newAcc = acc

        if (curr.elementId === null) {
          newAcc.push(
            {
              userDefinedId: curr.formElementId,
              caption: curr.label,
              customCaption: ''
            
            })
        }
        return newAcc
      }, []),
      allElementsActive: elements.some((element) => element.checked),
      allElementsDisabled: [12, 13].includes(formSection.sectionId)
    }
  }
  case types.GET_FORM_SECTION_REJECTED: {
    return {
      ...state,
      getFormSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.UPDATE_PROVIDER_SECTION_REQUESTED: {
    return {
      ...state,
      updateProviderSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.UPDATE_PROVIDER_SECTION_FULFILLED: {
    const formSection = action.payload
    const sectionIndex = state.sections.findIndex((section) => section.sectionId === formSection.sectionId)

    return {
      ...state,
      updateProviderSection: {
        loading: false,
        success: true,
        error: false
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.UPDATE_PROVIDER_SECTION_REJECTED: {
    return {
      ...state,
      updateProviderSection: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.SAVE_PROVIDER_SECTION_REQUESTED: {
    return {
      ...state,
      saveProviderSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.SAVE_PROVIDER_SECTION_FULFILLED: {
    const formSection = action.payload
    const sectionIndex = state.sections.findIndex((section) => {
      if (section.formSection) {
        return section.formSection.formSectionId === formSection.formSection.formSectionId
      }

      return -1
    })

    return {
      ...state,
      saveProviderSection: {
        loading: false,
        success: true,
        error: false
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.SAVE_PROVIDER_SECTION_REJECTED: {
    return {
      ...state,
      saveProviderSection: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.GET_ATTESTATION_SECTION_REQUESTED: {
    return {
      ...state,
      getAttestationSection: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_ATTESTATION_SECTION_FULFILLED: {
    const { elements, ...section } = action.payload

    const attestor = elements[0]
    const attestorElement = {
      elementId: attestor.elementId,
      fieldType: 'Radio Group',
      options: [{
        optionId: attestor.options[0].listValueId,
        caption: attestor.options[0].option.label,
        customCaption: ''
      }, {
        optionId: attestor.options[1].listValueId,
        caption: attestor.options[1].option.label,
        customCaption: ''
      }]
    }

    const attestation = elements[1]
    const attestationElement = {
      elementId: attestation.elementId,
      caption: attestation.label,
      customCaption: '',
      fieldType: 'Provider Attestation',
      value: '',
      placeholder: attestation.placeholder
    }

    const agreedToAttestationCheckbox = elements[2]
    const checkboxElement = {
      elementId: agreedToAttestationCheckbox.elementId,
      caption: agreedToAttestationCheckbox.label,
      customCaption: '',
      fieldType: 'Checkbox'
    }

    const signatureElement = {
      elementId: elements[3].elementId,
      caption: 'N/A',
      customCaption: 'N/A',
      fieldType: 'eSign'
    }
        
    return {
      ...state,
      getAttestationSection: {
        loading: false,
        success: true,
        error: false
      },
      section: {
        sectionId: section.sectionId,
        caption: section.text,
        customCaption: '',
        column: section.column,
        order: section.order
      },
      attestation: [attestorElement, attestationElement, checkboxElement, signatureElement]
    }
  }
  case types.GET_ATTESTATION_SECTION_REJECTED: {
    return {
      ...state,
      getAttestationSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.GET_ATTESTATION_FORM_SECTION_REQUESTED: {
    return {
      ...state,
      getAttestationFormSection: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_ATTESTATION_FORM_SECTION_FULFILLED: {
    const { elements, ...section } = action.payload
    const attestor = elements[0]
    const attestorElement = {
      elementId: attestor.elementId,
      formElementId: attestor.formElementId,
      fieldType: 'Radio Group',
      options: [{
        optionId: attestor.element.options[0].listValueId,
        caption: attestor.element.options[0].option.label,
        customCaption: ''
      }, {
        optionId: attestor.element.options[1].listValueId,
        caption: attestor.element.options[1].option.label,
        customCaption: ''
      }]
    }

    const attestation = elements[1]
    const attestationElement = {
      elementId: attestation.elementId,
      formElementId: attestation.formElementId,
      caption: attestation.label,
      customCaption: '',
      fieldType: 'Provider Attestation',
      value: attestation.value,
      placeholder: attestation.placeholder
    }

    const agreedToAttestationCheckbox = elements[2]
    const checkboxElement = {
      elementId: agreedToAttestationCheckbox.elementId,
      formElementId: agreedToAttestationCheckbox.formElementId,
      caption: agreedToAttestationCheckbox.label,
      customCaption: '',
      fieldType: 'Checkbox'
    }

    const signatureElement = {
      elementId: elements[3].elementId,
      formElementId: elements[3].formElementId,
      caption: 'N/A',
      customCaption: 'N/A',
      fieldType: 'eSign'
    }

    return {
      ...state,
      getAttestationFormSection: {
        loading: false,
        success: true,
        error: false
      },
      section: {
        sectionId: section.formSectionId,
        caption: section.text,
        customCaption: '',
        column: section.column,
        order: section.order
      },
      attestation: [attestorElement, attestationElement, checkboxElement, signatureElement]
    }
  }
  case types.GET_ATTESTATION_FORM_SECTION_REJECTED: {
    return {
      ...state,
      getAttestationFormSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.UPDATE_ATTESTATION_SECTION_REQUESTED: {
    return {
      ...state,
      updateAttestationSection: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.UPDATE_ATTESTATION_SECTION_FULFILLED: {
    const formSection = action.payload

    const sectionIndex = state.sections.findIndex((section) => section.sectionId === formSection.sectionId)

    return {
      ...state,
      updateAttestationSection: {
        loading: false,
        success: true,
        error: false
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.UPDATE_ATTESTATION_SECTION_REJECTED: {
    return {
      ...state,
      updateAttestationSection: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.EDIT_ATTESTATION_SECTION_REQUESTED: {
    return {
      ...state,
      editAttestationSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.EDIT_ATTESTATION_SECTION_FULFILLED: {
    const formSection = action.payload

    const sectionIndex = state.sections.findIndex((section) => section.sectionId === formSection.sectionId)

    return {
      ...state,
      editAttestationSection: {
        loading: false,
        success: true,
        error: false
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.EDIT_ATTESTATION_SECTION_REJECTED: {
    return {
      ...state,
      editAttestationSection: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.GET_DOSING_BASES: {
    return {
      ...state,
      dosingBases: action.payload
    }
  }
  case types.GET_DRUG_PRODUCTS: {
    return {
      ...state,
      drugProducts: action.payload
    }
  }
  case types.UPDATE_PRESCRIPTION_SECTION_REQUESTED: {
    return {
      ...state,
      updatePrescriptionSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.UPDATE_PRESCRIPTION_SECTION_FULFILLED: {
    const { newFormSection, program } = action.payload

    const sectionIndex = state.sections.findIndex((section) => section.sectionId === newFormSection.sectionId)

    return {
      ...state,
      updatePrescriptionSection: {
        loading: false,
        success: true,
        error: false
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...newFormSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ],
      program
    }
  }
  case types.UPDATE_PRESCRIPTION_SECTION_REJECTED: {
    return {
      ...state,
      updatePrescriptionSection: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.GET_PATIENT_DEMOGRAPHICS: {
    return {
      ...state,
      demographics: action.payload
    }
  }
  case types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REQUESTED: {
    return {
      ...state,
      saveDemographics: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_FULFILLED: {
    return {
      ...state,
      saveDemographics: {
        loading: false,
        success: true,
        error: false
      }
    }
  }
  case types.SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REJECTED: {
    return {
      ...state,
      saveDemographics: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.UPDATE_PATIENT_SECTION_REQUESTED: {
    return {
      ...state,
      updatePatientSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.UPDATE_PATIENT_SECTION_FULFILLED: {
    const formSection = action.payload
    const sectionIndex = state.patientSections.findIndex((section) => section.sectionId === formSection.sectionId)
        
    return {
      ...state,
      updatePatientSection: {
        loading: false,
        error: false,
        success: true
      },
      patientSections: [
        ...state.patientSections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.patientSections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.UPDATE_PATIENT_SECTION_REJECTED: {
    return {
      ...state,
      updatePatientSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.EDIT_PATIENT_SECTION_REQUESTED: {
    return {
      ...state,
      editPatientSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.EDIT_PATIENT_SECTION_FULFILLED: {
    const formSection = action.payload
    const sectionIndex = state.patientSections.findIndex((section) => section.sectionId === formSection.sectionId)
        
    return {
      ...state,
      editPatientSection: {
        loading: false,
        error: false,
        success: true
      },
      patientSections: [
        ...state.patientSections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.patientSections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.EDIT_PATIENT_SECTION_REJECTED: {
    return {
      ...state,
      editPatientSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.UPDATE_PROGRAM_REQUESTED: {
    return {
      ...state,
      updateProgram: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.UPDATE_PROGRAM_FULFILLED: {
    return {
      ...state,
      updateProgram: {
        loading: false,
        error: false,
        success: true
      },
      program: {
        ...state.program,
        ...action.payload
      }
    }
  }
  case types.UPDATE_PROGRAM_REJECTED: {
    return {
      ...state,
      updateProgram: {
        lpading: false,
        error: true,
        success: false
      }
    }
  }
  case types.UPDATE_PROGRAM_SETTINGS_REQUESTED: {
    return {
      ...state,
      updateProgramSettings: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.UPDATE_PROGRAM_SETTINGS_FULFILLED: {
    const { programSections, ...program } = action.payload
    const sections = programSections.map((programSection) => programSection.section)

    const providerSections = []
    const patientSections = []

    sections.forEach((section) => {
      if (section.form === 'clinical') {
        providerSections.push({
          ...section,
          status: section.formSection ? 'EDITED' : 'TO DO'
        })
      }

      if (section.form === 'patient') {
        patientSections.push({
          ...section,
          status: section.formSection ? 'EDITED' : 'TO DO'
        })
      }
    })
        
    return {
      ...state,
      updateProgramSettings: {
        loading: false,
        error: false,
        success: true
      },
      sections: providerSections,
      patientSections,
      program
    }
  }
  case types.UPDATE_PROGRAM_SETTINGS_REJECTED: {
    return {
      ...state,
      updateProgramSettings: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.RESET_CLINICAL_FORM_REQUESTED: {
    return {
      ...state,
      resetClinicalForm: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.RESET_CLINICAL_FORM_FULFILLED: {
    const { programSections, ...program } = action.payload

    const sections = programSections.map((programSection) => programSection.section)

    const providerSections = []

    sections.forEach((section) => {
      if (section.form === 'clinical') {
        providerSections.push({
          ...section,
          status: 'TO DO'
        })
      }
    })
        
    return {
      ...state,
      resetClinicalForm: {
        loading: false,
        error: false,
        success: true
      },
      sections: providerSections,
      program
    }
  }
  case types.RESET_CLINICAL_FORM_REJECTED: {
    return {
      ...state,
      resetClinicalForm: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.RESET_PATIENT_FORM_REQUESTED: {
    return {
      ...state,
      resetPatientForm: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.RESET_PATIENT_FORM_FULFILLED: {
    const { programSections, ...program } = action.payload

    const sections = programSections.map((programSection) => programSection.section)
    const patientSections = []

    sections.forEach((section) => {

      if (section.form === 'patient') {
        patientSections.push({
          ...section,
          status: 'TO DO'
        })
      }
    })

    return {
      ...state,
      resetPatientForm: {
        loading: false,
        error: false,
        success: true
      },
      patientSections,
      program
    }
  }
  case types.RESET_PATIENT_FORM_REJECTED: {
    return {
      ...state,
      resetPatientForm: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.EDIT_PRESCRIPTION_SECTION_REQUESTED: {
    return {
      ...state,
      editPrescriptionSection: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.EDIT_PRESCRIPTION_SECTION_FULFILLED: {
    const formSection = action.payload
    const sectionIndex = state.sections.findIndex((section) => section.sectionId === formSection.sectionId)

    return {
      ...state,
      editPrescriptionSection: {
        loading: false,
        error: false,
        success: true
      },
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...formSection,
          status: 'EDITED'
        },
        ...state.sections.slice(sectionIndex + 1)
      ]
    }
  }
  case types.EDIT_PRESCRIPTION_SECTION_REJECTED: {
    return {
      ...state,
      editPrescriptionSection: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.SORT_PROGRAMS: {
    const { programs, sortKey, sortDirection } = action.payload

    const newPrograms = programs.sort((a, b) => {
      if (sortKey === 'programName') {
        const programA = `${a.medicationName} ${a.name}`
        const programB = `${b.medicationName} ${b.name}`
        if (programA < programB) return sortDirection === 'ASC' ? -1 : 1
        if (programA > programB) return sortDirection === 'ASC' ? 1 : -1
        return 0
      }

      if (a.medicationName < b.medicationName) return sortDirection === 'ASC' ? -1 : 1
      if (a.medicationName > b.medicationName) return sortDirection === 'ASC' ? 1 : -1
      return 0
    })

    return {
      ...state,
      sortKey,
      sortDirection,
      programs: newPrograms
    }
  }
  case types.SEARCH_PROGRAMS: {
    const { search, programType, manufacturer, status } = action.payload

    const newPrograms = state.getPrograms.data
      .filter((program) => {
        if (search) {
          const searchTerm = search.toLowerCase()
          if (program.name.toLowerCase().includes(searchTerm) || program.medicationName.toLowerCase().includes(searchTerm)) {
            return program
          }
        } else {
          return program
        }
      })
      .filter((program) => {
        if (programType) {
          return program.programTypeId === programType
        } else {
          return program
        }
      })
      .filter((program) => {
        if (manufacturer) {
          return program.manufacturerId === manufacturer
        } else {
          return program
        }
      })
      .filter((program) => {
        if (status) {
          return program.status === status
        } else {
          return program
        }
      })

    return {
      ...state,
      programs: newPrograms
    }
  }
  case types.UPDATE_STEP: {
    return {
      ...state,
      step: action.payload
    }
  }
  case types.UPDATE_ACTION: {
    return {
      ...state,
      action: action.payload
    }
  }
  case types.ADD_LIBRARY_FIELD: {
    const elementId = parseInt(action.payload, 10)

    const element = state.library.find((l) => l.elementId === elementId)

    return {
      ...state,
      library: state.library.filter((l) => l.elementId !== element.elementId),
      elements: [
        ...state.elements,
        element
      ]
    }
  }
  case types.REMOVE_LIBRARY_FIELD: {
    const element = state.elements.find((e) => e.elementId === action.payload)

    return {
      ...state,
      elements: state.elements.filter((e) => e.elementId !== element.elementId),
      library: [
        ...state.library,
        element
      ]
    }
  }
  case types.ADD_USER_DEFINED_FIELD: {
    const ids = state.userDefined.map((ud) => ud.userDefinedId)

    const id = ids.length === 0 ? 1 : Math.max(...ids) + 1

    return {
      ...state,
      userDefined: [
        ...state.userDefined,
        {
          userDefinedId: id,
          caption: 'User Defined' + id,
          customCaption: ''
        }
      ]
    }
  }
  case types.REMOVE_USER_DEFINED_FIELD: {
    return {
      ...state,
      userDefined: state.userDefined.filter((ud) => ud.userDefinedId !== action.payload)
    }
  }
  case types.UPDATE_ELEMENT_CAPTION: {
    const { value, id } = action.payload

    const elementIndex = state.elements.findIndex((element) => element.elementId === id)

    return {
      ...state,
      elements: [
        ...state.elements.slice(0, elementIndex),
        {
          ...state.elements[elementIndex],
          customCaption: value
        },
        ...state.elements.slice(elementIndex + 1)
      ]
    }
  }
  case types.UPDATE_SECTION_CAPTION: {
    return {
      ...state,
      section: {
        ...state.section,
        customCaption: action.payload
      }
    }
  }
  case types.UPDATE_SECTION_SUB_CAPTION: {
    return {
      ...state,
      section: {
        ...state.section,
        customSubCaption: action.payload
      }
    }
  }
  case types.UPDATE_USER_DEFINED_CAPTION: {
    const { value, id } = action.payload

    const index = state.userDefined.findIndex((element) => element.userDefinedId === id)

    return {
      ...state,
      userDefined: [
        ...state.userDefined.slice(0, index),
        {
          ...state.userDefined[index],
          customCaption: value
        },
        ...state.userDefined.slice(index + 1)
      ]
    }
  }
  case types.UPDATE_ELEMENT_FIELD_TYPE: {
    const { value, id } = action.payload
    const elementIndex = state.elements.findIndex((element) => element.elementId === parseInt(id, 10))
    return {
      ...state,
      elements: [
        ...state.elements.slice(0, elementIndex),
        {
          ...state.elements[elementIndex],
          elementTypeId: value
        },
        ...state.elements.slice(elementIndex + 1)
      ]
    }
  }
  case types.UPDATE_ELEMENT_OPTIONS: {
    const { options, includeOther, elementId } = action.payload
    const elementIndex = state.elements.findIndex((element) => element.elementId === elementId)
    let newOptions = options
    if (includeOther) {
      newOptions = [...newOptions, { label: 'Other', value: 'other' }]
    }

    return {
      ...state,
      elements: [
        ...state.elements.slice(0, elementIndex),
        {
          ...state.elements[elementIndex],
          options: newOptions
        },
        ...state.elements.slice(elementIndex + 1)
      ]
    }
  }
  case types.UPDATE_ALL_ELEMENTS_CHECKED: {
    const { checked } = action.payload
    return {
      ...state,
      allElementsActive: checked,
      elements: state.elements.map((element) => ({
        ...element,
        checked
      }))
    }
  }
  case types.UPDATE_ELEMENT_CHECKED: {
    const { elementId, checked } = action.payload
    const elementIndex = state.elements.findIndex((element) => element.elementId === elementId)

    const newElements = [
      ...state.elements.slice(0, elementIndex),
      {
        ...state.elements[elementIndex],
        checked
      },
      ...state.elements.slice(elementIndex + 1)
    ]

    return {
      ...state,
      elements: newElements,
      allElementsActive: newElements.some((element) => element.checked)
    }
  }
  case types.CLEAR_UPDATE_PROVIDER_SECTION:
    return {
      ...state,
      updateProviderSection: {
        loading: false,
        success: false,
        error: false
      },
      saveProviderSection: {
        loading: false,
        success: false,
        error: false
      },
      updateAttestationSection: {
        loading: false,
        success: false,
        error: false
      },
      updatePrescriptionSection: {
        loading: false,
        success: false,
        error: false
      },
      editAttestationSection: {
        loading: false,
        success: false,
        error: false
      },
      editPrescriptionSection: {
        loading: false,
        success: false,
        error: false
      }
    }
  case types.CLEAR_UPDATE_PATIENT_SECTION: {
    return {
      ...state,
      updatePatientSection: {
        loading: false,
        success: false,
        error: false
      },
      editPatientSection: {
        loading: false,
        success: false,
        error: false
      }
    }
  }
  case types.UPDATE_OPTION_CAPTION: {
    const { optionId, value } = action.payload
      
    const optionIndex = state.attestation[0].options.findIndex((option) => option.optionId === parseInt(optionId, 10))
    const newAttestorElement = {
      ...state.attestation[0],
      options: [
        ...state.attestation[0].options.slice(0, optionIndex),
        {
          ...state.attestation[0].options[optionIndex],
          customCaption: value
        },
        ...state.attestation[0].options.slice(optionIndex + 1)
      ]
    }
    return {
      ...state,
      attestation: [
        newAttestorElement,
        ...state.attestation.filter((element) => element.elementId !== newAttestorElement.elementId)
      ]
    }
  }
  case types.UPDATE_ATTESTATION_ELEMENT_CAPTION: {
    const { value, id, key } = action.payload

    const elementIndex = state.attestation.findIndex((element) => element.elementId === id)

    return {
      ...state,
      attestation: [
        ...state.attestation.slice(0, elementIndex),
        {
          ...state.attestation[elementIndex],
          [key]: value
        },
        ...state.attestation.slice(elementIndex + 1)
      ]
    }
  }
  case types.UPDATE_DEMOGRAPHIC_CHECKBOX: {
    const { demographicId, checked } = action.payload
    const demographicIndex = state.demographics.findIndex((demographic) => demographic.patientDemographicId === demographicId)

    return {
      ...state,
      demographics: [
        ...state.demographics.slice(0, demographicIndex),
        {
          ...state.demographics[demographicIndex],
          checked
        },
        ...state.demographics.slice(demographicIndex + 1)
      ]
    }
  }
  case types.UPDATE_ALL_DEMOGRAPHIC_CHECKBOX: {
    return {
      ...state,
      demographics: state.demographics.map((demographic) => ({
        ...demographic,
        checked: demographic.patientDemographicId === 1 ? true : action.payload
      }))
    }
  }
  case types.CLEAR_CREATE_AND_UPDATE_PROGRAM: {
    return {
      ...state,
      createNewProgram: {
        loading: false,
        error: false,
        success: false
      },
      updateProgramSettings: {
        loading: false,
        error: false,
        success: false
      },
      getProgram: {
        loading: false,
        success: false,
        error: false
      }
    }
  }
  default:
    return state
  }
}

export default adminReducer