import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import PrimaryButton from '../../../components/PrimaryButton'
import { getPatientLandingPage, setStep } from '../../../features/patient/patientAction'

const PatientLanding = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { program, landingPageState, programState, landingPage } = useSelector((state) => ({
    program: state.user.program,
    landingPageState: state.patient.getPatientLandingPage,
    programState: state.user.getProgram,
    landingPage: state.patient.landingPage
  }))

  const handleStartClick = () => {
    dispatch(setStep(1))
    navigate('/patient-program/authorization')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (programState.success) {
      dispatch(getPatientLandingPage(program.programId))
    }
  }, [programState.success])
      
  return (
    <div>
      {
        landingPageState.success && (
          <>
            <Row>
              {
                landingPage.columns.map((column, index) => (
                  <Col
                    key={`${program.name}_patient_${index}`}
                    md={12 / landingPage.numberOfColumns}
                  >
                    {
                      column.map((section) => (
                        <FormSection
                          key={`${program.name}_patient_${index}_section_${section.formSectionId}`}
                          section={section}
                          primaryColor={program.primaryColor}
                        />
                      ))
                    }
                  </Col>
                ))
              }
            </Row>
            <hr />
            <Row>
              <Col className='text-center py-2'>
                <PrimaryButton
                  color={program.programBanner ? program.primaryColor : '#3B6E35'}
                  secondcolor={program.programBanner ? program.secondaryColor : '#5FAD56'}
                  onClick={handleStartClick}
                >
                Start Enrollment
                </PrimaryButton>
              </Col>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default PatientLanding