import React, { useEffect, useState } from 'react'
import { Form, Modal, Row, Col, Button } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import chroma from 'chroma-js'
import PropTypes from 'prop-types'

const components = {
  DropdownIndicator: null
}

const createOption = (label) => ({
  label,
  value: label
})

const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = chroma('#c72127')
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? '#c72127'
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : '#c72127',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? '#c72127'
            : color.alpha(0.3).css()
          : undefined,
      },
    }
  },
  multiValue: (styles) => {
    const color = chroma('#c72127')
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#c72127',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#c72127',
    ':hover': {
      backgroundColor: '#c72127',
      color: 'white',
    },
  }),
}

const OptionsModal = ({ show, elementId, elements, onClose, onSubmit }) => {
  const [element, setElement] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [includeOther, setIncludeOther] = useState(false)

  useEffect(() => {
    if (elementId) {
      const el = elements.find((el) => el.elementId === elementId)
      setElement(el)
      setValue(el.options.reduce((acc, curr) => {
        if (curr.value !== 'other') {
          return [...acc, { label: curr.label, value: curr.value }]
        }
        return acc
      }, []))

      setIncludeOther(el.options.some((o) => o.value === 'other'))
    }
  }, [elementId])

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
    case 'Enter':
    case 'Tab':
      setValue((prev) => [...prev, createOption(inputValue)])
      setInputValue('')
      event.preventDefault()
    }
  }

  const handleChange = () => {
    setIncludeOther((ps) => !ps)
  }

  const handleSubmit = () => {
    onSubmit(value, includeOther)
  }

  return (
    <Modal 
      show={show}
      onHide={onClose}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>{element && element.caption}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <CreatableSelect
              components={components}
              isClearable
              isMulti
              menuIsOpen={false}
              placeholder="Type something and press enter..."
              inputValue={inputValue}
              value={value}
              isDisabled={elementId === 1}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              styles={colorStyles}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <Form.Check
              label='Include Option for Other'
              checked={includeOther}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' size='sm' onClick={onClose}>
          Close
        </Button>
        <Button variant='success' size='sm' onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

OptionsModal.propTypes = {
  show: PropTypes.boolean,
  elementId: PropTypes.number,
  elements: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default OptionsModal