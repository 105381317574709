import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../../components/PrimaryButton'
import StyledH3 from '../../../components/StyledH3'
import { addLibraryField, addUserDefinedField, getSection, removeLibraryField, removeUserDefinedField, updateAllElementsChecked, updateElementCaption, updateElementChecked, updateElementFieldType, updateElementOptions, updatePatientSection, updateSectionCaption, updateSectionSubCaption, updateUserDefinedCaption } from '../../../features/admin/adminAction'
import draftToHtml from 'draftjs-to-html'
import { BsTrash, BsPlus } from 'react-icons/bs'
import OptionsModal from '../../../components/OptionsModal'
import { useFormik } from 'formik'
import { UserDefinedFieldSchema } from '../../../utils/validation'

const PatientSection = () => {
  const [show, setShow] = useState(false)
  const [elementId, setElementId] = useState(0)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [patientOptions, setPatientOptions] = useState([])

  const { sectionId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { program, getSectionState, section, updatePatientSectionState, elements, library, userDefined, allElementsActive, allElementsDisabled } = useSelector((state) => ({
    program: state.admin.program,
    getSectionState: state.admin.getSection,
    section: state.admin.section,
    updatePatientSectionState: state.admin.updatePatientSection,
    elements: state.admin.elements,
    library: state.admin.library,
    userDefined: state.admin.userDefined,
    allElementsActive: state.admin.allElementsActive,
    allElementsDisabled: state.admin.allElementsDisabled
  }))

  const userDefinedForm = useFormik({
    enableReinitialize: false,
    initialValues: {
      field: ''
    },
    validationSchema: UserDefinedFieldSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(addUserDefinedField(values))
      resetForm()
    }
  })

  useEffect(() => {
    dispatch(getSection({ sectionId, programId: program.programId }))
  }, [])

  const handleSaveSection = () => {
    let data = {
      programId: program.programId,
      section
    }
    
    switch (section.sectionId) {
    case 13:
      data.content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      break
    case 14:
    case 18:
      data.content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      data.patientOptions = patientOptions
      data.elements = elements
      break
    case 15:
    case 16:
    case 17:
      data.elements = elements
      data.userDefined = userDefined
      break
    default:
      break
    }
    dispatch(updatePatientSection(data))
  }

  useEffect(() => {
    if (updatePatientSectionState.success) {
      navigate(-1)
    }
  }, [updatePatientSectionState.success])

  useEffect(() => {
    if (section.sectionId === 14) {
      const patientElement = elements.find((element) => element.elementId === 89)

      if (patientElement) {
        setPatientOptions(
          patientElement.options.map((option) => ({
            ...option,
            customLabel: ''
          }))
        )
      }
    }

    if (section.sectionId === 18) {
      const patientElement = elements.find((element) => element.elementId === 171)

      if (patientElement) {
        setPatientOptions(
          patientElement.options.map((option) => ({
            ...option,
            customLabel: ''
          }))
        )
      }
    }
  }, [section.sectionId])

  const handleChangeSectionCaption = (event) => {
    dispatch(updateSectionCaption(event.target.value))
  }

  const handleChangeSectionSubCaption = (event) => {
    dispatch(updateSectionSubCaption(event.target.value))
  }
  
  const handleChangePatientOptionCaption = (event) => {
    const { name, value } = event.target

    const optionIndex = patientOptions.findIndex((option) => option.listValueId === parseInt(name, 10))

    setPatientOptions((ps) => [
      ...ps.slice(0, optionIndex),
      {
        ...ps[optionIndex],
        customLabel: value
      },
      ...ps.slice(optionIndex + 1)
    ])
  }

  const handleChangeElementCaption = (value, id) => {
    dispatch(updateElementCaption({ value, id }))
  }

  const handleUpdateElementFieldType = (value, id) => {
    dispatch(updateElementFieldType({ value, id }))
  }

  const renderFieldTypeSelector = (element) => {
    if ([3, 4, 5, 6].includes(element.elementTypeId) && element.elementId > 0) {
      return (
        <Form.Control
          as='select'
          size='sm'
          className='me-2'
          value={element.elementTypeId}
          onChange={(event) => handleUpdateElementFieldType(parseInt(event.target.value, 10), element.elementId)}
        >
          <option value={3}>Dropdown</option>
          <option value={4}>Radio Group</option>
          <option value={5}>Single Choice - Checkbox</option>
          <option value={6}>Multi Choice - Checkbox</option>
        </Form.Control>
      )
    }
    return element.elementType
  }

  const handleUpdateElementChecked = (elementId, checked) => {
    dispatch(updateElementChecked({ elementId, checked }))
  }

  const handleRemoveField = (elementId) => {
    dispatch(removeLibraryField(elementId))
  }

  const handleChangeUserDefinedCaption = (value, id) => {
    dispatch(updateUserDefinedCaption({ value, id }))
  }

  const handleRemoveUserDefinedField = (id) => {
    dispatch(removeUserDefinedField(id))
  }

  const handleSelectField = (event) => {
    dispatch(addLibraryField(event.target.value))
  }

  const handleCloseOptionsModal = () => {
    setShow(false)
    setElementId(0)
  }

  const handleUpdateOptions = (options, includeOther) => {
    dispatch(updateElementOptions({ options, includeOther, elementId }))
    setShow(false)
    setElementId(0)
  }

  const handleOpenOptionsModal = (id) => {
    setShow(true)
    setElementId(id)
  }

  const handleUpdateAllChecked = () => {
    dispatch(updateAllElementsChecked({ checked: !allElementsActive }))
  }

  const loadTableBody = () => {
    switch (section.sectionId) {
    case 13:
      return (
        <tr>
          <td />
          <td colSpan={4}>
            <Editor
              editorState={editorState}
              onEditorStateChange={(editorState) => setEditorState(editorState)}
              wrapperClassName='border rounded'
              name='introductory-page'
              editorClassName='p-1'
              toolbar={{ 
                options: ['inline', 'link', 'blockType', 'history', 'list'],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline']
                },
                link: {
                  inDropdown: false,
                  options: ['link']
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                }
              }}
              editorStyle={{
                height: '24rem'
              }}
              wrapperStyle={{
                width: '80rem'
              }}
            />
          </td>
        </tr>
      )
    case 14:
    case 18:
      return (
        <>
          <tr>
            <td />
            <td>Section Header</td>
            <td>{section.caption}</td>
            <td>
              <Form.Control
                size='sm'
                value={section.customCaption}
                onChange={handleChangeSectionCaption}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td />
            <td>Section Sub Header</td>
            <td>
              {section.subCaption}
            </td>
            <td>
              <Form.Control
                size='sm'
                value={section.customSubCaption}
                onChange={handleChangeSectionSubCaption}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td />
            <td colSpan={4}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(editorState) => setEditorState(editorState)}
                wrapperClassName='border rounded'
                name='introductory-page'
                editorClassName='p-1'
                toolbar={{ 
                  options: ['inline', 'link', 'blockType', 'history', 'list'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline']
                  },
                  link: {
                    inDropdown: false,
                    options: ['link']
                  },
                  blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                  }
                }}
                editorStyle={{
                  height: '24rem'
                }}
                wrapperStyle={{
                  width: '80rem'
                }}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>Patient/Legal Representative</td>
            <td colSpan={2}>
              {
                patientOptions.map((option) => (
                  <Row key={`patient-option-${option.listValueId}`}>
                    <Col md={8}>
                      <div dangerouslySetInnerHTML={{ __html: option.label }} className='mb-2' />
                    </Col>
                    <Col>
                      <Form.Control
                        key={`patient-option-text-${option.listValueId}`}
                        size='sm'
                        className='mt-1'
                        name={option.listValueId}
                        value={option.customLabel}
                        onChange={handleChangePatientOptionCaption}
                      />
                    </Col>
                  </Row>
                ))
              }
            </td>
            <td />
          </tr>
          <tr>
            <td />
            <td>Representative Relationship</td>
            <td>
              {
                section.sectionId === 14 ? elements.find((element) => element.elementId === 90).caption : elements.find((element) => element.elementId === 172).caption
              }
            </td>
            <td>
              {
                section.sectionId === 14 && (
                  <Form.Control
                    size='sm'
                    value={elements.find((element) => element.elementId === 90).customCaption}
                    onChange={(event) => handleChangeElementCaption(event.target.value, 90)}
                  />
                )
              }
              {
                section.sectionId === 18 && (
                  <Form.Control
                    size='sm'
                    value={elements.find((element) => element.elementId === 172).customCaption}
                    onChange={(event) => handleChangeElementCaption(event.target.value, 172)}
                  />
                )
              }
            </td>
            <td />
          </tr>
        </>
      )
    case 15:
    case 16:
    case 17:
      return (
        <>
          {
            elements.map((element) => (
              <>
                <tr key={`element-${element.elementId}`}>
                  {
                    element.configurable === 1 && element.default === 1 ? (
                      <td className='pt-0'>
                        <div className="form-checkbox form-checkbox-primary">
                          <input 
                            id={element.elementId} 
                            type="checkbox" 
                            checked={element.checked}
                            onChange={() => handleUpdateElementChecked(element.elementId, !element.checked)}
                          />
                          <label htmlFor={element.elementId}>

                          </label>
                        </div>
                      </td>
                    ) : element.default === 0 ? (
                      <td>
                        <Button
                          size='sm'
                          variant='outline-danger'
                          onClick={() => handleRemoveField(element.elementId)}
                        >
                          <BsTrash />
                        </Button>
                      </td>
                    ) : <td></td>
                  }
                  <td>
                    <Row>
                      <Col>
                        {renderFieldTypeSelector(element)}
                      </Col>
                      <Col>
                        {
                          [3, 4, 5, 6].includes(element.elementTypeId) && element.elementId > 0 && (
                            <Button
                              size='sm'
                              variant='link'
                              onClick={() => handleOpenOptionsModal(element.elementId)}
                            >
                              Edit
                            </Button>
                          )
                        }
                      </Col>
                    </Row>
                  </td>
                  <td>
                    {element.caption}
                  </td>
                  <td>
                    {
                      element.elementTypeId !== 13 && (
                        <Form.Control
                          size='sm'
                          value={element.customCaption}
                          onChange={(event) => handleChangeElementCaption(event.target.value, element.elementId)}
                        />
                      )
                    }
                  </td>
                  <td>
                    {element.autoFilled === 1 && 'Yes'}
                  </td>
                </tr>
              </>
            ))
          }
          <tr>
            <td colSpan={2}></td>
            <td>
              <Form.Control
                as='select'
                size='sm'
                aria-label='Select Field From Library'
                onChange={handleSelectField}
              >
                <option hidden>Select Field From Library</option>
                {
                  library.map((l) => (
                    <option key={l.caption} value={l.elementId}>{l.caption}</option>
                  ))
                }
              </Form.Control>
            </td>
            <td colSpan={2}></td>
          </tr>
          {
            userDefined.map((ud) => (
              <tr key={ud.userDefinedId}>
                <td>
                  <Button
                    size='sm'
                    variant='outline-danger'
                    onClick={() => handleRemoveUserDefinedField(ud.userDefinedId)}
                  >
                    <BsTrash />
                  </Button>
                </td>
                <td>
                  {ud.type}
                </td>
                <td>
                  {ud.caption}
                </td>
                <td>
                  <Form.Control
                    size='sm'
                    value={ud.customCaption}
                    onChange={(event) => handleChangeUserDefinedCaption(event.target.value, ud.userDefinedId)}
                  />
                </td>
                <td></td>
              </tr>
            ))
          }
          <tr>
            <td></td>
            <td></td>
            <td>
              <Form noValidate onSubmit={userDefinedForm.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} xs={8} controlId='userDefinedField'>
                    <Form.Label>User Defined</Form.Label>
                    <Form.Control
                      as='select'
                      size='sm'
                      aria-label='Select User Defined Field'
                      name='field'
                      value={userDefinedForm.values.field}
                      onChange={userDefinedForm.handleChange}
                      onBlur={userDefinedForm.handleBlur}
                      isInvalid={!!userDefinedForm.errors.field && userDefinedForm.touched.field}
                    >
                      <option hidden>Select User Defined Field Type</option>
                      <option value='Text'>Text</option>
                      <option value='Yes/No Radio Group'>Yes/No Radio Group</option>
                      <option value='Yes/No/NA Radio Group'>Yes/No/NA Radio Group</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {userDefinedForm.errors.field}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Col xs={4}>
                    <Button variant='outline-success' size='sm' type='submit' style={{ marginTop: 32 }}>
                      <BsPlus className='mr-2' />
                        Add
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </td>
            <td></td>
            <td></td>
          </tr>
        </>
      )
    default:
      break
    }
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <StyledH3
            color='#070f0d'
            onClick={() => navigate(-1)}
            style={{
              cursor: 'pointer'
            }}
          >
            <u>Forms</u>
          </StyledH3>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getSectionState.loading && (
          <div>
            Fetching Section...
          </div>
        )
      }
      {
        getSectionState.success && (
          <>
            <Row>
              <Col md={10} xs={9}>
                <StyledH3>
                  Patient Form: {section.caption}
                </StyledH3>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleSaveSection}
                  disabled={updatePatientSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  responsive
                >
                  <thead>
                    <tr>
                      <th>
                        <div className="form-checkbox form-checkbox-secondary pb-2">
                          <input 
                            id="all" 
                            type="checkbox" 
                            checked={allElementsActive} 
                            disabled={allElementsDisabled}
                            onChange={handleUpdateAllChecked}
                          />
                          <label htmlFor="all">

                          </label>
                        </div>
                      </th>
                      <th>
                      Field Type
                      </th>
                      <th style={{ width: '36rem' }}>
                      Default Caption
                      </th>
                      <th style={{ width: '20rem' }}>
                      Custom Caption
                      </th>
                      <th>
                      Auto-Fill
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadTableBody()}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Button
                  size='sm'
                  variant='outline-danger'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <PrimaryButton
                  size='sm'
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  onClick={handleSaveSection}
                  disabled={updatePatientSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
            <OptionsModal
              show={show}
              elementId={elementId}
              elements={elements}
              onClose={handleCloseOptionsModal}
              onSubmit={handleUpdateOptions}
            />
          </>
        )
      }
    </div>
  )
}

export default PatientSection