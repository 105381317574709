import { combineReducers } from 'redux'
import admin from './admin/adminReducer'
import auth from './auth/authReducer'
import patient from './patient/patientReducer'
import provider from './provider/providerReducer'
import user from './user/userReducer'

const rootReducer = combineReducers({
  admin,
  auth,
  patient,
  provider,
  user
})

export default rootReducer