import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Launch from '../pages/epic/Launch'
import PatientLayout from '../layouts/PatientLayout'
import Patient from '../pages/epic/Patient'
import ProviderLayout from '../layouts/ProviderLayout'
import Provider from '../pages/epic/Provider'
import ProgramLayout from '../layouts/ProgramLayout'
import Program from '../pages/epic/Program'
import NotFound from '../components/NotFound'
import MainLayout from '../layouts/MainLayout'
import Forms from '../pages/admin/Forms'
import Form from '../pages/admin/Form'
import AddSection from '../pages/admin/AddSection'
import AddAttestation from '../pages/admin/AddAttestation'
import AddPrescription from '../pages/admin/AddPrescription'
import ProviderForm from '../pages/admin/ProviderForm'
import PatientSection from '../pages/admin/PatientSection'
import Introduction from '../pages/admin/PatientForm/Introduction'
import AuthorizationPreview from '../pages/admin/PatientForm/Authorization'
import ContactPreview from '../pages/admin/PatientForm/Contact'
import InsurancePreview from '../pages/admin/PatientForm/Insurance'
import AssistancePreview from '../pages/admin/PatientForm/Assistance'
import CertificationPreview from '../pages/admin/PatientForm/Certification'
import EditSection from '../pages/admin/EditSection'
import EditAttestation from '../pages/admin/EditAttestation'
import EditPatientSection from '../pages/admin/EditPatientSection'
import EditPrescription from '../pages/admin/EditPrescription'
import Login from '../pages/admin/Login'
import ProviderExit from '../pages/epic/ProviderExit'
import PatientProgramLayout from '../layouts/PatientProgramLayout'
import PatientLanding from '../pages/epic/PatientLanding'
import Authorization from '../pages/epic/Authorization'
import Contact from '../pages/epic/Contact'
import Insurance from '../pages/epic/Insurance'
import Assistance from '../pages/epic/Assistance'
import Certification from '../pages/epic/Certification'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route index element={<Forms />} />
          <Route path='/login' element={<Login />} />
          <Route path='/launch' element={<Launch />} />
          <Route path='/consent' element={<Launch />} />
          <Route path='/form' element={<Form />} />
          <Route path='/form/:programId' element={<Form />} />
          <Route path='/form/add/section/:sectionId' element={<AddSection />} />
          <Route path='/form/edit/section/:formSectionId' element={<EditSection />} />
          <Route path='/form/add/attestation/:sectionId' element={<AddAttestation />} />
          <Route path='/form/edit/attestation/:formSectionId' element={<EditAttestation />} />
          <Route path='/form/add/prescription/:sectionId' element={<AddPrescription />} />
          <Route path='/form/edit/prescription/:formSectionId' element={<EditPrescription />} />
          <Route path='/form/add/patient/:sectionId' element={<PatientSection />} />
          <Route path='/form/edit/patient/:formSectionId' element={<EditPatientSection />} />
          <Route path='/form/patient/intro/:programId' element={<Introduction />} />
          <Route path='/form/patient/authorization/:programId' element={<AuthorizationPreview />} />
          <Route path='/form/patient/contact/:programId' element={<ContactPreview />} />
          <Route path='/form/patient/insurance/:programId' element={<InsurancePreview />} />
          <Route path='/form/patient/assistance/:programId' element={<AssistancePreview />} />
          <Route path='/form/patient/certification/:programId' element={<CertificationPreview />} />
          <Route path='/form/provider/:programId' element={<ProviderForm />} />
        </Route>
        <Route path='patient' element={<PatientLayout />}>
          <Route index element={<Patient />} />
        </Route>
        <Route path='provider' element={<ProviderLayout />}>
          <Route index element={<Provider />} />
          <Route path='exit' element={<ProviderExit />} />
        </Route>
        <Route path='program' element={<ProgramLayout />}>
          <Route path=':id' element={<Program />} />
        </Route>
        <Route path='patient-program' element={<PatientProgramLayout />}>
          <Route path=':programId' element={<PatientLanding />} />
          <Route path='authorization' element={<Authorization />} />
          <Route path='contact' element={<Contact />} />
          <Route path='insurance' element={<Insurance />} />
          <Route path='assistance' element={<Assistance />} />
          <Route path='certification' element={<Certification />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes