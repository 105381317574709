import types from '../types'

const initialState = {
  isFetching: false,
  iss: null,
  launch: null,
  authorizeUrl: null,
  tokenUrl: null,
  tokenUri: null,
  userFriendlyStatus: 'Waiting...',
  redirectToTokenUri: false,
  error: null,
  accessTokenRecieved: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_FHIR_META_DATA_REQUESTED: {
    return {
      ...state,
      isFetching: true,
      accessToken: null,
      userFriendlyStatus: 'Fetching metadata...'
    }
  }
  case types.GET_FHIR_META_DATA_FULFILLED: {
    const { authUrl, tokenUrl } = action.payload
    return {
      ...state,
      isFetching: false,
      authorizeUrl: authUrl,
      tokenUrl,
      redirectToTokenUri: true,
      userFriendlyStatus: 'Redirecting',
      accessToken: null,
      error: null
    }
  }
  case types.GET_FHIR_META_DATA_REJECTED: {
    return {
      ...state,
      isFetching: false,
      error: 'Unable to get fhir metadata'
    }
  }
  case types.GET_ACCESS_TOKEN_REQUESTED: {
    return {
      ...state,
      isFetching: true,
      userFriendlyStatus: 'Fetching access token...'
    }
  }
  case types.GET_ACCESS_TOKEN_FULFILLED: {
    if (action.payload.status !== 'Success') {
      return {
        ...state,
        isFetching: false,
        error: {
          code: 'FHIR_AT_1',
          description: 'Unable to authenticate user.'
        }
      }
    } else {

      return {
        isFetching: false,
        userFriendlyStatus: 'Access token received...',
        error: null,
        accessTokenRecieved: true
      }
    }
  }
  case types.GET_ACCESS_TOKEN_REJECTED: {
    return {
      ...state,
      isFetching: false,
      error: {
        code: 'FHIR_AT_1',
        description: action.error.message
      }
    }
  }
  case types.INITIALIZE_SMART: {
    const { iss, launch } = action.payload
    return {
      ...state,
      iss,
      launch,
      userFriendlyStatus: 'Initializing...'
    }
  }
  case types.FHIR_GENERATE_TOKEN_URI_REQUESTED: {
    return {
      ...state,
      isFetching: true,
      tokenUri: null
    }
  }
  case types.FHIR_GENERATE_TOKEN_URI_FULFILLED: {
    return {
      ...state,
      isFetching: false,
      tokenUri: action.payload
    }
  }
  case types.FHIR_GENERATE_TOKEN_URI_REJECTED: {
    return {
      ...state,
      isFetching: false,
      error: action.error
    }
  }
  default:
    return state
  }
}

export default authReducer