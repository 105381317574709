import types from '../types'
import patientService from './patientService'

export const getPatientLandingPage = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_PATIENT_LANDING_PAGE_REQUESTED })
  try {
    const page = await patientService.getForm(programId, 2)
    dispatch({ type: types.GET_PATIENT_LANDING_PAGE_FULFILLED, payload: page })
  } catch (error) {
    dispatch({ type: types.GET_PATIENT_LANDING_PAGE_REJECTED, payload: error })
  }
}

export const getAuthorizationForm = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_AUTHORIZATION_FORM_REQUESTED })
  try {
    const form = await patientService.getForm(programId, 3)
    dispatch({ type: types.GET_AUTHORIZATION_FORM_FULFILLED, payload: form })
  } catch (error) {
    dispatch({ type: types.GET_AUTHORIZATION_FORM_REJECTED, payload: error })
  }
}

export const authorize = (values) => async (dispatch) => {
  dispatch({ type: types.AUTHORIZE_REQUESTED })
  try {
    const authorized = await patientService.submitForm('authorize', values)
    dispatch({ type: types.AUTHORIZE_FULFILLED, payload: { values, authorized: authorized.data }})
  } catch (error) {
    dispatch({ type: types.AUTHORIZE_REJECTED, payload: error })
  }
}

export const getContactForm = ({ programId, fhirPatient }) => async (dispatch) => {
  dispatch({ type: types.GET_CONTACT_FORM_REQUESTED })
  try {
    const form = await patientService.getForm(programId, 4)
    dispatch({ type: types.GET_CONTACT_FORM_FULFILLED, payload: { form, fhirPatient }})
  } catch (error) {
    console.log(error)
    dispatch({ type: types.GET_CONTACT_FORM_REJECTED, payload: error })
  }
}

export const addContact = (values) => async (dispatch) => {
  dispatch({ type: types.ADD_CONTACT_REQUESTED })
  try {
    const contact = await patientService.submitForm('contact', values)
    dispatch({ type: types.ADD_CONTACT_FULFILLED, payload: { values, contact: contact.data }})
  } catch (error) {
    console.log(error)
    dispatch({ type: types.ADD_CONTACT_REJECTED, payload: error })
  }
}

export const getInsuranceForm = ({ programId, coverages }) => async (dispatch) => {
  dispatch({ type: types.GET_INSURANCE_FORM_REQUESTED })
  try {
    const form = await patientService.getForm(programId, 5)
    dispatch({ type: types.GET_INSURANCE_FORM_FULFILLED, payload: { form, coverages }})
  } catch (error) {
    dispatch({ type: types.GET_INSURANCE_FORM_REJECTED, payload: error })
  }
}

export const addInsurance = (values) => async (dispatch) => {
  dispatch({ type: types.ADD_INSURANCE_REQUESTED })
  try {
    const insurance = await patientService.submitForm('insurance', values)
    dispatch({ type: types.ADD_INSURANCE_FULFILLED, payload: { values, insurance: insurance.data }})
  } catch (error) {
    dispatch({ type: types.ADD_INSURANCE_REJECTED, payload: error })
  }
}

export const getAssistanceForm = ({ programId, patient }) => async (dispatch) => {
  dispatch({ type: types.GET_ASSISTANCE_FORM_REQUESTED })
  try {
    const form = await patientService.getForm(programId, 6)
    dispatch({ type: types.GET_ASSISTANCE_FORM_FULFILLED, payload: { form, patient }})
  } catch (error) {
    dispatch({ type: types.GET_ASSISTANCE_FORM_REJECTED, payload: error })
  }
}

export const addAssistance = (values) => async (dispatch) => {
  dispatch({ type: types.ADD_ASSISTANCE_REQUESTED })
  try {
    const assistance = await patientService.submitForm('assistance', values)
    dispatch({ type: types.ADD_ASSISTANCE_FUFILLED, payload: { values, assistance: assistance.data }})
  } catch (error) {
    dispatch({ type: types.ADD_ASSISTANCE_REJECTED, payload: error })
  }
}

export const getCertificationForm = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_CERTIFICATION_FORM_REQUESTED })
  try {
    const form = await patientService.getForm(programId, 7)
    dispatch({ type: types.GET_CERTIFICATION_FORM_FULFILLED, payload: form })
  } catch (error) {
    dispatch({ type: types.GET_CERTIFICATION_FORM_REJECTED, payload: error })
  }
}

export const consent = (values) => async (dispatch) => {
  dispatch({ type: types.CONSENT_REQUESTED })
  try {
    const consentSent = await patientService.submitForm('consent', values)
    dispatch({ type: types.CONSENT_FULFILLED, payload: { values, consent: consentSent.data }})
  } catch (error) {
    dispatch({ type: types.CONSENT_REJECTED, payload: error })
  }
}

export const setStep = (payload) => (dispatch) => dispatch({ type: types.SET_STEP, payload })

export const updateAuthorizationValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_AUTHORIZATION_VALUE, payload })

export const updateContactValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_CONTACT_VALUE, payload })

export const updateInsuranceValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_INSURANCE_VALUE, payload })

export const updateAssistanceValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_ASSISTANCE_VALUE, payload })

export const updateCertificationValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_CERTIFICATION_VALUE, payload })