/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { initializeSmart, generateTokenUri } from '../../../features/auth/authAction'
import Spinner from 'react-bootstrap/Spinner'
import { useLocation } from 'react-router-dom'

const Launch = () => {
  const dispatch = useDispatch()
  const fhirAuth = useSelector((state) => state.auth)
  const location = useLocation()

  useEffect(() => {
    const querystring = qs.parse(window.location.search.slice(1))
    if (typeof querystring.iss === 'undefined') {
      alert('Issuer not specified. Aborting.')
      window.location = '/'
      return
    }

    if (typeof querystring.launch === 'undefined') {
      alert('Launch code not specified. Aborting.')
      window.location = '/'
      return
    }
    dispatch(initializeSmart({ iss: querystring.iss, launch: querystring.launch }))
  }, [])

  useEffect(() => {
    if (fhirAuth.redirectToTokenUri) {
      dispatch(generateTokenUri({
        iss: fhirAuth.iss,
        launch: fhirAuth.launch,
        authorizeUrl: fhirAuth.authorizeUrl,
        tokenUrl: fhirAuth.tokenUrl,
        actor: location.pathname.includes('consent') ? 'patient' : 'provider'
      }))
    }
  }, [fhirAuth.redirectToTokenUri])

  useEffect(() => {
    if (fhirAuth.tokenUri) {
      window.location = fhirAuth.tokenUri
    }
  }, [fhirAuth.tokenUri])

  if (fhirAuth.error !== null) {
    return <div>Error: {fhirAuth.error}</div>
  }

  return (
    <div className='text-center'>
      <Spinner animation='border' role='status' />
      {fhirAuth.userFriendlyStatus}
    </div>
  )
}

export default Launch