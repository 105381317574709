import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'

const FormTextbox = ({ name, size, label, labelOrientation, placeholder, required, formValue, updateValue, rows }) => {
  if (rows) {
    return (
      <Form.Group id={name}>
        {label && (<Form.Label>{label}</Form.Label>)} 
        <Form.Control
          name={name}
          required={required}
          as='textarea'
          placeholder={placeholder || ''}
          size={size || 'sm'}
          value={formValue}
          onChange={(event) => updateValue(name, event.target.value)}
          className='mb-2'
          rows={rows}
        />
      </Form.Group>
    )
  }


  switch (labelOrientation) {
  case 'left':
    return (
      <Form.Group as={Row} id={name} className='mt-3'>
        <Col sm={8}>
          <Form.Label dangerouslySetInnerHTML={{ __html: label}} />
        </Col>
        <Col sm={4}>
          <Form.Control 
            name={name}
            required={required}
            placeholder={placeholder || ''}
            size={size || 'sm'}
            value={formValue}
            onChange={(event) => updateValue(name, event.target.value)}
          />
        </Col>
      </Form.Group>
    )
  default:
    return (
      <Form.Group id={name}>
        {label && (<Form.Label>{label}</Form.Label>)} 
        <Form.Control
          name={name}
          required={required}
          placeholder={placeholder || ''}
          size={size || 'sm'}
          value={formValue}
          onChange={(event) => updateValue(name, event.target.value)}
          className='mb-2'
        />
      </Form.Group>
    )
  }
}

FormTextbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelOrientation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.number,
  formValue: PropTypes.string,
  updateValue: PropTypes.func
}

export default FormTextbox