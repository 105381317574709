import React, { useEffect } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../../components/PrimaryButton'
import StyledH3 from '../../../components/StyledH3'
import { editAttestationSection, getAttestationFormSection, updateAttestationElementCaption, updateOptionCaption, updateSectionCaption } from '../../../features/admin/adminAction'

const EditAttestation = () => {
  const { formSectionId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { program, getAttestationFormSectionState, section, attestation, editAttestationSectionState } = useSelector((state) => ({
    program: state.admin.program,
    getAttestationFormSectionState: state.admin.getAttestationFormSection,
    section: state.admin.section,
    attestation: state.admin.attestation,
    editAttestationSectionState: state.admin.editAttestationSection
  }))

  useEffect(() => {
    dispatch(getAttestationFormSection({ formSectionId, programId: program.programId }))
  }, [])

  const handleChangeSectionCaption = (event) => {
    dispatch(updateSectionCaption(event.target.value))
  }

  const handleChangeOptionCaption = (optionId, value) => {
    dispatch(updateOptionCaption({ optionId, value }))
  }

  const handleChangeElementCaption = (value, id, key) => {
    dispatch(updateAttestationElementCaption({ value, id, key }))
  }

  const handleEditProviderAttestation = () => {
    dispatch(editAttestationSection({ programId: program.programId, section, attestation }))
  }

  useEffect(() => {
    if (editAttestationSectionState.success) {
      navigate(-1)
    }
  }, [editAttestationSectionState.success])
  
  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <Link to='/'>
            <u>
              <StyledH3 color='#070f0d'>
                Forms
              </StyledH3>
            </u>
          </Link>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getAttestationFormSectionState.loading && (
          <div>
            Fetching Section...
          </div>
        )
      }
      {
        getAttestationFormSectionState.success && (
          <>
            <Row>
              <Col md={10} xs={9}>
                <StyledH3>
                    Clinical Form: {section.caption}
                </StyledH3>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleEditProviderAttestation}
                  disabled={editAttestationSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  responsive
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Field Type</th>
                      <th>Default Caption</th>
                      <th>Custom Caption</th>
                      <th>Auto-Fill</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>Section Header</td>
                      <td>{section.caption}</td>
                      <td>
                        <Form.Control
                          size='sm'
                          value={section.customCaption}
                          onChange={handleChangeSectionCaption}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td />
                      <td>{attestation[0].fieldType}</td>
                      <td>
                        <p>{attestation[0].options[0].caption}</p>
                        <p>{attestation[0].options[1].caption}</p>
                      </td>
                      <td>
                        <p>
                          <Form.Control
                            size='sm'
                            value={attestation[0].options[0].customCaption}
                            onChange={(event) => handleChangeOptionCaption(attestation[0].options[0].optionId, event.target.value)}
                          />
                        </p>
                        <p>
                          <Form.Control
                            size='sm'
                            value={attestation[0].options[1].customCaption}
                            onChange={(event) => handleChangeOptionCaption(attestation[0].options[1].optionId, event.target.value)}
                          />
                        </p>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td />
                      <td>Text</td>
                      <td>{attestation[1].caption}</td>
                      <td>
                        <Form.Control
                          size='sm'
                          value={attestation[1].customCaption}
                          onChange={(event) => handleChangeElementCaption(event.target.value, attestation[1].elementId, 'customCaption')}
                        />
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <td>{attestation[1].fieldType}</td>
                      <td>
                        <Form.Control
                          as='textarea'
                          rows={6}
                          size='sm'
                          value={attestation[1].value}
                          onChange={(event) => handleChangeElementCaption(event.target.value, attestation[1].elementId, 'value')}
                          placeholder={attestation[1].placeholder}
                        />
                      </td>
                      <td>N/A - Attestation Language</td>
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <td>{attestation[2].fieldType}</td>
                      <td>{attestation[2].caption}</td>
                      <td>
                        <Form.Control
                          size='sm'
                          value={attestation[2].customCaption}
                          onChange={(event) => handleChangeElementCaption(event.target.value, attestation[2].elementId, 'customCaption')}
                        />
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <td>{attestation[3].fieldType}</td>
                      <td>{attestation[3].caption}</td>
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant='outline-danger'
                  size='sm'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleEditProviderAttestation}
                  disabled={editAttestationSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default EditAttestation