import * as Yup from 'yup'

const digitsOnly = (value) => /^\d+$/.test(value)

export const ProgramSchema = Yup.object().shape({
  primaryDiagnosis: Yup.number().required('Please confirm the primary diagnosis.'),
  program: Yup.string(),
  pharmacy: Yup.string().when('program', {
    is: (val) => val === 'cablivi',
    then: (schema) => schema.required('This field is required.')
  }),
  deviceType: Yup.string().when('program', {
    is: (val) => val === 'dupixent',
    then: (schema) => schema.required('This field is required.')
  }),
  dupixentDosage: Yup.array().when('program', {
    is: (val) => val === 'dupixent',
    then: (schema) => schema.min(1, 'Please choose all dosing that apply.')
  }),
  cabliviDosage: Yup.object().when('program', {
    is: (val) => val === 'cablivi',
    then: (schema) => schema.shape({
      qtyType: Yup.string(), 
      qty: Yup.number().required('Please select dosing quantity.')
    })
  }),
  attester: Yup.string().when('program', {
    is: (val) => val === 'cablivi',
    then: (schema) => schema.required('This field is required.')
  }),
  agreedToAttestation: Yup.bool().oneOf([true], 'Please read and agree to the attestation.'),
  name: Yup.string().required('This field is required.')
})

export const AuthorizationSchema = Yup.object().shape({
  actor: Yup.string().required('Please read and agree to the Authorization for the Release and Use of Health Information.'),
  relationshipToPatient: Yup.string().when('actor', {
    is: (val) => val === 'representative',
    then: (schema) => schema.required('This field is required.')
  }),
  name: Yup.string().required('This field is required.')
})

export const ContactSchema = Yup.object().shape({
  preferredPhone: Yup.string().test('all-digits', 'Please include all digits of your preferred phone number.', (value) => {
    if (value === undefined) {
      return true
    }

    if (value && value.length === 13) {
      return true
    }

    return true
  }).required('This field is required.'),
  alternatePhone: Yup.string().test('empty-or-all-digits', 'Please include all digits of your alternate phone number.', (value) => {
    if (value === undefined) {
      return true
    }

    if (value && value.length === 13) {
      return true
    }

    return true
  }),
  email: Yup.string().email('Please enter a valid email address.'),
  language: Yup.string(),
  otherLanguage: Yup.string().when('langauge', {
    is: (val) => val === 'OTHER',
    then: (schema) => schema.required('This field is required.')
  }),
  streetAddress: Yup.string().required('This field is required.'),
  city: Yup.string().required('This field is required.'),
  state: Yup.string().required('This field is required.'),
  zip: Yup.string().required('This field is required.').test('Digits only', 'The field should have digits only', digitsOnly),
  caregiverName: Yup.string(),
  caregiverPhone: Yup.string().when('caregiverName', {
    is: (val) => val,
    then: (schema) => schema.required('This field is required.')
  }),
  actor: Yup.string().required('Please read and understand the SANOFI COMMUNICATIONS CONSENT.'),
  relationshipToPatient: Yup.string().when('actor', {
    is: (val) => val === 'representative',
    then: (schema) => schema.required('This field is required.')
  }),
  name: Yup.string().required('This field is required.')
})

const fields = ['primaryMedicalInsuranceName', 'primaryMedicalInsurancePhone', 'rxInsuranceName', 'rxPhone', 'rxPolicyId', 'rxBin', 'rxGroup', 'rxPcn']

export const InsuranceSchema = Yup.object().shape({
  program: Yup.string(),
  noInsurance: Yup.bool().test(
    'required',
    'This field is required. Please fill in insurance information below or choose No Insurance if applicable',
    (value, ctx) => {
      return value ? value : fields.some((field) => ctx.parent[field] !== undefined)
    }
  ),
  primaryMedicalInsuranceName: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined'),
  primaryMedicalInsurancePhone: Yup.string()
    .test(
      'required', 
      'This field is required.', 
      (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined')
    .test(
      'empty-or-all-digits', 
      'Please include all digits of the phone number.', 
      (value) => {
        if (value === undefined) {
          return true
        }

        if (value && value.length === 13) {
          return true
        }

        return true
      }),
  policyholderName: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined'), 
  policyholderEmployer: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined'), 
  relationshipToPatient: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined'), 
  primaryPolicyId: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined'), 
  primaryGroup: Yup.string().test(
    'required', 
    'This field is required.', 
    (value, ctx) => ctx.parent.noInsurance ? typeof value === 'undefined' : typeof value !== 'undefined')
})

export const AssistanceSchema = Yup.object().shape({
  apply: Yup.string(),
  household: Yup.string().test('Digits Only', 'The field should have digits only.', (value) => {
    if (value === undefined) {
      return true
    }

    if (value && digitsOnly(value)) {
      return true
    }

    return true
  }),
  income: Yup.string().test('Digits Only', 'The field should have digits only.', (value) => {
    if (value === undefined) {
      return true
    }

    if (value && digitsOnly(value)) {
      return true
    }

    return true
  }),
  ssn: Yup.string().test('Digits Only', 'The field should have digits only.', (value) => {
    if (value === undefined) {
      return true
    }

    if (value && digitsOnly(value)) {
      return true
    }

    return true
  })
})

export const CertificationSchema = Yup.object().shape({
  actor: Yup.string().required('Please read and agree to the Patient Certifications.'),
  relationshipToPatient: Yup.string().when('actor', {
    is: (val) => val === 'representative',
    then: (schema) => schema.required('This field is required.')
  }),
  name: Yup.string().required('This field is required.')
})

export const NewFormSchema = Yup.object().shape({
  drugName: Yup.string().required('This field is required.'),
  programName: Yup.string().required('This field is required.'),
  manufacturer: Yup.string().required('This field is required.'),
  programType: Yup.string().required('This field is required.'),
  diagnosisCode: Yup.string(),
  diagnosisDescription: Yup.string(),
  primaryDiagnoses: Yup.array(
    Yup.object().shape({
      code: Yup.string().nullable().required('This field is required.'),
      description: Yup.string().nullable().required('This field is required.')
    })
  ).min(1, 'Please add a Primary Diagnosis'),
  rxCode: Yup.string(),
  rxDescription: Yup.string(),
  rxNormCodes: Yup.array(
    Yup.object().shape({
      code: Yup.string().nullable().required('This field is required.'),
      description: Yup.string().nullable().required('This field is required.')
    })
  ).min(1, 'Please add a Drug Product.'),
  programContactName: Yup.string().required('This field is required.'),
  programContactPhone: Yup.string().required('This field is required.'),
  programContactEmail: Yup.string().email('Please specify a valid email address.').required('This field is required.')
})

export const UserDefinedFieldSchema = Yup.object().shape({
  field: Yup.string().required('This field is required.')
})