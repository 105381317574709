import keyMirror from 'keymirror'

export default keyMirror({
  //ADMIN
  GET_PROGRAM_TYPES: null,
  GET_MANUFACTURERS: null,
  GET_PROGRAMS_REQUESTED: null,
  GET_PROGRAMS_FULFILLED: null,
  GET_PROGRAMS_REJECTED: null,
  GET_PROGRAM_REQUESTED: null,
  GET_PROGRAM_FULFILLED: null,
  GET_PROGRAM_REJECTED: null,
  CREATE_NEW_PROGRAM_REQUESTED: null,
  CREATE_NEW_PROGRAM_FULFILLED: null,
  CREATE_NEW_PROGRAM_REJECTED: null,
  GET_SECTION_REQUESTED: null,
  GET_SECTION_FULFILLED: null,
  GET_SECTION_REJECTED: null,
  GET_FORM_SECTION_REQUESTED: null,
  GET_FORM_SECTION_FULFILLED: null,
  GET_FORM_SECTION_REJECTED: null,
  UPDATE_PROVIDER_SECTION_REQUESTED: null,
  UPDATE_PROVIDER_SECTION_FULFILLED: null,
  UPDATE_PROVIDER_SECTION_REJECTED: null,
  SAVE_PROVIDER_SECTION_REQUESTED: null,
  SAVE_PROVIDER_SECTION_FULFILLED: null,
  SAVE_PROVIDER_SECTION_REJECTED: null,
  GET_ATTESTATION_SECTION_REQUESTED: null,
  GET_ATTESTATION_SECTION_FULFILLED: null,
  GET_ATTESTATION_SECTION_REJECTED: null,
  GET_ATTESTATION_FORM_SECTION_REQUESTED: null,
  GET_ATTESTATION_FORM_SECTION_FULFILLED: null,
  GET_ATTESTATION_FORM_SECTION_REJECTED: null,
  UPDATE_ATTESTATION_SECTION_REQUESTED: null,
  UPDATE_ATTESTATION_SECTION_FULFILLED: null,
  UPDATE_ATTESTATION_SECTION_REJECTED: null,
  EDIT_ATTESTATION_SECTION_REQUESTED: null,
  EDIT_ATTESTATION_SECTION_FULFILLED: null,
  EDIT_ATTESTATION_SECTION_REJECTED: null,
  GET_DOSING_BASES: null,
  GET_DRUG_PRODUCTS: null,
  UPDATE_PRESCRIPTION_SECTION_REQUESTED: null,
  UPDATE_PRESCRIPTION_SECTION_FULFILLED: null,
  UPDATE_PRESCRIPTION_SECTION_REJECTED: null,
  GET_PATIENT_DEMOGRAPHICS: null,
  SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REQUESTED: null,
  SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_FULFILLED: null,
  SAVE_PROGRAM_PATIENT_DEMOGRAPHICS_REJECTED: null,
  UPDATE_PATIENT_SECTION_REQUESTED: null,
  UPDATE_PATIENT_SECTION_FULFILLED: null,
  UPDATE_PATIENT_SECTION_REJECTED: null,
  EDIT_PATIENT_SECTION_REQUESTED: null,
  EDIT_PATIENT_SECTION_FULFILLED: null,
  EDIT_PATIENT_SECTION_REJECTED: null,
  UPDATE_PROGRAM_REQUESTED: null,
  UPDATE_PROGRAM_FULFILLED: null,
  UPDATE_PROGRAM_REJECTED: null,
  UPDATE_PROGRAM_SETTINGS_REQUESTED: null,
  UPDATE_PROGRAM_SETTINGS_FULFILLED: null,
  UPDATE_PROGRAM_SETTINGS_REJECTED: null,
  RESET_CLINICAL_FORM_REQUESTED: null,
  RESET_CLINICAL_FORM_FULFILLED: null,
  RESET_CLINICAL_FORM_REJECTED: null,
  RESET_PATIENT_FORM_REQUESTED: null,
  RESET_PATIENT_FORM_FULFILLED: null,
  RESET_PATIENT_FORM_REJECTED: null,
  EDIT_PRESCRIPTION_SECTION_REQUESTED: null,
  EDIT_PRESCRIPTION_SECTION_FULFILLED: null,
  EDIT_PRESCRIPTION_SECTION_REJECTED: null,
  SORT_PROGRAMS: null,
  SEARCH_PROGRAMS: null,
  UPDATE_STEP: null,
  UPDATE_ACTION: null,
  ADD_LIBRARY_FIELD: null,
  REMOVE_LIBRARY_FIELD: null,
  ADD_USER_DEFINED_FIELD: null,
  REMOVE_USER_DEFINED_FIELD: null,
  UPDATE_ELEMENT_CAPTION: null,
  UPDATE_SECTION_CAPTION: null,
  UPDATE_SECTION_SUB_CAPTION: null,
  UPDATE_USER_DEFINED_CAPTION: null,
  UPDATE_ELEMENT_FIELD_TYPE: null,
  UPDATE_ELEMENT_OPTIONS: null,
  UPDATE_ALL_ELEMENTS_CHECKED: null,
  UPDATE_ELEMENT_CHECKED: null,
  CLEAR_UPDATE_PROVIDER_SECTION: null,
  CLEAR_UPDATE_PATIENT_SECTION: null,
  UPDATE_OPTION_CAPTION: null,
  UPDATE_ATTESTATION_ELEMENT_CAPTION: null,
  UPDATE_DEMOGRAPHIC_CHECKBOX: null,
  UPDATE_ALL_DEMOGRAPHIC_CHECKBOX: null,
  CLEAR_CREATE_AND_UPDATE_PROGRAM: null,
  //AUTH
  GET_FHIR_META_DATA_REQUESTED: null,
  GET_FHIR_META_DATA_FULFILLED: null,
  GET_FHIR_META_DATA_REJECTED: null,
  GET_ACCESS_TOKEN_REQUESTED: null,
  GET_ACCESS_TOKEN_FULFILLED: null,
  GET_ACCESS_TOKEN_REJECTED: null,
  FHIR_GENERATE_TOKEN_URI_REQUESTED: null,
  FHIR_GENERATE_TOKEN_URI_FULFILLED: null,
  FHIR_GENERATE_TOKEN_URI_REJECTED: null,
  INITIALIZE_SMART: null,
  //PATIENT
  GET_PATIENT_LANDING_PAGE_REQUESTED: null,
  GET_PATIENT_LANDING_PAGE_FULFILLED: null,
  GET_PATIENT_LANDING_PAGE_REJECTED: null,
  GET_AUTHORIZATION_FORM_REQUESTED: null,
  GET_AUTHORIZATION_FORM_FULFILLED: null,
  GET_AUTHORIZATION_FORM_REJECTED: null,
  AUTHORIZE_REQUESTED: null,
  AUTHORIZE_FULFILLED: null,
  AUTHORIZE_REJECTED: null,
  GET_CONTACT_FORM_REQUESTED: null,
  GET_CONTACT_FORM_FULFILLED: null,
  GET_CONTACT_FORM_REJECTED: null,
  ADD_CONTACT_REQUESTED: null,
  ADD_CONTACT_FULFILLED: null,
  ADD_CONTACT_REJECTED: null,
  GET_INSURANCE_FORM_REQUESTED: null,
  GET_INSURANCE_FORM_REJECTED: null,
  GET_INSURANCE_FORM_FULFILLED: null,
  ADD_INSURANCE_REQUESTED: null,
  ADD_INSURANCE_FULFILLED: null,
  ADD_INSURANCE_REJECTED: null,
  GET_ASSISTANCE_FORM_REQUESTED: null,
  GET_ASSISTANCE_FORM_FULFILLED: null,
  GET_ASSISTANCE_FORM_REJECTED: null,
  ADD_ASSISTANCE_REQUESTED: null,
  ADD_ASSISTANCE_FUFILLED: null,
  ADD_ASSISTANCE_REJECTED: null,
  GET_CERTIFICATION_FORM_REQUESTED: null,
  GET_CERTIFICATION_FORM_FULFILLED: null,
  GET_CERTIFICATION_FORM_REJECTED: null,
  CONSENT_REQUESTED: null,
  CONSENT_FULFILLED: null,
  CONSENT_REJECTED: null,
  SET_STEP: null,
  UPDATE_AUTHORIZATION_VALUE: null,
  UPDATE_CONTACT_VALUE: null,
  UPDATE_INSURANCE_VALUE: null,
  UPDATE_ASSISTANCE_VALUE: null,
  UPDATE_CERTIFICATION_VALUE: null,
  //PROVIDER
  GET_PROVIDER_FORM_REQUESTED: null,
  GET_PROVIDER_FORM_FULFILLED: null,
  GET_PROVIDER_FORM_REJECTED: null,
  SIGN_ENROLLMENT_REQUESTED: null,
  SIGN_ENROLLMENT_FULFILLED: null,
  SIGN_ENROLLMENT_REJECTED: null,
  SEND_TO_POOL_REQUESTED: null,
  SEND_TO_POOL_FULFILLED: null,
  SEND_TO_POOL_REJECTED: null,
  PRINT_PDF_REQUESTED: null,
  PRINT_PDF_FULFILLED: null,
  PRINT_PDF_REJECTED: null,
  INIT_FORM: null,
  UPDATE_FORM_VALUE: null,
  SEND_PATIENT_COPAY_OFFER_REQUESTED: null,
  SEND_PATIENT_COPAY_OFFER_FULFILLED: null,
  SEND_PATIENT_COPAY_OFFER_REJECTED: null,
  RESET_PROVIDER_STATE: null,
  //USER
  GET_HEALTH_SYSTEM_REQUESTED: null,
  GET_HEALTH_SYSTEM_FULFILLED: null,
  GET_HEALTH_SYSTEM_REJECTED: null,
  GET_PATIENT_MEDICATIONS_REQUESTED: null,
  GET_PATIENT_MEDICATION_FULFILLED: null,
  GET_PATIENT_MEDICATION_REJECTED: null,
  GET_SESSION_PROGRAM_REQUESTED: null,
  GET_SESSION_PROGRAM_FULFILLED: null,
  GET_SESSION_PROGRAM_REJECTED: null,
  GET_PATIENT_REQUESTED: null,
  GET_PATIENT_FULFILLED: null,
  GET_PATIENT_REJECTED: null,
  START_SESSION_REQUESTED: null,
  START_SESSION_FULFILLED: null,
  START_SESSION_REJECTED: null,
  GET_PATIENT_COVERAGES_REQUESTED: null,
  GET_PATIENT_COVERAGES_FULFILLED: null,
  GET_PATIENT_COVERAGES_REJECTED: null,
  UPDATE_PATIENT_SESSION: null,
  SET_PROGRAM: null,
  SET_SESSION: null,
  GET_CO_PAY_OFFER_REQUESTED: null,
  GET_CO_PAY_OFFER_FULFILLED: null,
  GET_CO_PAY_OFFER_REJECTED: null,
  START_ACTION_REQUESTED: null,
  START_ACTION_FULFILLED: null,
  START_ACTION_REJECTED: null,
  SET_CURRENT_ACTION: null,
  SET_CURRENT_ACTION_ID: null,
  CLEAR_PROGRAM: null
})