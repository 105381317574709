import types from '../types'
import providerService from './providerService'

export const getProviderForm = (programId) => async (dispatch) => {
  dispatch({ type: types.GET_PROVIDER_FORM_REQUESTED })
  try {
    const form = await providerService.getProviderForm(programId, 1)
    dispatch({ type: types.GET_PROVIDER_FORM_FULFILLED, payload: form.data })
  } catch (error) {
    dispatch({ type: types.GET_PROVIDER_FORM_REJECTED, payload: error })
  }
}

export const signEnrollment = (payload) => async (dispatch, getState) => {
  dispatch({ type: types.SIGN_ENROLLMENT_REQUESTED })
  try {
    const { user: { program, healthSystem } } = getState()
    const signedData = await providerService.signForm({ ...payload, programId: program.programId, healthSystemId: healthSystem.data.healthSystemId })

    dispatch({ type: types.SIGN_ENROLLMENT_FULFILLED, payload: signedData.data })
  } catch (error) {
    console.log(error)
    dispatch({ type: types.SIGN_ENROLLMENT_REJECTED, payload: error })
  }
}

export const sendToPool = ({ message, payload }) => async (dispatch) => {
  dispatch({ type: types.SEND_TO_POOL_REQUESTED })
  try {
    const updatedSession = await providerService.sendToPool({ message, payload })
    dispatch({ type: types.SEND_TO_POOL_FULFILLED, payload: updatedSession.data })
  } catch (error) {
    dispatch({ type: types.SEND_TO_POOL_REJECTED, payload: error })
  }
}

export const printForm = (patient, coverages) => async (dispatch, getState) => {
  dispatch({ type: types.PRINT_PDF_REQUESTED })
  const { user: { program, session } } = getState()
  try {
    const pdfName = await providerService.printPdf({ sessionId: session.sessionId, programId: program.programId, patient, coverages })
    dispatch({ type: types.PRINT_PDF_FULFILLED, payload: pdfName.data })
  } catch (error) {
    dispatch({ type: types.PRINT_PDF_REJECTED, payload: error })
  }
}

export const initForm = (payload) => (dispatch) => dispatch({ type: types.INIT_FORM, payload })

export const updateFormValue = (payload) => (dispatch) => dispatch({ type: types.UPDATE_FORM_VALUE, payload })

export const resetState = () => (dispatch) => dispatch({ type: types.RESET_PROVIDER_STATE })