import styled from 'styled-components'

const ProgramBanner = styled.div`
    margin-bottom: 0.4rem;
    @media screen and (min-width: 786px) {
        order: 2;
        border-left: ${props => `${props.borderLeft}px solid ${props.color}`};
        margin-left: ${props => `${props.marginLeft}rem`};
	    padding-left: ${props => `${props.paddingLeft}rem`};
    }
`

export default ProgramBanner