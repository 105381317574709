import React from 'react'
import StyledH2 from '../../../components/StyledH2'
import { Button, Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
    <div className='login-container'>
      <Container className='login-content'>
        <Row className="justify-content-md-center">
          <Col xs lg="2" margin="auto">
            <StyledH2 color='#070f0d' className='font-weight-bold'>Log In</StyledH2>
            <Link to="/">
              <Button variant='success' size='sm'>
                Log In With Microsoft
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>   
    </div>
  )
}

export default Login