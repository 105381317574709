import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import { addAssistance, getAssistanceForm, setStep, updateAssistanceValue } from '../../../features/patient/patientAction'
import LinkButton from '../../../components/LinkButton'
import PrimaryButton from '../../../components/PrimaryButton'

const Assistance = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    program,
    assistanceFormState,
    programState,
    assistanceForm,
    assistance,
    patient,
    session,
    addAssistanceState,
    actionId
  } = useSelector((state) => ({
    program: state.user.program,
    assistanceFormState: state.patient.getAssistanceForm,
    programState: state.user.getProgram,
    assistanceForm: state.patient.assistanceForm,
    assistance: state.patient.assistance,
    patient: state.user.fhirPatient,
    session: state.user.session,
    addAssistanceState: state.patient.addAssistance,
    actionId: state.user.actionId
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!assistanceFormState.success && programState.success) {
      dispatch(getAssistanceForm({ programId: program.programId, patient }))
    }
  }, [assistanceFormState.success, programState.success])

  const handlePreviousClick = () => {
    dispatch(setStep(3))
    navigate('/patient-program/insurance')
  }

  const handleUpdateFormField = (name, value) => {
    dispatch(updateAssistanceValue({ name, value }))
  }

  useEffect(() => {
    if (addAssistanceState.success) {
      dispatch(setStep(4))
      navigate('/patient-program/certification')
    }
  }, [addAssistanceState.success])

  return (
    <div>
      <p className='small text-muted'>Step 4 of 5</p>
      {
        assistanceFormState.success && (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              const sessionAction = session.actions.find((action) => action.sessionActionId === actionId)
              dispatch(addAssistance({
                ...assistance,
                formId: assistanceForm.formId,
                sessionActionId: sessionAction.sessionActionId
              }))
            }}
          >
            <Row>
              {
                assistanceForm.columns.map((column, index) => (
                  <Col
                    key={`${program.name}_patient_assistance_${index}`}
                    md={12 / assistanceForm.numberOfColumns}
                  >
                    {
                      column.map((section) => (
                        <FormSection
                          key={`${program.name}_patient_assistance_section_${section.formSectionId}`}
                          section={section}
                          primaryColor={program.primaryColor}
                          formValues={assistance}
                          updateValue={handleUpdateFormField}
                        />
                      ))
                    }
                  </Col>
                ))
              }
            </Row>
            <hr />
            <Row>
              <Col>
                <LinkButton
                  variant='link'
                  color={program.programBanner ? program.primaryColor : '#030A08'}
                  onClick={handlePreviousClick}
                >
                  Previous
                </LinkButton>
              </Col>
              <Col className='text-center py-2'>
                <PrimaryButton
                  color={program.programBanner ? program.primaryColor : '#3B6E35'}
                  secondcolor={program.programBanner ? program.secondaryColor : '#5FAD56'}
                  type='submit'
                >
                  Next
                </PrimaryButton>
              </Col>
              <Col />
            </Row>
          </Form>
        )
      }
    </div>
  )
}

export default Assistance