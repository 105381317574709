import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import PatientHeader from '../../../components/PatientHeader'
import PrimaryButton from '../../../components/PrimaryButton'
import {
  getProviderForm,
  initForm,
  sendToPool,
  signEnrollment,
  updateFormValue,
} from '../../../features/provider/providerAction'
import {
  getPatient,
  setSession,
  startAction,
} from '../../../features/user/userAction'
import {
  getPatientName,
  getPatientGender,
  getPatientBirthDate,
  programHasDemographic,
  calculateAge,
  generateHeightMeasurement,
  generateWeightMeasurement,
} from '../../../utils/helpers'

const Program = () => {
  const [userFriendlyStatus, setUserFriendlyStatus] =
    useState('Loading Form...')

  const dispatch = useDispatch()

  const {
    patient,
    getPatientState,
    updateSessionActionState,
    program,
    getFormState,
    form,
    initializedForm,
    conditions,
    formValues,
    session,
    enroll,
    sendToPoolState,
    action,
    healthSystem,
    user
  } = useSelector((state) => ({
    patient: state.user.patient,
    getPatientState: state.user.getPatient,
    updateSessionActionState: state.user.updateSessionAction,
    program: state.user.program,
    getFormState: state.provider.getForm,
    form: state.provider.form,
    initializedForm: state.provider.initializedForm,
    conditions: state.user.conditions,
    formValues: state.provider.formValues,
    session: state.user.session,
    action: state.user.action,
    enroll: state.provider.enroll,
    sendToPoolState: state.provider.sendToPool,
    healthSystem: state.user.healthSystem.data,
    user: state.user.user
  }))

  const navigate = useNavigate()

  useEffect(() => {
    if (program) {
      dispatch(getPatient(session.patientId))
      setUserFriendlyStatus('Gathering Patients Information...')
    }
  }, [program])

  useEffect(() => {
    if (getPatientState.success) {
      dispatch(startAction(user))
      setUserFriendlyStatus('Starting Action...')
    }
  }, [getPatientState.success])

  useEffect(() => {
    if (updateSessionActionState.success) {
      dispatch(getProviderForm(program.programId))
      setUserFriendlyStatus('Fetching Form...')
    }
  }, [updateSessionActionState.success])

  useEffect(() => {
    if (getFormState.success) {
      dispatch(initForm({ form, program, conditions, sessionAction: action }))
      setUserFriendlyStatus('Initializing Form...')
    }
  }, [getFormState.success])

  useEffect(() => {
    if (initializedForm) {
      document.getElementById('submit-program').disabled = true

      document.getElementById('agreedToAttestation').disabled = true
      document.getElementById('attestatorName').disabled = true

      const attestation = document.getElementById('attestation')
      attestation.addEventListener('scroll', (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        if (Math.floor(scrollTop + clientHeight) === scrollHeight) {
          document.getElementById('submit-program').disabled = false

          document.getElementById('agreedToAttestation').disabled = false
          document.getElementById('attestatorName').disabled = false
        }
      })
    }
  }, [initializedForm])

  useEffect(() => {
    if (enroll.success) {
      dispatch(setSession(enroll.data))
      navigate('/provider')
    }
  }, [enroll.success])

  useEffect(() => {
    if (sendToPoolState.success) {
      dispatch(setSession(sendToPoolState.data))
      navigate('/provider')
    }
  }, [sendToPoolState.success])

  const handleUpdateFormField = (name, value) => {
    dispatch(updateFormValue({ name, value }))
  }

  const handleSendToPool = () => {
    const payload = {
      ...formValues,
      sessionActionId: action.sessionActionId,
      formId: form.formId,
      healthSystemId: healthSystem.healthSystemId
    }
    dispatch(
      sendToPool({
        message: `Please complete the provider portion of the ${program.medicationName} enrollment form.`,
        payload,
      })
    )
  }

  return (
    <Container className="shadow p-0 bg-white mb-4 ">
      {(getPatientState.loading || updateSessionActionState.loading || getFormState.loading) && (
        <div className="text-center py-3">
          <Spinner animation="border" role="status" />
          <div>{userFriendlyStatus}</div>
        </div>
      )}
      {initializedForm && (
        <>
          <Container className="m-0 p-0">
            <PatientHeader className="px-3 py-2" color={program.tertiaryColor}>
              {programHasDemographic(1, program.patientDemographics) && (
                <>
                  <dt>Patient Name:</dt>
                  <dd> {getPatientName(patient)}</dd>
                </>
              )}
              {programHasDemographic(2, program.patientDemographics) && (
                <>
                  <dt>MRN:</dt>
                  <dd> {patient.mrn}</dd>
                </>
              )}
              {programHasDemographic(3, program.patientDemographics) && (
                <>
                  <dt>Gender:</dt>
                  <dd> {getPatientGender(patient)}</dd>
                </>
              )}
              {programHasDemographic(4, program.patientDemographics) && (
                <>
                  <dt>DOB:</dt>
                  <dd> {getPatientBirthDate(patient)}</dd>
                </>
              )}
              {programHasDemographic(5, program.patientDemographics) && (
                <>
                  <dt>Age:</dt>
                  <dd> {calculateAge(getPatientBirthDate(patient))}</dd>
                </>
              )}
              {programHasDemographic(6, program.patientDemographics) && (
                <>
                  <dt>Weight:</dt>
                  <dd>
                    {' '}
                    {patient.measurements
                      ? generateWeightMeasurement(patient, 'kg')
                      : ''}
                  </dd>
                </>
              )}
              {programHasDemographic(7, program.patientDemographics) && (
                <>
                  <dt>Weight:</dt>
                  <dd>
                    {' '}
                    {patient.measurements
                      ? generateWeightMeasurement(patient, 'ibs')
                      : ''}
                  </dd>
                </>
              )}
              {programHasDemographic(8, program.patientDemographics) && (
                <>
                  <dt>Height:</dt>
                  <dd>
                    {' '}
                    {patient.measurements
                      ? generateHeightMeasurement(patient, 'cm')
                      : ''}
                  </dd>
                </>
              )}
              {programHasDemographic(9, program.patientDemographics) && (
                <>
                  <dt>Height:</dt>
                  <dd>
                    {' '}
                    {patient.measurements
                      ? generateHeightMeasurement(patient, 'ft')
                      : ''}
                  </dd>
                </>
              )}
            </PatientHeader>
          </Container>
          <Container className="m-0 pt-0 pl-3 pb-2">
            {enroll.error && (
              <Alert variant="danger">
                Unable to Submit Program Enrollment
              </Alert>
            )}
            {sendToPoolState.error && (
              <Alert variant="danger">Unable to Send to Pool</Alert>
            )}
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                dispatch(
                  signEnrollment({
                    ...formValues,
                    sessionActionId: action.sessionActionId,
                    formId: form.formId,
                  })
                )
              }}
            >
              <Row>
                {form.columns.map((column, index) => (
                  <Col
                    key={`${program.medicationName}_column_${index}`}
                    lg={12 / form.numberOfColumns}
                  >
                    {column.map((section, index, row) => (
                      <div
                        key={`${program.medicationName}_provider_${index}_section_${section.formSectionId}`}
                      >
                        <FormSection
                          section={section}
                          primaryColor={program.primaryColor}
                          showHeader
                          formValues={formValues}
                          updateValue={handleUpdateFormField}
                        />
                        {index + 1 !== row.length && program.programId > 1 && (
                          <hr />
                        )}
                      </div>
                    ))}
                    {form.columns.length === index + 1 && (
                      <>
                        <Button
                          id="forward-to-pool"
                          variant="secondary"
                          className="mr-2"
                          onClick={handleSendToPool}
                          disabled={sendToPoolState.loading}
                        >
                          {sendToPoolState.loading ? (
                            <Spinner animation="border" size='sm' />
                          ) : (
                            'Save and Forward To Pool'
                          )}
                        </Button>
                        <PrimaryButton
                          type="submit"
                          color={
                            program.programBanner
                              ? program.primaryColor
                              : '#3B6E35'
                          }
                          secondcolor={
                            program.programBanner
                              ? program.secondaryColor
                              : '#5FAD56'
                          }
                          id="submit-program"
                        >
                          Submit Program Enrollment
                        </PrimaryButton>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            </Form>
          </Container>
        </>
      )}
    </Container>
  )
}

export default Program
