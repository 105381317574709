/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../../../components/PrimaryButton'
import { Button, Overlay, Tooltip } from 'react-bootstrap'
import { FaUserMd, FaUser } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { clearCreateAndUpdateProgram, updateAction, updateStep } from '../../../../features/admin/adminAction'

const programStatusClass = (status) => {
  return status === 'Active' ? 'text-success' : status === 'Inactive' ? 'text-danger' : ''
}

const FormRow = ({ program }) => {
  const dispatch = useDispatch()
  const [copiedProvider, setCopiedProvider] = useState(false)
  const [copiedPatient, setCopiedPatient] = useState(false)

  const provider = useRef(null)
  const patient = useRef(null)

  return (
    <tr>
      <td>{program.medicationName}</td>
      <td>{program.medicationName}{program.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program.name}</td>
      <td>
        <CopyToClipboard 
          text={`mrn=%PATID%&encounter=%CSN%&user=%USERPROVFHIRID%&programId=${program.programId}`}
          onCopy={() => setCopiedProvider(true)}
        >
          <Button ref={provider} variant='outline-secondary' size='sm' className='mr-2' onMouseLeave={() => setCopiedProvider(false)}>
            <FaUserMd />
          </Button>
        </CopyToClipboard>
        <Overlay target={provider.current} show={copiedProvider} placement="right">
          {
            (props) => (
              <Tooltip id='copied-provider' {...props}>
                    Copied!
              </Tooltip>
            )
          }
        </Overlay> 
        <CopyToClipboard 
          text={`mrn=%PATID%&encounter=%CSN%&mychartid=%WPRID%&programId=${program.programId}`}
          onCopy={() => setCopiedPatient(true)}
        >
          <Button variant='outline-info' size='sm' ref={patient} onMouseLeave={() => setCopiedPatient(false)}>
            <FaUser />
          </Button>
        </CopyToClipboard>
        <Overlay target={patient.current} show={copiedPatient} placement="right">
          {
            (props) => (
              <Tooltip id='copied-patient' {...props}>
                    Copied!
              </Tooltip>
            )
          }
        </Overlay>
      </td>
      <td>{program.programType.name}</td>
      <td>{program.manufacturer.name}</td>
      <td className={`font-weight-bold ${programStatusClass(program.status)}`}>{program.status}</td>
      <td>
        <Link
          to={`/form/${program.programId}`}
          onClick={() => {
            dispatch(updateAction('edit'))
            dispatch(updateStep('landing'))
            dispatch(clearCreateAndUpdateProgram())
          }}
        >
          <PrimaryButton
            size='sm'
            color='#3B6E35'
            secondcolor='#5FAD56'
          >
            Edit
          </PrimaryButton>
        </Link>
      </td>
    </tr>
  )
}

export default FormRow