import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import { addInsurance, getInsuranceForm, setStep, updateInsuranceValue } from '../../../features/patient/patientAction'
import LinkButton from '../../../components/LinkButton'
import PrimaryButton from '../../../components/PrimaryButton'
import { getPatientCoverages } from '../../../features/user/userAction'

const Insurance = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    program,
    insuranceFormState,
    programState,
    insuranceForm,
    getPatientCoveragesState,
    coverages,
    insurance,
    session,
    addInsuranceState,
    actionId
  } = useSelector((state) => ({
    program: state.user.program,
    insuranceFormState: state.patient.getInsuranceForm,
    programState: state.user.getProgram,
    insuranceForm: state.patient.insuranceForm,
    getPatientCoveragesState: state.user.getPatientCoverages,
    coverages: state.user.coverages,
    insurance: state.patient.insurance,
    session: state.user.session,
    addInsuranceState: state.patient.addInsurance,
    actionId: state.user.actionId
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!getPatientCoveragesState.success && programState.success) {
      dispatch(getPatientCoverages())
    }
  }, [getPatientCoveragesState.success, programState.success])

  useEffect(() => {
    if (getPatientCoveragesState.success) {
      dispatch(getInsuranceForm({ programId: program.programId, coverages }))
    }
  }, [getPatientCoveragesState.success])

  const handlePreviousClick = () => {
    dispatch(setStep(2))
    navigate('/patient-program/contact')
  }

  const handleUpdateFormField = (name, value) => {
    dispatch(updateInsuranceValue({ name, value }))
  }

  useEffect(() => {
    if (addInsuranceState.success) {
      dispatch(setStep(3))
      navigate('/patient-program/assistance')
    }
  }, [addInsuranceState.success])

  return (
    <div>
      <p className='small text-muted'>Step 3 of 5</p>
      {
        insuranceFormState.success && (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              const sessionAction = session.actions.find((action) => action.sessionActionId === actionId)
              dispatch(addInsurance({
                ...insurance,
                sessionActionId: sessionAction.sessionActionId,
                formId: insuranceForm.formId
              }))
            }}
          >
            <Row>
              {
                insuranceForm.columns.map((column, index) => (
                  <Col
                    key={`${program.name}_patient_insurance_${index}`}
                    md={12 / insuranceForm.numberOfColumns}
                  >
                    {
                      column.map((section) => (
                        <FormSection
                          key={`${program.name}_patient_contact_section_${section.formSectionId}`}
                          section={section}
                          primaryColor={program.primaryColor}
                          formValues={insurance}
                          updateValue={handleUpdateFormField}
                        />
                      ))
                    }
                  </Col>
                ))
              }
            </Row>
            <hr />
            <Row>
              <Col>
                <LinkButton
                  variant='link'
                  color={program.programBanner ? program.primaryColor : '#030A08'}
                  onClick={handlePreviousClick}
                >
                  Previous
                </LinkButton>
              </Col>
              <Col className='text-center py-2'>
                <PrimaryButton
                  color={program.programBanner ? program.primaryColor : '#3B6E35'}
                  secondcolor={program.programBanner ? program.secondaryColor : '#5FAD56'}
                  type='submit'
                >
                  Next
                </PrimaryButton>
              </Col>
              <Col />
            </Row>
          </Form>
        )
      }
    </div>
  )
}

export default Insurance