import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const ProviderAttestation = ({ name, rows, value, label }) => {
  return (
    <Form.Group className='mb-2'>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        id={name}
        as='textarea'
        rows={rows}
        defaultValue={value}
        readOnly
      />
    </Form.Group>
  )
}

ProviderAttestation.propTypes = {
  name: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  label: PropTypes.string
}

export default ProviderAttestation