import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PatientHeader from '../../../../components/PatientHeader'
import StyledH3 from '../../../../components/StyledH3'
import FormSection from '../../../../components/FormSection'
import PrimaryButton from '../../../../components/PrimaryButton'
import { getProgram } from '../../../../features/admin/adminAction'
import { getAssistanceForm } from '../../../../features/patient/patientAction'
import { calculateAge, calculateStandardHeight, calculateWeightInPounds } from '../../../../utils/helpers'

const AssistancePreview = () => {
  const { programId } = useParams()
  const navigate = useNavigate()
  
  const dispatch = useDispatch()
  const { program, getProgramState, getAssistanceFormState, assistanceForm } = useSelector((state) => ({
    program: state.admin.program,
    getProgramState: state.admin.getProgram,
    getAssistanceFormState: state.patient.getAssistanceForm,
    assistanceForm: state.patient.assistanceForm
  }))
  
  useEffect(() => {
    if (!getProgramState.success) {
      dispatch(getProgram(programId))
    }
  
    if (getProgramState.success) {
      dispatch(getAssistanceForm(programId))
    }
  }, [getProgramState.success])
  
  const programHasDemographic = (demographicId) => program.patientDemographics.find((patientDemographic) => patientDemographic.patientDemographicId === demographicId)
  
  const handlePreviousClick = () => {
    navigate(`/form/patient/insurance/${program.programId}`)
  }
  
  const handleNextClick = () => {
    navigate(`/form/patient/certification/${program.programId}`)
  }
  
  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <u>
            <StyledH3
              color='#070f0d'
              onClick={() => navigate('/form')}
              style={{
                cursor: 'pointer'
              }}
            >
                Forms
            </StyledH3>
          </u>
        </Col>
        <Col>
          <StyledH3
            color='#070f0d' 
            className='font-weight-bold'
          >
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getProgramState.loading && (
          <div>
              Fetching Page...
          </div>
        )
      }
      {
        getProgramState.success && (
          <Container className='shadow p-0 bg-white mb-4'>
            <Container className='m-0 p-0'>
              <PatientHeader
                className='px-3 py-2'
                color={program.tertiaryColor}
              >
                {
                  programHasDemographic(1) && (
                    <>
                      <dt>Patient Name:</dt>
                      <dd> John Doe</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(2) && (
                    <>
                      <dt>MRN:</dt>
                      <dd> 12345</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(3) && (
                    <>
                      <dt>Gender:</dt>
                      <dd> Male</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(4) && (
                    <>
                      <dt>DOB:</dt>
                      <dd> 05/13/1968</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(5) && (
                    <>
                      <dt>Age:</dt>
                      <dd> {calculateAge('05/13/1968')}</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(6) && (
                    <>
                      <dt>Weight:</dt>
                      <dd> 88 kg (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(7) && (
                    <>
                      <dt>Weight:</dt>
                      <dd> {calculateWeightInPounds(88)} ibs (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(8) && (
                    <>
                      <dt>Height:</dt>
                      <dd> 188 cm (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(9) && (
                    <>
                      <dt>Height:</dt>
                      <dd> {calculateStandardHeight(188)} (09/27/22)</dd>
                    </>
                  )
                }
              </PatientHeader>
            </Container>
            <Container className='m-0 pt-0 pl-3 pb-2'>
              {
                getAssistanceFormState.loading && (
                  <div>
                      Fetching form...
                  </div>
                )
              }
              {
                getAssistanceFormState.success && (
                  <>
                    <p className="small text-muted">Step 4 of 5</p>
                    <Row>
                      {
                        assistanceForm.columns.map((column, index) => (
                          <Col
                            key={`${program.name}_patient_${index}`} 
                            md={12 / assistanceForm.numberOfColumns}
                          >
                            {
                              column.map((section) => (
                                <FormSection
                                  section={section}
                                  primaryColor={program.primaryColor}
                                  key={`${program.name}_patient_${index}_section_${section.formSectionId}`}
                                  showHeader={false}
                                />
                              ))
                            }
                          </Col>
                        ))
                      }
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Button variant='link' onClick={handlePreviousClick}>Previous</Button>
                      </Col>
                      <Col className='text-center py-2'>
                        <PrimaryButton
                          color='#3B6E35'
                          secondcolor='#5FAD56'
                          onClick={handleNextClick}
                        >
                          Next
                        </PrimaryButton>
                      </Col>
                      <Col />
                    </Row>
                  </>
                )
              }
            </Container>
          </Container>
        )
      }
    </div>
  )
}

export default AssistancePreview