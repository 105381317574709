import React from 'react'
import PropTypes from 'prop-types'
import { Image as BootstrapImage } from 'react-bootstrap'
import { getApiServer } from '../../utils/helpers'

const Image = ({ src, alt }) => {

  const server = getApiServer()

  return (
    <figure>
      <BootstrapImage src={server + src} alt={alt} />
      <figcaption className='py-1 px-3'>{alt}</figcaption>
    </figure>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
}

export default Image