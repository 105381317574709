import React, { Fragment, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StyledH3 from '../../../components/StyledH3'
import { getProgram } from '../../../features/admin/adminAction'
import PatientHeader from '../../../components/PatientHeader'
import { getProviderForm } from '../../../features/provider/providerAction'
import FormSection from '../../../components/FormSection'
import { calculateAge, calculateStandardHeight, calculateWeightInPounds, programHasDemographic } from '../../../utils/helpers'

const ProviderForm = () => {
  const { programId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { program, getProgramState, form, getFormState } = useSelector((state) => ({
    program: state.admin.program,
    getProgramState: state.admin.getProgram,
    form: state.provider.form,
    getFormState: state.provider.getForm
  }))

  useEffect(() => {
    dispatch(getProgram(programId))
    dispatch(getProviderForm(programId))
  }, [])

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <u>
            <StyledH3
              color='#070f0d'
              onClick={() => navigate(-1)}
              style={{
                cursor: 'pointer'
              }}
            >
              Forms
            </StyledH3>
          </u>
        </Col>
        <Col>
          <StyledH3
            color='#070f0d' 
            className='font-weight-bold'
          >
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getProgramState.loading && (
          <div>
            Fetching Section...
          </div>
        )
      }
      {
        getProgramState.success && (
          <Container className='shadow p-0 bg-white mb-4'>
            <Container className='m-0 p-0'>
              <PatientHeader
                className='px-3 py-2'
                color={program.tertiaryColor}
              >
                {
                  programHasDemographic(1, program.patientDemographics) && (
                    <>
                      <dt>Patient Name:</dt>
                      <dd> John Doe</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(2, program.patientDemographics) && (
                    <>
                      <dt>MRN:</dt>
                      <dd> 12345</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(3, program.patientDemographics) && (
                    <>
                      <dt>Gender:</dt>
                      <dd> Male</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(4, program.patientDemographics) && (
                    <>
                      <dt>DOB:</dt>
                      <dd> 05/13/1968</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(5, program.patientDemographics) && (
                    <>
                      <dt>Age:</dt>
                      <dd> {calculateAge('05/13/1968')}</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(6, program.patientDemographics) && (
                    <>
                      <dt>Weight:</dt>
                      <dd> 88 kg (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(7, program.patientDemographics) && (
                    <>
                      <dt>Weight:</dt>
                      <dd> {calculateWeightInPounds(88)} ibs (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(8, program.patientDemographics) && (
                    <>
                      <dt>Height:</dt>
                      <dd> 188 cm (09/27/22)</dd>
                    </>
                  )
                }
                {
                  programHasDemographic(9, program.patientDemographics) && (
                    <>
                      <dt>Height:</dt>
                      <dd> {calculateStandardHeight(188)} (09/27/22)</dd>
                    </>
                  )
                }
              </PatientHeader>
            </Container>
            <Container className='m-0 pt-0 pl-3 pb-2'>
              {
                getFormState.loading && (
                  <div>
                    Fetching Form...
                  </div>
                )
              }
              {
                getFormState.success && (
                  <Row>
                    {
                      form.columns.map((column, index) => (
                        <Col 
                          key={`${program.name}_provider_${index}`} 
                          md={12 / form.numberOfColumns}
                        >
                          {
                            column.map((section, index, row) => (
                              <Fragment key={`${program.name}_provider_${index}_section_${section.formSectionId}`}>
                                <FormSection
                                  section={section}
                                  primaryColor={program.primaryColor}
                                  showHeader
                                />
                                {
                                  index + 1 !== row.length && program.programId > 1 && (
                                    <hr />
                                  ) 
                                }
                              </Fragment>
                             
                            ))
                          }
                        </Col>
                      ))
                    }
                  </Row>
                )
              }
            </Container>
            <Container className='small'>
              <Row>
                {
                  program.footerTemplate && (
                    <Col className='text-center' dangerouslySetInnerHTML={{ __html: program.footerTemplate }} />
                  )
                }
              </Row>
            </Container>
          </Container>
        )
      }
    </div>
  )
}

export default ProviderForm