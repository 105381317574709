import axios from 'axios'
import { getApiServer } from '../../utils/helpers'
import { RateLimiter } from 'limiter'

const serverUrl = getApiServer()
const limiter = new RateLimiter({ tokensPerInterval: 100, interval: 'minute', fireImmediately: true })

const getHealthSystem = async () => {
  return await axios.get(`${serverUrl}/health-system`)
}

const getHealthSystemUser = async () => {
  return await axios.get(`${serverUrl}/health-system-user`)
}

const getEpicResource = async (client, id, resource, iss = '', accessToken = '', code = '') => {
  const remainingRequests = await limiter.removeTokens(1)
  let success = false
  if (remainingRequests < 0) {
    return { success }
  } else {
    success = true
    let data
    switch (resource) {
    case 'Condition': {
      const conditions = await client.search({ type: resource, query: { patient: id, category: 'problem-list-item' } })
      data = conditions.data
    }
      break
    case 'Coverage': {
      const coverages = await axios.get(`${iss}/Coverage?patient=${id}&_include=Coverage:subscriber:Patient&_include=Coverage:payor`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      data = coverages.data
    }
      break
    case 'Encounter': {
      const encounter = await client.read({ type: resource, id })
      data = encounter.data
    }
      break
    case 'MedicationRequest': {
      const medicationRequests = await axios.get(`${iss}/MedicationRequest?patient=${id}&_include=MedicationRequest:requester&_include=MedicationRequest:medicationReference`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      data = medicationRequests.data
    }
      break
    case 'Observation': {
      const observations = await client.search({ type: resource, query: { patient: id, category: 'vital-signs', code } })
      data = observations.data
    }
      break
    case 'Patient': {
      const patient = await client.read({ type: resource, id })
      data = patient.data
    }
      break
    case 'Practitioner': {
      const practitioner = await client.read({ type: resource, id })
      data = practitioner.data
    }
      break
    case 'PractitionerRole': {
      const roles = await axios.get(`${iss}/PractitionerRole?practitioner=${id}&_include=PractitionerRole:location`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      data = roles.data
    }
      break
    default:
      break
    }

    return { success, data }
  }
}

const savePatient = async (patient, weight, height) => {
  return await axios.post(`${serverUrl}/patient`, { patient, weight, height })
}

const getPatient = async (patientId) => {
  return await axios.get(`${serverUrl}/epic-patient`, { params: { patientId } })
}

const startSession = async (actor, healthSystemId) => {
  return axios.post(`${serverUrl}/session`, { actor, healthSystemId })
}

const updateSessionAction = async (sessionActionId, actor) => {
  return axios.post(`${serverUrl}/session-action`, { sessionActionId, actor })
}

const getCopayOffer = async (rxNormCode) => {
  return await axios.get(`${serverUrl}/copay`, { params: { rxNormCode } })
}

const getPatientCoverages = async (patientId) => {
  return await axios.get(`${serverUrl}/coverages`, { params: { patientId }})
}

const userService = {
  getHealthSystem,
  getEpicResource,
  savePatient,
  getPatient,
  startSession,
  getCopayOffer,
  updateSessionAction,
  getHealthSystemUser,
  getPatientCoverages
}

export default userService