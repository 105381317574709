import React, { useEffect, useState, Fragment } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { BsPlus, BsTrash } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../../components/PrimaryButton'
import StyledH3 from '../../../components/StyledH3'
import { editPrescriptionSection, getDosingBases, getDrugProducts, getFormSection, updateSectionCaption } from '../../../features/admin/adminAction'
import uuid from 'react-uuid'

const initialState = {
  indication: '',
  isIndicationInvalid: false,
  indications: [],
  weightLow: '',
  isWeightLowNA: false,
  isWeightLowInvalid: false,
  weightOperator: '',
  isWeightOperatorInvalid: false,
  weightHigh: '',
  isWeightHighNA: false,
  isWeightHighInvalid: false,
  weightInvalidMessage: '',
  weights: [],
  ageLow: '',
  isAgeLowNA: false,
  isAgeLowInvalid: false,
  ageOperator: '',
  isAgeOperatorInvalid: false,
  ageHigh: '',
  isAgeHighNA: false,
  isAgeHighInvalid: false,
  ageInvalidMessage: '',
  ages: [],
  prescriptionInfo: [{
    prescriptionId: 1,
    caption: 'Starting Therapy',
    customCaption: '',
    fieldType: 'Starting Therapy',
    products: [],
    rules: [],
    isNA: false
  }, {
    prescriptionId: 2,
    caption: 'Ongoing Therapy',
    customCaption: '',
    fieldType: 'Ongoing Therapy',
    products: [],
    rules: [],
    isNA: false
  }]
}

const EditPrescription = () => {
  const { formSectionId } = useParams()
  const navigate = useNavigate()

  const [dosingBasisOptions, setDosingBasisOptions] = useState([])
  const [state, setState] = useState(initialState)

  const dispatch = useDispatch()
  const { program, getFormSectionState, section, dosingBases, drugProducts, elements, editPrescriptionSectionState } = useSelector((state) => ({
    program: state.admin.program,
    getFormSectionState: state.admin.getFormSection,
    section: state.admin.section,
    dosingBases: state.admin.dosingBases,
    drugProducts: state.admin.drugProducts,
    elements: state.admin.elements,
    editPrescriptionSectionState: state.admin.editPrescriptionSection
  }))

  useEffect(() => {
    dispatch(getDosingBases())
    dispatch(getFormSection({ formSectionId, programId: program.programId }))
    dispatch(getDrugProducts(program.programId))

    setDosingBasisOptions(program.dosingBases.map((dosingBase) => dosingBase.dosingBasisId))
  }, [])

  useEffect(() => {
    if (editPrescriptionSectionState.success) {
      navigate(-1)
    }
  }, [editPrescriptionSectionState.success])

  const activeDosingBasis = () => {
    const dosingBasisId = dosingBasisOptions[0]
    const dosingBasis = dosingBases.find((dosingBase) => dosingBase.dosingBasisId === dosingBasisId)
    return `${dosingBasis.name.toLowerCase()}`
  }

  const allActiveDosingBasis = () => {
    return dosingBasisOptions.map((dosingBasisOption) => {
      const dosingBasis = dosingBases.find((dosingBase) => dosingBase.dosingBasisId === dosingBasisOption)
      return `${dosingBasis.name.toLowerCase()}`
    })
  }

  const buildProducts = (isStarting) => {
    if (dosingBasisOptions.includes(4)) {
      const parentProductElementId = isStarting ? 78 : 83
      const parentProductElement = elements.find((element) => element.elementId === parentProductElementId)
      
      return parentProductElement.options.map((option) => {
        const optionChildren = option.children.map((child) => child.listValueChildId)
        const drugProduct = drugProducts.find((dp) => dp.name === option.label)

        const parentSigElementId = isStarting ? 79 : 84
        const parentSigElement = elements.find((element) => element.elementId === parentSigElementId)
        let sigs = []
        if (parentSigElement) {
          sigs = parentSigElement.options.reduce((acc, curr) => {
            if (optionChildren.includes(curr.listValueId)) {
              
              return [...acc, { sigId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }

        const parentQuantityElementId = isStarting ? 80 : 85
        const parentQuantityElement = elements.find((element) => element.elementId === parentQuantityElementId)
        let quantities = []
        if (parentQuantityElement) {
          quantities = parentQuantityElement.options.reduce((acc, curr) => {
            if (optionChildren.includes(curr.listValueId)) {
                  
              return [...acc, { quantityId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }

        const parentRefillElementId = isStarting ? 81 : 86
        const parentRefillElement = elements.find((element) => element.elementId === parentRefillElementId)
        let refills = []
        if (parentRefillElement) {
          refills = parentRefillElement.options.reduce((acc, curr) => {
            if (optionChildren.includes(curr.listValueId)) {
                  
              return [...acc, { refillId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }
        
        return {
          id: drugProduct.codeId,
          name: drugProduct.name,
          hideName: false,
          sigs,
          sig: '',
          quantities,
          quantity: '',
          includeQuantityTextbox: false,
          refills,
          refill: '',
          includeRefillTextbox: false
        }
      })
    }

    return []
  }

  const pairWise = (dosingBases) => {
    const dosingBase1 = dosingBases[0]
    const dosingBase1Id = dosingBase1 === 'indication' ? 74 : dosingBase1 === 'weight' ? 75 : 76
    const dosingBase1Element = elements.find((element) => element.elementId === dosingBase1Id)
    const dosingBase1Options = dosingBase1Element ? dosingBase1Element.options.map((option) => ({ [`${dosingBase1}Id`]: option.listValueId })) : []
    if (dosingBases.length === 2) {
      return dosingBase1Options.flatMap((db) => {
        const dosingBase2 = dosingBases[1]
        const dosingBase2Id = dosingBase2 === 'indication' ? 74 : dosingBase2 === 'weight' ? 75 : 76
        const dosingBase2Element = elements.find((element) => element.elementId === dosingBase2Id)
        const dosingBase2Options = dosingBase2Element.options.map((option) => ({ [`${dosingBase2}Id`]: option.listValueId }))
        return dosingBase2Options.map((db2) => ({ [`${dosingBase1}Id`]: db[`${dosingBase1}Id`], [`${dosingBase2}Id`]: db2[`${dosingBase2}Id`] }))
      })
    }

    return dosingBase1Options.flatMap((db) => {
      const dosingBase2 = dosingBases[1]
      const dosingBase2Id = dosingBase2 === 'indication' ? 74 : dosingBase2 === 'weight' ? 75 : 76
      const dosingBase2Element = elements.find((element) => element.elementId === dosingBase2Id)
      const dosingBase2Options = dosingBase2Element.options.map((option) => ({ [`${dosingBase2}Id`]: option.listValueId }))

      const dosingBase3 = dosingBases[2]
      const dosingBase3Id = dosingBase3 === 'indication' ? 74 : dosingBase3 === 'weight' ? 75 : 76
      const dosingBase3Element = elements.find((element) => element.elementId === dosingBase3Id)
      const dosingBase3Options = dosingBase3Element.options.map((option) => ({ [`${dosingBase3}Id`]: option.listValueId }))
      return dosingBase2Options.flatMap((db2) => {
        return dosingBase3Options.map((db3) => ({ 
          [`${dosingBase1}Id`]: db[`${dosingBase1}Id`], 
          [`${dosingBase2}Id`]: db2[`${dosingBase2}Id`],
          [`${dosingBase3}Id`]: db3[`${dosingBase3}Id`]
        }))
      })
    })
  }

  const buildRules = (indications, weights, ages, isStarting) => {
    if (dosingBasisOptions.includes(4)) {
      return []
    }

    if (dosingBasisOptions.length === 1) {
      const dosingBase = activeDosingBasis()
      const activeDosingBases = dosingBase === 'indication' ? indications : dosingBase === 'weight' ? weights : ages
      const parentElementId = dosingBase === 'indication' ? 74 : dosingBase === 'weight' ? 75 : 76
      const parentElement = elements.find((element) => element.elementId === parentElementId)
      return activeDosingBases.map((dosingBasis, index) => {
        const parentElementOption = parentElement.options.find((option) => option.label === dosingBasis.name)
        const parentElementOptionChildren = parentElementOption.children.map((child) => child.listValueChildId)

        const parentProductElementId = isStarting ? 78 : 83
        const parentProductElement = elements.find((element) => element.elementId === parentProductElementId)
        let products = []
        if (parentProductElement) {
          products = parentProductElement.options.reduce((acc, curr) => {
            if (parentElementOptionChildren.includes(curr.listValueId)) {
              const drugProduct = drugProducts.find((dp) => dp.name === curr.label)
              return [...acc, drugProduct.codeId]
            }
            return acc
          }, [])
        }

        const parentSigElementId = isStarting ? 79 : 84
        const parentSigElement = elements.find((element) => element.elementId === parentSigElementId)
        let sigs = []
        if (parentSigElement) {
          sigs = parentSigElement.options.reduce((acc, curr) => {
            if (parentElementOptionChildren.includes(curr.listValueId)) {
              
              return [...acc, { sigId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }

        const parentQuantityElementId = isStarting ? 80 : 85
        const parentQuantityElement = elements.find((element) => element.elementId === parentQuantityElementId)
        let quantities = []
        if (parentQuantityElement) {
          quantities = parentQuantityElement.options.reduce((acc, curr) => {
            if (parentElementOptionChildren.includes(curr.listValueId)) {
                  
              return [...acc, { quantityId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }

        const parentRefillElementId = isStarting ? 81 : 86
        const parentRefillElement = elements.find((element) => element.elementId === parentRefillElementId)
        let refills = []
        if (parentRefillElement) {
          refills = parentRefillElement.options.reduce((acc, curr) => {
            if (parentElementOptionChildren.includes(curr.listValueId)) {
                  
              return [...acc, { refillId: curr.listValueId, name: curr.label }]
            }
            return acc
          }, [])
        }

        const ruleObj = {
          id: index + 1,
          indicationId: 'Any',
          weightId: 'Any',
          ageId: 'Any',
          products,
          hideProductName: false,
          sigs,
          sig: '',
          quantities,
          quantity: '',
          includeQuantityTextbox: false,
          refills,
          refill: '',
          includeRefillTextbox: false
        }

        ruleObj[`${dosingBase}Id`] = dosingBasis[`${dosingBase}Id`]
        return ruleObj
      })
    }

    const dosingBases = allActiveDosingBasis()
    const pairs = pairWise(dosingBases)
    const rules = pairs.map((pair) => {
      const pairOptions = dosingBases.map((dosingBase) => pair[`${dosingBase}Id`])
      const parentProductElementId = isStarting ? 78 : 83
      const parentProductElement = elements.find((element) => element.elementId === parentProductElementId)
      let products = []
      if (parentProductElement) {
        products = parentProductElement.options.reduce((acc, curr) => {
          const parentOptions = curr.parents.map((parent) => parent.listValueParentId)
          if (pairOptions.some((po) => parentOptions.includes(po))) {
            const drugProduct = drugProducts.find((dp) => dp.name === curr.label)
            return [...acc, drugProduct.codeId]
          }
          return acc
        }, [])
      }
      
      const parentSigElementId = isStarting ? 79 : 84
      const parentSigElement = elements.find((element) => element.elementId === parentSigElementId)
      let sigs = []
      if (parentSigElement) {
        sigs = parentSigElement.options.reduce((acc, curr) => {
          const parentOptions = curr.parents.map((parent) => parent.listValueParentId)
          if (pairOptions.some((po) => parentOptions.includes(po))) {
            return [...acc, { sigId: curr.listValueId, name: curr.label }]
          }
          return acc
        }, [])
      }

      const parentQuantityElementId = isStarting ? 80 : 85
      const parentQuantityElement = elements.find((element) => element.elementId === parentQuantityElementId)
      let quantities = []
      if (parentQuantityElement) {
        quantities = parentQuantityElement.options.reduce((acc, curr) => {
          const parentOptions = curr.parents.map((parent) => parent.listValueParentId)
          if (pairOptions.some((po) => parentOptions.includes(po))) {
            return [...acc, { quantityId: curr.listValueId, name: curr.label }]
          }
          return acc
        }, [])
      }

      const parentRefillElementId = isStarting ? 81 : 86
      const parentRefillElement = elements.find((element) => element.elementId === parentRefillElementId)
      let refills = []
      if (parentRefillElement) {
        refills = parentRefillElement.options.reduce((acc, curr) => {
          const parentOptions = curr.parents.map((parent) => parent.listValueParentId)
          if (pairOptions.some((po) => parentOptions.includes(po))) {
            return [...acc, { refillId: curr.listValueId, name: curr.label }]
          }
          return acc
        }, [])
      }

      const ruleObj = {
        id: uuid(),
        indicationId: 'Any',
        weightId: 'Any',
        ageId: 'Any',
        products,
        hideProductName: false,
        sigs,
        sig: '',
        quantities,
        quantity: '',
        includeQuantityTextbox: false,
        refills,
        refill: '',
        includeRefillTextbox: false
      }

      dosingBases.forEach((dosingBase) => {
        ruleObj[`${dosingBase}Id`] = pair[`${dosingBase}Id`] === 71 ? 'Any' : pair[`${dosingBase}Id`]
      })

      return ruleObj
    }).filter((rule) => rule.products.length !== 0 && rule.quantities.length !== 0 && rule.refills.length !== 0 && rule.sigs.length !== 0)

    return rules
  }

  useEffect(() => {
    if (getFormSectionState.success) {
      const indicationElement = elements.find((element) => element.elementId === 74)
      let indications = []
      if (indicationElement) {
        indications = indicationElement.options.reduce((acc, curr) => {
          if (curr.value !== 'Any') {
            return [...acc, { 
              indicationId: curr.listValueId,
              name: curr.value
            }]
          }
          return acc
        }, [])
      }
    
      const weightElement = elements.find((element) => element.elementId === 75)
      let weights = []
      if (weightElement) {
        weights = weightElement.options.reduce((acc, curr) => {
          if (curr.value !== 'Any') {
            return [...acc, { 
              weightId: curr.listValueId,
              name: curr.value
            }]
          }
    
          return acc
        }, [])
      }
    
      const ageElement = elements.find((element) => element.elementId === 76)
      let ages = []
      if (ageElement) {
        ages = ageElement.options.reduce((acc, curr) => {
          if (curr.value !== 'Any') {
            return [...acc, { 
              ageId: curr.listValueId,
              name: curr.value
            }]
          }
          return acc
        }, [])
      }

      const startingTherapyCaption = elements.find((element) => element.elementId === 77)
      const startingTherapyObject = {
        prescriptionId: 1,
        caption: startingTherapyCaption ? startingTherapyCaption.value : 'Starting Therapy', 
        customCaption: '',
        fieldType: 'Starting Therapy',
        products: buildProducts(true),
        rules: buildRules(indications, weights, ages, true),
        isNA: false
      }

      const ongoingTherapyCaption = elements.find((element) => element.elementId === 82)
      const ongoingElementObject = {
        prescriptionId: 2,
        caption: 'Ongoing Therapy',
        customCaption: '',
        fieldType: 'Ongoing Therapy',
        products: [],
        rules: [],
        isNA: true
      }

      if (ongoingTherapyCaption) {
        ongoingElementObject.caption = ongoingTherapyCaption.value
        ongoingElementObject.isNA = false
        ongoingElementObject.products = buildProducts(false)
        ongoingElementObject.rules = buildRules(indications, weights, ages, false)
      }
      
      setState((ps) => ({
        ...ps,
        indications,
        weights,
        ages,
        prescriptionInfo: [startingTherapyObject, ongoingElementObject]
      }))
    }
  }, [getFormSectionState.success])

  const handleChangeSectionCaption = (event) => {
    dispatch(updateSectionCaption(event.target.value))
  }

  const buildPrescriptionInfoNone = () => {
    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: previousState.prescriptionInfo.map((prescription) => ({
        ...prescription,
        products: drugProducts.map((drugProduct) => ({
          id: drugProduct.codeId,
          name: drugProduct.name,
          hideName: false,
          sigs: [],
          sig: '',
          quantities: [],
          quantity: '',
          includeQuantityTextbox: false,
          refills: [],
          refill: '',
          includeRefillTextbox: false
        }))
      }))
    }))
  }

  const buildRangeString = (low, isLowNA, operator, high, isHighNA) => `${isLowNA ? '' : `${low} `}${operator}${isHighNA ? '' : ` ${high}`}`

  const handleChangeDosingBasisCheckbox = (event) => {
    const { checked, value } = event.target
    const dosingBasis = parseInt(value, 10)

    let newDosingBasisOptions = dosingBasisOptions
    if (checked) {
      newDosingBasisOptions = [...newDosingBasisOptions, dosingBasis]

      if (dosingBasis === 4) {
        buildPrescriptionInfoNone()
      } else {
        let rules = []

        if (newDosingBasisOptions.length > 1) {
          rules = [{
            id: 1,
            indicationId: 'Any',
            weightId: 'Any',
            ageId: 'Any',
            products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
            hideProductName: false,
            sigs: [],
            sig: '',
            quantities: [],
            quantity: '',
            includeQuantityTextbox: false,
            refills: [],
            refill: '',
            includeRefillTextbox: false
          }]
        }

        setState((previousState) => ({
          ...previousState,
          prescriptionInfo: previousState.prescriptionInfo.map((prescription) => ({
            ...prescription,
            rules
          }))
        }))

      }
    } else {
      newDosingBasisOptions = newDosingBasisOptions.filter((dosingBasisOption) => dosingBasisOption !== dosingBasis)

      const doseBase = dosingBases.find((dosingBase) => dosingBase.dosingBasisId === dosingBasis)

      if (dosingBasis !== 4) {
        if (newDosingBasisOptions.length === 1) {
          const activeDosingBasis = dosingBases.find((dosingBase) => dosingBase.dosingBasisId === dosingBasisOptions[0])
          const dosingOptions = state[`${activeDosingBasis.name.toLowerCase()}s`]
          setState((ps) => ({
            ...ps,
            prescriptionInfo: ps.prescriptionInfo.map((prescription) => ({
              ...prescription,
              rules: dosingOptions.map((dosingOption, index) => {
                let rule = {
                  id: index,
                  indicationId: 'Any',
                  weightId: 'Any',
                  ageId: 'Any',
                  products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
                  hideProductName: false,
                  sigs: [],
                  sig: '',
                  quantities: [],
                  quantity: '',
                  includeQuantityTextbox: false,
                  refills: [],
                  refill: '',
                  includeRefillTextbox: false
                }
    
                rule[`${activeDosingBasis.name.toLowerCase()}Id`] = dosingOption[`${activeDosingBasis.name.toLowerCase()}Id`]
    
                return rule
              })
            }))
          }))
        }

        if (newDosingBasisOptions.length > 1) {
          setState((ps) => ({
            ...ps,
            prescriptionInfo: ps.prescriptionInfo.map((prescription) => ({
              ...prescription,
              rules: prescription.rules.map((rule) => ({
                ...rule,
                [`${doseBase.name.toLowerCase()}Id`]: 'Any'
              }))
            }))
          }))
        }

      } else {
        setState((ps) => ({
          ...ps,
          [`${doseBase.name.toLowerCase()}s`]: [],
          prescriptionInfo: ps.prescriptionInfo.map((prescription) => ({
            ...prescription,
            rules: []
          }))
        }))
      }
    }

    setDosingBasisOptions(newDosingBasisOptions)
  }

  const isCheckboxDisabled = (dosingBasisId) => {
    if (dosingBasisId < 4) {
      return dosingBasisOptions.includes(4)
    }

    return dosingBasisOptions.length !== 0 && !dosingBasisOptions.includes(4)
  }

  const handleAddInput = (inputType) => {
    const only1DosingBasis = dosingBasisOptions.length === 1
    switch (inputType) {
    case 'indication': {
      if (state.indication) {
        const ids = state.indications.map((indication) => indication.indicationId)
        const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
        setState((ps) => ({
          ...ps,
          indication: '',
          indications: [...ps.indications, {
            indicationId: id,
            name: state.indication
          }]
        }))

        if (only1DosingBasis) {
          setState((ps) => ({
            ...ps,
            prescriptionInfo: ps.prescriptionInfo.map((prescription) => {
              const ruleIds = prescription.rules.map((rule) => rule.id)
              const ruleId = ruleIds.length === 0 ? 1 : Math.max(...ruleIds) + 1
              return (
                {
                  ...prescription,
                  rules: [
                    ...prescription.rules,
                    {
                      id: ruleId,
                      indicationId: id,
                      weightId: 'Any',
                      ageId: 'Any',
                      products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
                      hideProductName: false,
                      sigs: [],
                      sig: '',
                      quantities: [],
                      quantity: '',
                      includeQuantityTextbox: false,
                      refills: [],
                      refill: '',
                      includeRefillTextbox: false
                    }
                  ]
                }
              )
            })
          }))
        }
      } else {
        setState((ps) => ({
          ...ps,
          isIndicationInvalid: true
        }))
      }
    }
      break
    case 'weight': {
      const { weightLow, weightHigh, isWeightLowNA, isWeightHighNA, weightOperator } = state
      if (!weightLow && !isWeightLowNA) {
        setState((ps) => ({
          ...ps,
          isWeightLowInvalid: true,
          weightInvalidMessage: 'Please add a low number'
        }))
      } else if (!weightHigh && !isWeightHighNA) {
        setState((ps) => ({
          ...ps,
          isWeightHighInvalid: true,
          weightInvalidMessage: 'Please add a high number'
        }))
      } else if (!weightOperator) {
        setState((ps) => ({
          ...ps,
          isWeightOperatorInvalid: true,
          weightInvalidMessage: 'Please add an operator'
        }))
      } else {
        const ids = state.weights.map((weight) => weight.weightId)
        const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
        setState((ps) => ({
          ...ps,
          weightLow: '',
          weightHigh: '',
          isWeightLowNA: false,
          isWeightHighNA: false,
          weightOperator: '',
          weights: [...ps.weights, {
            weightId: id,
            name: buildRangeString(state.weightLow, state.isWeightLowNA, state.weightOperator, state.weightHigh, state.isWeightHighNA)
          }]
        }))

        if (only1DosingBasis) {
          setState((ps) => ({
            ...ps,
            prescriptionInfo: ps.prescriptionInfo.map((prescription) => {
              const ruleIds = prescription.rules.map((rule) => rule.id)
              const ruleId = ruleIds.length === 0 ? 1 : Math.max(...ruleIds) + 1
              return (
                {
                  ...prescription,
                  rules: [
                    ...prescription.rules,
                    {
                      id: ruleId,
                      indicationId: 'Any',
                      weightId: id,
                      ageId: 'Any',
                      products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
                      hideProductName: false,
                      sigs: [],
                      sig: '',
                      quantities: [],
                      quantity: '',
                      includeQuantityTextbox: false,
                      refills: [],
                      refill: '',
                      includeRefillTextbox: false
                    }
                  ]
                }
              )
            })
          }))
        }
      }
    }
      break
    case 'age': {
      const { ageLow, ageHigh, isAgeLowNA, isAgeHighNA, ageOperator } = state
      if (!ageLow && !isAgeLowNA) {
        setState((ps) => ({
          ...ps,
          isAgeLowInvalid: true,
          ageInvalidMessage: 'Please add a low number'
        }))
      } else if (!ageHigh && !isAgeHighNA) {
        setState((ps) => ({
          ...ps,
          isAgeHighInvalid: true,
          ageInvalidMessage: 'Please add a high number'
        }))
      } else if (!ageOperator) {
        setState((ps) => ({
          ...ps,
          isAgeOperatorInvalid: true,
          ageInvalidMessage: 'Please add an operator'
        }))
      } else {
        const ids = state.ages.map((age) => age.ageId)
        const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
        setState((ps) => ({
          ...ps,
          ageLow: '',
          ageHigh: '',
          isAgeLowNA: false,
          isAgeHighNA: false,
          ageOperator: '',
          ages: [...ps.ages, {
            ageId: id,
            name: buildRangeString(ageLow, isAgeLowNA, ageOperator, ageHigh, isAgeHighNA)
          }]
        }))

        if (only1DosingBasis) {
          setState((ps) => ({
            ...ps,
            prescriptionInfo: ps.prescriptionInfo.map((prescription) => {
              const ruleIds = prescription.rules.map((rule) => rule.id)
              const ruleId = ruleIds.length === 0 ? 1 : Math.max(...ruleIds) + 1
              return (
                {
                  ...prescription,
                  rules: [
                    ...prescription.rules,
                    {
                      id: ruleId,
                      indicationId: 'Any',
                      weightId: 'Any',
                      ageId: id,
                      products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
                      hideProductName: false,
                      sigs: [],
                      sig: '',
                      quantities: [],
                      quantity: '',
                      includeQuantityTextbox: false,
                      refills: [],
                      refill: '',
                      includeRefillTextbox: false
                    }
                  ]
                }
              )
            })
          }))
        }
      }
    }
      break
    default:
      break
    }
  }

  const handleRemoveInput = (key, idName, id) => {
    const prescriptionInfo = state.prescriptionInfo.map((prescription) => {
      let rules = []

      if (dosingBasisOptions.length === 1) {
        rules = prescription.rules.filter((rule) => rule[idName] !== id)
      }

      if (dosingBasisOptions.length > 1) {
        rules = prescription.rules.map((rule) => ({
          ...rule,
          [idName]: 'Any'
        }))
      }

      return {
        ...prescription,
        rules
      }
    })

    setState((previousState) => ({
      ...previousState,
      [key]: previousState[key].filter((previousState) => previousState[idName] !== id),
      prescriptionInfo
    }))
  }

  const handleTextChange = (event) => {
    setState((ps) => ({
      ...ps,
      [event.target.name]: event.target.value
    }))
  }

  const handleCheckboxChange = (event, parent) => {
    setState((previousState) => ({
      ...previousState,
      [parent]: '',
      [event.target.name]: event.target.checked
    }))
  }

  const handleSelectChange = (event) => {
    setState((previousState) => ({
      ...previousState,
      [event.target.name]: event.target.value
    }))
  }

  const handleAddRuleInput = (inputType, prescriptionId, ruleId) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)

    switch (inputType) {
    case 'sig': {
      const ids = state.prescriptionInfo[prescriptionIndex].rules[ruleIndex].sigs.map((sig) => sig.sigId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            rules: [
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
                sig: '',
                sigs: [...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].sigs, { sigId: id, name: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].sig }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    case 'quantity': {
      const ids = state.prescriptionInfo[prescriptionIndex].rules[ruleIndex].quantities.map((quantity) => quantity.quantityId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            rules: [
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
                quantity: '',
                includeQuantityTextbox: false,
                quantities: [
                  ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].quantities, 
                  { 
                    quantityId: id, 
                    name: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].quantity,
                    includeTextbox: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].includeQuantityTextbox
                  }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    case 'refill': {
      const ids = state.prescriptionInfo[prescriptionIndex].rules[ruleIndex].refills.map((refill) => refill.refillId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            rules: [
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
                refill: '',
                includeRefillTextbox: false,
                refills: [
                  ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].refills, 
                  { 
                    refillId: id, 
                    name: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].refill,
                    includeTextbox: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex].includeRefillTextbox
                  }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    default:
      break
    }
  }

  const handlePrescriptionCheckboxChange = (id, checked) => {
    const index = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === id)
    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, index),
        {
          ...previousState.prescriptionInfo[index],
          customCaption: '',
          isNA: checked
        },
        ...previousState.prescriptionInfo.slice(index + 1),
      ]
    }))
  }

  const handlePrescriptionTextChange = (id, value) => {
    const index = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === id)
    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, index),
        {
          ...previousState.prescriptionInfo[index],
          customCaption: value
        },
        ...previousState.prescriptionInfo.slice(index + 1),
      ]
    }))
  }

  const handleProductCheckboxChange = (prescriptionId, productId, event) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const productIndex = state.prescriptionInfo[prescriptionIndex].products.findIndex((product) => product.id === productId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          products: [
            ...previousState.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].products[productIndex],
              [event.target.name]: event.target.checked
            },
            ...previousState.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleRemoveProductInput = (key, idName, prescriptionId, productId, id) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const productIndex = state.prescriptionInfo[prescriptionIndex].products.findIndex((product) => product.id === productId)

    setState((ps) => ({
      ...ps,
      prescriptionInfo: [
        ...ps.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...ps.prescriptionInfo[prescriptionIndex],
          products: [
            ...ps.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
            {
              ...ps.prescriptionInfo[prescriptionIndex].products[productIndex],
              [key]: ps.prescriptionInfo[prescriptionIndex].products[productIndex][key].filter((obj) => obj[idName] !== id)
            },
            ...ps.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
          ]
        },
        ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleProductTextChange = (prescriptionId, productId, event) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const productIndex = state.prescriptionInfo[prescriptionIndex].products.findIndex((product) => product.id === productId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          products: [
            ...previousState.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].products[productIndex],
              [event.target.name]: event.target.value
            },
            ...previousState.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleAddProductInput = (inputType, prescriptionId, productId) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const productIndex = state.prescriptionInfo[prescriptionIndex].products.findIndex((product) => product.id === productId)

    switch (inputType) {
    case 'sig': {
      const ids = state.prescriptionInfo[prescriptionIndex].products[productIndex].sigs.map((sig) => sig.sigId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            products: [
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].products[productIndex],
                sig: '',
                sigs: [...ps.prescriptionInfo[prescriptionIndex].products[productIndex].sigs, { sigId: id, name: ps.prescriptionInfo[prescriptionIndex].products[productIndex].sig }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    case 'quantity': {
      const ids = state.prescriptionInfo[prescriptionIndex].products[productIndex].quantities.map((quantity) => quantity.quantityId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            products: [
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].products[productIndex],
                quantity: '',
                includeQuantityTextbox: false,
                quantities: [
                  ...ps.prescriptionInfo[prescriptionIndex].products[productIndex].quantities, 
                  { 
                    quantityId: id, 
                    name: ps.prescriptionInfo[prescriptionIndex].products[productIndex].quantity,
                    includeTextbox: ps.prescriptionInfo[prescriptionIndex].products[productIndex].includeQuantityTextbox
                  }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    case 'refill': {
      const ids = state.prescriptionInfo[prescriptionIndex].products[productIndex].refills.map((refill) => refill.refillId)
      const id = ids.length === 0 ? 1 : Math.max(...ids) + 1
      setState((ps) => ({
        ...ps,
        prescriptionInfo: [
          ...ps.prescriptionInfo.slice(0, prescriptionIndex),
          {
            ...ps.prescriptionInfo[prescriptionIndex],
            products: [
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(0, productIndex),
              {
                ...ps.prescriptionInfo[prescriptionIndex].products[productIndex],
                refill: '',
                includeRefillTextbox: false,
                refills: [
                  ...ps.prescriptionInfo[prescriptionIndex].products[productIndex].refills, 
                  { 
                    refillId: id, 
                    name: ps.prescriptionInfo[prescriptionIndex].products[productIndex].refill,
                    includeTextbox: ps.prescriptionInfo[prescriptionIndex].products[productIndex].includeRefillTextbox
                  }]
              },
              ...ps.prescriptionInfo[prescriptionIndex].products.slice(productIndex + 1),
            ]
          },
          ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
        ]
      }))
    }
      break
    default:
      break
    }
  }

  const handleRuleCheckboxChange = (prescriptionId, ruleId, event) => {
    const { value, checked } = event.target
    const drugProductId = parseInt(value, 10)

    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          rules: [
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              products: checked ? [...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex].products, drugProductId] : previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex].products.filter((product) => product !== drugProductId)
            },
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleDrugProductCheckboxChange = (prescriptionId, ruleId, event) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          rules: [
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              [event.target.name]: event.target.checked
            },
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleRemoveRuleInput = (key, idName, prescriptionId, ruleId, id) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)
    setState((ps) => ({
      ...ps,
      prescriptionInfo: [
        ...ps.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...ps.prescriptionInfo[prescriptionIndex],
          rules: [
            ...ps.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              [key]: ps.prescriptionInfo[prescriptionIndex].rules[ruleIndex][key].filter((obj) => obj[idName] !== id)
            },
            ...ps.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleRuleTextChange = (prescriptionId, ruleId, event) => {
    const { name, value } = event.target
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((product) => product.id === ruleId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          rules: [
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              [name]: value
            },
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleRuleIncludeCheckboxChange = (prescriptionId, ruleId, event) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          rules: [
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              [event.target.name]: event.target.checked
            },
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const haveDosingOptions = () => dosingBasisOptions.every((dosingBasisOption) => {
    const dosingBasis = dosingBases.find((dosingBase) => dosingBase.dosingBasisId === dosingBasisOption)
    return state[`${dosingBasis.name.toLowerCase()}s`].length > 0
  })

  const handleAddRule = (prescriptionId) => {
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIds = state.prescriptionInfo[prescriptionIndex].rules.map((rule) => rule.id)
    const id = ruleIds.length === 0 ? 1 : Math.max(...ruleIds) + 1

    setState((ps) => ({
      ...ps,
      prescriptionInfo: [
        ...ps.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...ps.prescriptionInfo[prescriptionIndex],
          rules: [
            ...ps.prescriptionInfo[prescriptionIndex].rules,
            {
              id,
              indicationId: 'Any',
              weightId: 'Any',
              ageId: 'Any',
              products: drugProducts.length === 1 ? drugProducts[0].codeId : [],
              hideProductName: false,
              sigs: [],
              sig: '',
              quantities: [],
              quantity: '',
              includeQuantityTextbox: false,
              refills: [],
              refill: '',
              includeRefillTextbox: false
            }
          ]
        },
        ...ps.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleChangeDosingBasisSelect = (prescriptionId, ruleId, event) => {
    const { value, name } = event.target
    const prescriptionIndex = state.prescriptionInfo.findIndex((prescription) => prescription.prescriptionId === prescriptionId)
    const ruleIndex = state.prescriptionInfo[prescriptionIndex].rules.findIndex((rule) => rule.id === ruleId)

    setState((previousState) => ({
      ...previousState,
      prescriptionInfo: [
        ...previousState.prescriptionInfo.slice(0, prescriptionIndex),
        {
          ...previousState.prescriptionInfo[prescriptionIndex],
          rules: [
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(0, ruleIndex),
            {
              ...previousState.prescriptionInfo[prescriptionIndex].rules[ruleIndex],
              [name]: value
            },
            ...previousState.prescriptionInfo[prescriptionIndex].rules.slice(ruleIndex + 1),
          ]
        },
        ...previousState.prescriptionInfo.slice(prescriptionIndex + 1)
      ]
    }))
  }

  const handleEdit = () => {
    dispatch(editPrescriptionSection({ 
      programId: program.programId,
      section,
      dosingBasisOptions, 
      indications: state.indications, 
      weights: state.weights, 
      ages: state.ages, 
      prescriptionInfo: state.prescriptionInfo 
    }))
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <Link
            to='/'
          >
            <u>
              <StyledH3 color='#070f0d'>
                Forms
              </StyledH3>
            </u>
          </Link>
        </Col>
        <Col>
          <StyledH3 color='#070f0d' className='font-weight-bold'>
            {program?.medicationName}{program?.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {program?.name}
          </StyledH3>
        </Col>
      </Row>
      <hr className='mt-1' />
      {
        getFormSectionState.loading && (
          <div>
            Fetching Section..
          </div>
        )
      }
      {
        getFormSectionState.success && (
          <>
            <Row>
              <Col md={10} xs={9}>
                <StyledH3>
                    Clinical Form: {section.caption}
                </StyledH3>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleEdit}
                  disabled={editPrescriptionSectionState.loading}
                >
                    Save
                </PrimaryButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  responsive
                >
                  <thead>
                    <tr>
                      <th />
                      <th>Field Type</th>
                      <th>Default Caption</th>
                      <th>Custom Caption</th>
                      <th>Auto-Fill</th>
                    </tr>  
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td>Section Header</td>
                      <td>{section.caption}</td>
                      <td>
                        <Form.Control
                          size='sm'
                          value={section.customCaption}
                          onChange={handleChangeSectionCaption}
                        />
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td></td>
                      <td>Dosing Basis</td>
                      <td>
                        {
                          dosingBases.map((dosingBasis) => (
                            <Form.Check
                              key={`${dosingBasis.name}-${dosingBasis.dosingBasisId}`}
                              inline
                              label={dosingBasis.name}
                              name={dosingBasis.name}
                              type='checkbox'
                              value={dosingBasis.dosingBasisId}
                              checked={dosingBasisOptions.includes(dosingBasis.dosingBasisId)}
                              onChange={handleChangeDosingBasisCheckbox}
                              disabled={isCheckboxDisabled(dosingBasis.dosingBasisId)}
                            />
                          ))
                        }
                      </td>
                      <td colSpan={2} />
                    </tr>
                    {
                      dosingBasisOptions.includes(1) && (
                        <tr>
                          <td />
                          <td>Indication List</td>
                          <td>
                            <Row className={`mb-${state.indications.length === 0 ? 0 : 2}`}>
                              <Col>
                                {
                                  state.indications.map((indication) => (
                                    <Row key={`indication-${indication.indicationId}`} className='mb-2'>
                                      <Col md={5}>
                                        {indication.name}
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          size='sm'
                                          variant='outline-danger'
                                          onClick={() => handleRemoveInput('indications', 'indicationId', indication.indicationId)}
                                        >
                                          <BsTrash />
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col md={10}>
                                <Form.Group>
                                  <Form.Control
                                    size='sm'
                                    value={state.indication}
                                    name='indication'
                                    onChange={handleTextChange}
                                    isInvalid={state.isIndicationInvalid}
                                  />
                                  <Form.Control.Feedback type='invalid'>
                                    Please add an indication.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Button
                                  variant='outline-success'
                                  size='sm'
                                  onClick={() => handleAddInput('indication')}
                                >
                                  <BsPlus /> Add
                                </Button>
                              </Col>
                            </Row>
                          </td>
                          <td />
                          <td />
                        </tr>
                      )
                    }
                    {
                      dosingBasisOptions.includes(2) && (
                        <tr>
                          <td />
                          <td>Weight Ranges (kg)</td>
                          <td>
                            <Row className={`mb-${state.weights.length === 0 ? 0 : 2}`}>
                              <Col>
                                {
                                  state.weights.map((weight) => (
                                    <Row key={`weight-${weight.weightId}`} className='mb-2'>
                                      <Col md={5}>
                                        {weight.name}
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          size='sm'
                                          variant='outline-danger'
                                          onClick={() => handleRemoveInput('weights', 'weightId', weight.weightId)}
                                        >
                                          <BsTrash />
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Group controlId='weightLow'>
                                  <Row>
                                    <Col>
                                      <Form.Label>Low</Form.Label>
                                    </Col>
                                    <Col>
                                      <Form.Check
                                        type='checkbox'
                                        label='N/A'
                                        name='isWeightLowNA'
                                        checked={state.isWeightLowNA}
                                        onChange={(event) => handleCheckboxChange(event, 'weightLow')}
                                        isInvalid={state.isWeightLowInvalid}
                                      />
                                    </Col>
                                  </Row>
                                  <Form.Control
                                    size='sm'
                                    name='weightLow'
                                    value={state.weightLow}
                                    onChange={handleTextChange}
                                    isInvalid={state.isWeightLowInvalid}
                                    disabled={state.isWeightLowNA}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId='weightOperator'>
                                  <Form.Label>Operator</Form.Label>
                                  <Form.Control
                                    as='select'
                                    size='sm'
                                    value={state.weightOperator}
                                    name='weightOperator'
                                    onChange={handleSelectChange}
                                    isInvalid={state.isWeightOperatorInvalid}
                                  >
                                    <option hidden>Select</option>
                                    <option value='-'>-</option>
                                    <option value='<'>&lt;</option>
                                    <option value='≤'>&#8804;</option>
                                    <option value='>'>&gt;</option>
                                    <option value='≥'>&#8805;</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId='weightHigh'>
                                  <Row>
                                    <Col>
                                      <Form.Label>High</Form.Label>
                                    </Col>
                                    <Col>
                                      <Form.Check
                                        type='checkbox'
                                        label='N/A'
                                        name='isWeightHighNA'
                                        checked={state.isWeightHighNA}
                                        onChange={(event) => handleCheckboxChange(event, 'weightHigh')}
                                        isInvalid={state.isWeightHighInvalid}
                                      />
                                    </Col>
                                  </Row>
                                  <Form.Control
                                    size='sm'
                                    name='weightHigh'
                                    value={state.weightHigh}
                                    onChange={handleTextChange}
                                    isInvalid={state.isWeightHighInvalid}
                                    disabled={state.isWeightHighNA}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Button
                                  variant='outline-success'
                                  size='sm'
                                  style={{
                                    marginTop: '2rem'
                                  }}
                                  onClick={() => handleAddInput('weight')}
                                >
                                  <BsPlus /> Add
                                </Button>
                              </Col>
                            </Row>
                            {
                              state.isWeightLowInvalid || state.isWeightHighInvalid || state.isWeightOperatorInvalid ? (
                                <Row>
                                  <Col>
                                    <Form.Text className='text-danger'>
                                      {state.weightInvalidMessage}
                                    </Form.Text>
                                  </Col>
                                </Row>
                              ) : null
                            }
                          </td>
                          <td />
                          <td />
                        </tr>
                      )
                    }
                    {
                      dosingBasisOptions.includes(3) && (
                        <tr>
                          <td />
                          <td>Age Ranges</td>
                          <td>
                            <Row className={`mb-${state.ages.length === 0 ? 0 : 2}`}>
                              <Col>
                                {
                                  state.ages.map((age) => (
                                    <Row key={`age-${age.ageId}`} className='mb-2'>
                                      <Col md={5}>
                                        {age.name}
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          size='sm'
                                          variant='outline-danger'
                                          onClick={() => handleRemoveInput('ages', 'ageId', age.ageId)}
                                        >
                                          <BsTrash />
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Group controlId='ageLow'>
                                  <Row>
                                    <Col>
                                      <Form.Label>Low</Form.Label>
                                    </Col>
                                    <Col>
                                      <Form.Check
                                        type='checkbox'
                                        label='N/A'
                                        name='isAgeLowNA'
                                        checked={state.isAgeLowNA}
                                        onChange={(event) => handleCheckboxChange(event, 'ageLow')}
                                        isInvalid={state.isAgeLowInvalid}
                                      />
                                    </Col>
                                  </Row>
                                  <Form.Control
                                    size='sm'
                                    name='ageLow'
                                    value={state.ageLow}
                                    onChange={handleTextChange}
                                    isInvalid={state.isAgeLowInvalid}
                                    disabled={state.isAgeLowNA}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Form.Group controlId='ageOperator'>
                                  <Form.Label>Operator</Form.Label>
                                  <Form.Control
                                    as='select'
                                    size='sm'
                                    value={state.ageOperator}
                                    name='ageOperator'
                                    onChange={handleSelectChange}
                                    isInvalid={state.isAgeOperatorInvalid}
                                  >
                                    <option hidden>Select</option>
                                    <option value='-'>-</option>
                                    <option value='<'>&lt;</option>
                                    <option value='≤'>&#8804;</option>
                                    <option value='>'>&gt;</option>
                                    <option value='≥'>&#8805;</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId='ageHigh'>
                                  <Row>
                                    <Col>
                                      <Form.Label>High</Form.Label>
                                    </Col>
                                    <Col>
                                      <Form.Check
                                        type='checkbox'
                                        label='N/A'
                                        name='isAgeHighNA'
                                        checked={state.isAgeHighNA}
                                        onChange={(event) => handleCheckboxChange(event, 'ageHigh')}
                                        isInvalid={state.isAgeHighInvalid}
                                      />
                                    </Col>
                                  </Row>
                                  <Form.Control
                                    size='sm'
                                    name='ageHigh'
                                    value={state.ageHigh}
                                    onChange={handleTextChange}
                                    isInvalid={state.isAgeHighInvalid}
                                    disabled={state.isAgeHighNA}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={2}>
                                <Button
                                  variant='outline-success'
                                  size='sm'
                                  style={{
                                    marginTop: '2rem'
                                  }}
                                  onClick={() => handleAddInput('age')}
                                >
                                  <BsPlus /> Add
                                </Button>
                              </Col>
                            </Row>
                            {
                              state.isAgeLowInvalid || state.isAgeHighInvalid || state.isAgeOperatorInvalid ? (
                                <Row>
                                  <Col>
                                    <Form.Text className='text-danger'>
                                      {state.ageInvalidMessage}
                                    </Form.Text>
                                  </Col>
                                </Row>
                              ) : null
                            }
                          </td>
                          <td />
                          <td />
                        </tr>
                      )
                    }
                    {
                      dosingBasisOptions.includes(4) && (
                        <>
                          {
                            state.prescriptionInfo.map((prescription) => (
                              <Fragment key={`prescriptionInfo-${prescription.prescriptionId}`}>
                                <tr>
                                  <td></td>
                                  <td>{prescription.fieldType}</td>
                                  <td>
                                    <Row>
                                      <Col>
                                        {prescription.caption}
                                      </Col>
                                      {
                                        prescription.prescriptionId === 2 && (
                                          <Col>
                                            <Form.Check
                                              type='checkbox'
                                              label='N/A'
                                              checked={prescription.isNA}
                                              onChange={(event) => handlePrescriptionCheckboxChange(prescription.prescriptionId, event.target.checked)}
                                            />
                                          </Col>
                                        )
                                      }
                                    </Row>
                                  </td>
                                  <td>
                                    <Form.Control 
                                      size='sm'
                                      value={prescription.customCaption}
                                      onChange={(event) => handlePrescriptionTextChange(prescription.prescriptionId, event.target.value)}
                                      disabled={prescription.isNA}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                                {
                                  prescription.products.map((product) => (
                                    <Fragment key={`product-${product.id}`}>
                                      <tr>
                                        <td colSpan={2} />
                                        <td>
                                          <Row>
                                            <Col>Drug Product(s)</Col>
                                            <Col>{product.name}</Col>
                                          </Row>
                                        </td>
                                        <td>
                                          <Form.Check
                                            type='checkbox'
                                            label='Hide "Drug Product(s)" caption'
                                            name='hideName'
                                            checked={product.hideName}
                                            onChange={(event) => handleProductCheckboxChange(prescription.prescriptionId, product.id, event)}
                                            disabled={prescription.isNA}
                                          />
                                        </td>
                                        <td />
                                      </tr>
                                      <tr>
                                        <td colSpan={2} />
                                        <td colSpan={2}>
                                          <Row className={`mb-${product.sigs.length === 0 ? 0 : 2}`}>
                                            <Col>
                                              {
                                                product.sigs.map((sig) => (
                                                  <Row key={`sig-${sig.sigId}`} className='mb-2'>
                                                    <Col md={5}>
                                                      {sig.name}
                                                    </Col>
                                                    <Col md={2}>
                                                      <Button
                                                        size='sm'
                                                        variant='outline-danger'
                                                        onClick={() => handleRemoveProductInput('sigs', 'sigId', prescription.prescriptionId, product.id, sig.sigId)}
                                                      >
                                                        <BsTrash />
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                ))
                                              }
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={1}>SIG</Col>
                                            <Col md={8}>
                                              <Form.Control
                                                size='sm'
                                                value={product.sig}
                                                name='sig'
                                                onChange={(event) => handleProductTextChange(prescription.prescriptionId, product.id, event)}
                                                disabled={prescription.isNA}
                                              />
                                            </Col>
                                            <Col>
                                              <Button
                                                variant='outline-success'
                                                size='sm'
                                                onClick={() => handleAddProductInput('sig', prescription.prescriptionId, product.id)}
                                                disabled={prescription.isNA}
                                              >
                                                <BsPlus /> Add
                                              </Button>
                                            </Col>
                                          </Row>
                                        </td>
                                        <td />
                                      </tr>
                                      <tr>
                                        <td colSpan={2} />
                                        <td colSpan={2}>
                                          <Row className={`mb-${product.quantities.length === 0 ? 0 : 2}`}>
                                            <Col>
                                              {
                                                product.quantities.map((quantity) => (
                                                  <Row key={`quantity-${quantity.quantityId}`} className='mb-2'>
                                                    <Col md={2}>
                                                      {quantity.name}
                                                    </Col>
                                                    <Col md={3}>
                                                      {
                                                        quantity.includeTextbox && (
                                                          <Form.Control 
                                                            size='sm'
                                                            readOnly
                                                          />
                                                        )
                                                      }
                                                    </Col>
                                                    <Col md={2}>
                                                      <Button
                                                        size='sm'
                                                        variant='outline-danger'
                                                        onClick={() => handleRemoveProductInput('quantities', 'quantityId', prescription.prescriptionId, product.id, quantity.quantityId)}
                                                      >
                                                        <BsTrash />
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                ))
                                              }
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={1}>Quantity</Col>
                                            <Col md={5}>
                                              <Form.Control
                                                size='sm'
                                                value={product.quantity}
                                                name='quantity'
                                                onChange={(event) => handleProductTextChange(prescription.prescriptionId, product.id, event)}
                                                disabled={prescription.isNA}
                                              />
                                            </Col>
                                            <Col>
                                              <Form.Check 
                                                type='checkbox'
                                                label='Include text box'
                                                name='includeQuantityTextbox'
                                                checked={product.includeQuantityTextbox}
                                                onChange={(event) => handleProductCheckboxChange(prescription.prescriptionId, product.id, event)}
                                                disabled={prescription.isNA}
                                              />
                                            </Col>
                                            <Col>
                                              <Button
                                                variant='outline-success'
                                                size='sm'
                                                onClick={() => handleAddProductInput('quantity', prescription.prescriptionId, product.id)}
                                                disabled={prescription.isNA}
                                              >
                                                <BsPlus /> Add
                                              </Button>
                                            </Col>
                                          </Row>
                                        </td>
                                        <td />
                                      </tr>
                                      <tr>
                                        <td colSpan={2} />
                                        <td colSpan={2}>
                                          <Row className={`mb-${product.refills.length === 0 ? 0 : 2}`}>
                                            <Col>
                                              {
                                                product.refills.map((refill) => (
                                                  <Row key={`refill-${refill.refillId}`} className='mb-2'>
                                                    <Col md={2}>
                                                      {refill.name}
                                                    </Col>
                                                    <Col md={3}>
                                                      {
                                                        refill.includeTextbox && (
                                                          <Form.Control 
                                                            size='sm'
                                                            readOnly
                                                          />
                                                        )
                                                      }
                                                    </Col>
                                                    <Col md={2}>
                                                      <Button
                                                        size='sm'
                                                        variant='outline-danger'
                                                        onClick={() => handleRemoveProductInput('refills', 'refillId', prescription.prescriptionId, product.id, refill.refillId)}
                                                      >
                                                        <BsTrash />
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                ))
                                              }
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={1}>Refill</Col>
                                            <Col md={5}>
                                              <Form.Control
                                                size='sm'
                                                value={product.refill}
                                                name='refill'
                                                onChange={(event) => handleProductTextChange(prescription.prescriptionId, product.id, event)}
                                                disabled={prescription.isNA}
                                              />
                                            </Col>
                                            <Col>
                                              <Form.Check 
                                                type='checkbox'
                                                label='Include text box'
                                                disabled={prescription.isNA}
                                                name='includeRefillTextbox'
                                                checked={product.includeRefillTextbox}
                                                onChange={(event) => handleProductCheckboxChange(prescription.prescriptionId, product.id, event)}
                                              />
                                            </Col>
                                            <Col>
                                              <Button
                                                variant='outline-success'
                                                size='sm'
                                                onClick={() => handleAddProductInput('refill', prescription.prescriptionId, product.id)}
                                                disabled={prescription.isNA}
                                              >
                                                <BsPlus /> Add
                                              </Button>
                                            </Col>
                                          </Row>
                                        </td>
                                        <td />
                                      </tr>
                                    </Fragment>
                                  ))
                                }
                              </Fragment>
                              
                            ))
                          }
                        </>
                      )
                    }
                    {
                      dosingBasisOptions.length === 1 && !dosingBasisOptions.includes(4) && state[`${activeDosingBasis()}s`].length > 0 && (
                        <>
                          {
                            state.prescriptionInfo.map((prescription) => (
                              <Fragment key={`prescription-${prescription.prescriptionId}`}>
                                <tr>
                                  <td></td>
                                  <td>{prescription.fieldType}</td>
                                  <td>
                                    <Row>
                                      <Col>
                                        {prescription.caption}
                                      </Col>
                                      {
                                        prescription.prescriptionId === 2 && (
                                          <Col>
                                            <Form.Check
                                              type='checkbox'
                                              label='N/A'
                                              checked={prescription.isNA}
                                              onChange={(event) => handlePrescriptionCheckboxChange(prescription.prescriptionId, event.target.checked)}
                                            />
                                          </Col>
                                        )
                                      }
                                    </Row>
                                  </td>
                                  <td>
                                    <Form.Control 
                                      size='sm'
                                      value={prescription.customCaption}
                                      onChange={(event) => handlePrescriptionTextChange(prescription.prescriptionId, event.target.value)}
                                      disabled={prescription.isNA}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                                {
                                  prescription.rules.map((rule) => (
                                    <Fragment key={`rule-${rule.id}`}>
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}>
                                              {state[`${activeDosingBasis()}s`].find((element) => element[`${activeDosingBasis()}Id`] === rule[`${activeDosingBasis()}Id`]).name}
                                            </Col>
                                            <Col md={2}>
                                              Drug Product(s)
                                            </Col>
                                            <Col>
                                              {
                                                Array.isArray(rule.products) ? 
                                                  drugProducts.map((drugProduct) => (
                                                    <Form.Check
                                                      key={`product-${drugProduct.codeId}`}
                                                      type='checkbox'
                                                      label={drugProduct.name}
                                                      value={drugProduct.codeId}
                                                      disabled={prescription.isNA}
                                                      checked={rule.products.includes(drugProduct.codeId)}
                                                      onChange={(event) => handleRuleCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                    />
                                                  )) : (
                                                    <Row>
                                                      <Col md={4}>
                                                        {drugProducts.find((drugProduct) => drugProduct.codeId === rule.products).name}
                                                      </Col>
                                                      <Col>
                                                        <Form.Check
                                                          type='checkbox'
                                                          label='Hide "Drug Product(s)" caption'
                                                          disabled={prescription.isNA}
                                                          checked={rule.hideProductName}
                                                          name='hideProductName'
                                                          onChange={() => handleDrugProductCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  )
                                              }
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}></Col>
                                            <Col md={2}>SIG</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.sigs.map((sig) => (
                                                      <Row key={`sig-${sig.sigId}`} className='mb-2'>
                                                        <Col md={5}>
                                                          {sig.name}
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('sigs', 'sigId', prescription.prescriptionId, rule.id, sig.sigId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col md={10}>
                                                      <Form.Control
                                                        size='sm'
                                                        name='sig'
                                                        disabled={prescription.isNA}
                                                        value={rule.sig}
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col md={2}>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('sig', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}></Col>
                                            <Col md={2}>Quantity</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.quantities.map((quantity) => (
                                                      <Row key={`quantity-${quantity.quantityId}`} className='mb-2'>
                                                        <Col md={2}>
                                                          {quantity.name}
                                                        </Col>
                                                        <Col md={3}>
                                                          {
                                                            quantity.includeTextbox && (
                                                              <Form.Control 
                                                                size='sm'
                                                                readOnly
                                                              />
                                                            )
                                                          }
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('quantities', 'quantityId', prescription.prescriptionId, rule.id, quantity.quantityId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col>
                                                      <Form.Control
                                                        size='sm'
                                                        disabled={prescription.isNA}
                                                        value={rule.quantity}
                                                        name='quantity'
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Form.Check 
                                                        type='checkbox'
                                                        label='Include text box'
                                                        disabled={prescription.isNA}
                                                        name='includeQuantityTextbox'
                                                        checked={rule.includeQuantityTextbox}
                                                        onChange={(event) => handleRuleIncludeCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('quantity', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}></Col>
                                            <Col md={2}>Refill</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.refills.map((refill) => (
                                                      <Row key={`refill-${refill.refillId}`} className='mb-2'>
                                                        <Col md={2}>
                                                          {refill.name}
                                                        </Col>
                                                        <Col md={3}>
                                                          {
                                                            refill.includeTextbox && (
                                                              <Form.Control 
                                                                size='sm'
                                                                readOnly
                                                              />
                                                            )
                                                          }
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('refills', 'refillId', prescription.prescriptionId, rule.id, refill.refillId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col>
                                                      <Form.Control
                                                        size='sm'
                                                        disabled={prescription.isNA}
                                                        value={rule.refill}
                                                        name='refill'
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Form.Check 
                                                        type='checkbox'
                                                        label='Include text box'
                                                        disabled={prescription.isNA}
                                                        name='includeRefillTextbox'
                                                        checked={rule.includeRefillTextbox}
                                                        onChange={(event) => handleRuleIncludeCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('refill', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  ))
                                }
                              </Fragment>
                            ))
                          }
                        </>
                      )
                    }
                    {
                      dosingBasisOptions.length > 1 && !dosingBasisOptions.includes(4) && haveDosingOptions() && (
                        <>
                          {
                            state.prescriptionInfo.map((prescription) => (
                              <Fragment key={`prescription-${prescription.prescriptionId}`}>
                                <tr>
                                  <td></td>
                                  <td>{prescription.fieldType}</td>
                                  <td>
                                    <Row>
                                      <Col>
                                        {prescription.caption}
                                      </Col>
                                      {
                                        prescription.prescriptionId === 2 && (
                                          <Col>
                                            <Form.Check
                                              type='checkbox'
                                              label='N/A'
                                              checked={prescription.isNA}
                                              onChange={(event) => handlePrescriptionCheckboxChange(prescription.prescriptionId, event.target.checked)}
                                            />
                                          </Col>
                                        )
                                      }
                                    </Row>
                                  </td>
                                  <td>
                                    <Form.Control 
                                      size='sm'
                                      value={prescription.customCaption}
                                      onChange={(event) => handlePrescriptionTextChange(prescription.prescriptionId, event.target.value)}
                                      disabled={prescription.isNA}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                                {
                                  prescription.rules.map((rule, i, arr) => (
                                    <Fragment key={`rule-${rule.id}`}>
                                      <tr>
                                        <td colSpan={2} rowSpan={4}>
                                          {
                                            state.indications.length > 0 && (
                                              <Form.Group className='mb-2'>
                                                <Form.Label>Indications</Form.Label>
                                                <Form.Control
                                                  as='select'
                                                  size='sm'
                                                  value={rule.indicationId}
                                                  disabled={prescription.isNA}
                                                  name='indicationId'
                                                  onChange={(event) => handleChangeDosingBasisSelect(prescription.prescriptionId, rule.id, event)}
                                                >
                                                  <option value='Any'>Any</option>
                                                  {
                                                    state.indications.map((indication) => (
                                                      <option 
                                                        key={`indication-${indication.indicationId}`} 
                                                        value={indication.indicationId}
                                                      >
                                                        {indication.name}
                                                      </option>
                                                    ))
                                                  }
                                                </Form.Control>
                                              </Form.Group>
                                            )
                                          }
                                          {
                                            state.weights.length > 0 && (
                                              <Form.Group className='mb-2'>
                                                <Form.Label>Weight Range</Form.Label>
                                                <Form.Control
                                                  as='select'
                                                  size='sm'
                                                  value={rule.weightId}
                                                  disabled={prescription.isNA}
                                                  name='weightId'
                                                  onChange={(event) => handleChangeDosingBasisSelect(prescription.prescriptionId, rule.id, event)}
                                                >
                                                  <option value='Any'>Any</option>
                                                  {
                                                    state.weights.map((weight) => (
                                                      <option 
                                                        key={`weight-${weight.weightId}`}
                                                        value={weight.weightId}
                                                      >
                                                        {weight.name}
                                                      </option>
                                                    ))
                                                  }
                                                </Form.Control>
                                              </Form.Group>
                                            )
                                          }
                                          {
                                            state.ages.length > 0 && (
                                              <Form.Group className='mb-2'>
                                                <Form.Label>Age Range</Form.Label>
                                                <Form.Control
                                                  as='select'
                                                  size='sm'
                                                  value={rule.ageId}
                                                  disabled={prescription.isNA}
                                                  name='ageId'
                                                  onChange={(event) => handleChangeDosingBasisSelect(prescription.prescriptionId, rule.id, event)}
                                                >
                                                  <option value='Any'>Any</option>
                                                  {
                                                    state.ages.map((age) => (
                                                      <option 
                                                        key={`age-${age.ageId}`}
                                                        value={age.ageId}
                                                      >
                                                        {age.name}
                                                      </option>
                                                    ))
                                                  }
                                                </Form.Control>
                                              </Form.Group>
                                            )
                                          }
                                          {
                                            arr.length - 1 === i && (
                                              <Button
                                                variant='outline-success'
                                                size='sm'
                                                style={{
                                                  width: '100%'
                                                }}
                                                disabled={prescription.isNA}
                                                onClick={() => handleAddRule(prescription.prescriptionId)}
                                              >
                                                New Rule
                                              </Button>
                                            )
                                          }
                                        </td>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}>
                                              Drug Product(s)
                                            </Col>
                                            <Col>
                                              {
                                                Array.isArray(rule.products) ? 
                                                  drugProducts.map((drugProduct) => (
                                                    <Form.Check
                                                      key={`product-${drugProduct.codeId}`}
                                                      type='checkbox'
                                                      label={drugProduct.name}
                                                      value={drugProduct.codeId}
                                                      disabled={prescription.isNA}
                                                      checked={rule.products.includes(drugProduct.codeId)}
                                                      onChange={(event) => handleRuleCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                    />
                                                  )) : (
                                                    <Row>
                                                      <Col md={4}>
                                                        {drugProducts.find((drugProduct) => drugProduct.codeId === rule.products).name}
                                                      </Col>
                                                      <Col>
                                                        <Form.Check
                                                          type='checkbox'
                                                          label='Hide "Drug Product(s)" caption'
                                                          disabled={prescription.isNA}
                                                          checked={rule.hideProductName}
                                                          name='hideProductName'
                                                          onChange={() => handleDrugProductCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  )
                                              }
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}>SIG</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.sigs.map((sig) => (
                                                      <Row key={`sig-${sig.sigId}`} className='mb-2'>
                                                        <Col md={5}>
                                                          {sig.name}
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('sigs', 'sigId', prescription.prescriptionId, rule.id, sig.sigId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col md={10}>
                                                      <Form.Control
                                                        size='sm'
                                                        name='sig'
                                                        disabled={prescription.isNA}
                                                        value={rule.sig}
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col md={2}>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('sig', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}>Quantity</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.quantities.map((quantity) => (
                                                      <Row key={`quantity-${quantity.quantityId}`} className='mb-2'>
                                                        <Col md={2}>
                                                          {quantity.name}
                                                        </Col>
                                                        <Col md={3}>
                                                          {
                                                            quantity.includeTextbox && (
                                                              <Form.Control 
                                                                size='sm'
                                                                readOnly
                                                              />
                                                            )
                                                          }
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('quantities', 'quantityId', prescription.prescriptionId, rule.id, quantity.quantityId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col>
                                                      <Form.Control
                                                        size='sm'
                                                        disabled={prescription.isNA}
                                                        value={rule.quantity}
                                                        name='quantity'
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Form.Check 
                                                        type='checkbox'
                                                        label='Include text box'
                                                        disabled={prescription.isNA}
                                                        name='includeQuantityTextbox'
                                                        checked={rule.includeQuantityTextbox}
                                                        onChange={(event) => handleRuleIncludeCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('quantity', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <Row>
                                            <Col md={2}>Refill</Col>
                                            <Col>
                                              <Row>
                                                <Col md={12}>
                                                  {
                                                    rule.refills.map((refill) => (
                                                      <Row key={`refill-${refill.refillId}`} className='mb-2'>
                                                        <Col md={2}>
                                                          {refill.name}
                                                        </Col>
                                                        <Col md={3}>
                                                          {
                                                            refill.includeTextbox && (
                                                              <Form.Control 
                                                                size='sm'
                                                                readOnly
                                                              />
                                                            )
                                                          }
                                                        </Col>
                                                        <Col md={2}>
                                                          <Button
                                                            size='sm'
                                                            variant='outline-danger'
                                                            onClick={() => handleRemoveRuleInput('refills', 'refillId', prescription.prescriptionId, rule.id, refill.refillId)}
                                                          >
                                                            <BsTrash />
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ))
                                                  }
                                                </Col>
                                                <Col md={12}>
                                                  <Row>
                                                    <Col>
                                                      <Form.Control
                                                        size='sm'
                                                        disabled={prescription.isNA}
                                                        value={rule.refill}
                                                        name='refill'
                                                        onChange={(event) => handleRuleTextChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Form.Check 
                                                        type='checkbox'
                                                        label='Include text box'
                                                        disabled={prescription.isNA}
                                                        name='includeRefillTextbox'
                                                        checked={rule.includeRefillTextbox}
                                                        onChange={(event) => handleRuleIncludeCheckboxChange(prescription.prescriptionId, rule.id, event)}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Button
                                                        variant='outline-success'
                                                        size='sm'
                                                        onClick={() => handleAddRuleInput('refill', prescription.prescriptionId, rule.id)}
                                                        disabled={prescription.isNA}
                                                      >
                                                        <BsPlus /> Add
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  ))
                                }
                              </Fragment>
                            ))
                          }
                        </>
                      )
                    }
                  </tbody>
                </Table>  
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant='outline-danger'
                  size='sm'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  className='float-md-right'
                  size='sm'
                  onClick={handleEdit}
                  disabled={editPrescriptionSectionState.loading}
                >
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default EditPrescription