import { format } from 'date-fns'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'

export const getBaseUrl = (url) => {
  let sourceUrl = url
  let sourceUrlIndex = url.indexOf('FHIR')
  if (sourceUrlIndex) {
    sourceUrl = url.substring(0, sourceUrlIndex)
  }
  return sourceUrl
}

export const getCurrentProgram = (healthSystem, program) => {
  const currentProgram = healthSystem.programs.find((p) => p.program.name === program)
  return currentProgram
}

export const getPatientName = (patient) => {
  let nameBuilder = ''
  if (patient) {
    if (patient.firstName) {
      nameBuilder += patient.firstName
    }
  
    if (patient.middleName) {
      nameBuilder += ` ${patient.middleName}`
    }
  
    if (patient.lastName) {
      nameBuilder += ` ${patient.lastName}`
    }
  }
  return nameBuilder
}

export const getPatientGender = (patient) => patient && patient.gender ? patient.gender : ''

export const getPatientBirthDate = (patient) => patient && patient.dateOfBirth ? format(new Date(dateFormatter(patient.dateOfBirth)), 'MM/dd/yyyy') : ''

export const getIsMychartUser = (patient) => patient && patient.mychartUser ? 'Yes' : 'No'

export const getPatientWeight = (patient) => {
  return patient && patient.weight ? `${patient.weight.value} ${patient.weight.unit} (${format(new Date(dateFormatter(patient.dateOfBirth)), 'MM/dd/yyyy')})` : ''
}

export const getPatientContact = (contact, type, areaCode) => {
  const contactType = contact.find((c) => c.use === type)
  
  if (type === 'email') {
    return contactType ? contactType.value : ''
  }

  return contactType
    ? areaCode
      ? contactType.value.replace('(', '').replace(')', '').slice(0, 3)
      : contactType.value.replace('(', '').replace(')', '').slice(-8)
    : ''
}

export const getPatientInsurance = (insurance, type, field) => {
  const insuranceType = insurance.find((i) => i.type === type)
  return insuranceType && insuranceType[field] || ''
}

export const getPatientInsurancePhone = (insurance, type, areaCode) => {
  const insuranceType = insurance.find((i) => i.type === type)
  return insuranceType && insuranceType.phoneNumber 
    ? areaCode
      ? insuranceType.phoneNumber.slice(0, 3)
      : insuranceType.phoneNumber.slice(-7)
    : '' 
}

export const getPatientTelecom = (patient, type) => {
  if (patient.telecom) {
    const telecom = patient.telecom.find((t) => t.system === type)
    return telecom ? ` ${telecom.value}` : ''
  }
  return ''
}

export const getPatientAddress = (address, field) => address[0][field] || ''

export const getPatientPrimaryDiagnosis = (diagnosis) => diagnosis.primaryDiagnosis.code

export const getPrescription = (prescription, field) => prescription.dosage[field]

export const getPrescriber = (prescription, field) => prescription.provider[field] || ''

export const getPrescriberContact = (prescription, field, areaCode) => {
  const contact = prescription.provider[field]

  return contact
    ? areaCode
      ? contact.replace('(', '').replace(')', '').slice(0, 3)
      : contact.replace('(', '').replace(')', '').slice(-8)
    : ''
}

export const getPrescriberSignature = (signature, field) => {
  const prescriberSignature = signature.find((s) => s.who === 'prescriber' || s.who === 'other')
  return prescriberSignature[field]
}

export const getPrescriberNpi = (prescriber) => {
  const npi = prescriber.identifier.find((id) => id.type.text === 'NPI')
  return npi ? npi.value : ''
}

export const getPrescriberPhone = (roles, areaCode) => {
  const role = roles[0]
  if (role.telecom) {
    const phone = role.telecom.find((t) => t.use === 'work')
    return phone
      ? areaCode
        ? phone.replace('(', '').replace(')', '').slice(0, 3)
        : phone.replace('(', '').replace(')', '').slice(-8)
      : ''
  }
  return ''
}

export const calculateAge = (dob) => {
  const today = new Date()
  const birthDate = new Date(dob)
  let age = differenceInCalendarYears(today, birthDate)
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return age
}

export const getPrimaryDiagnosis = (conditions, currentProgram) => {
  if (currentProgram && currentProgram.diagnosis) {
    const primaryCodes = currentProgram.diagnosis.map((d) => d.code)
    const patientCodes = conditions.map((c) => c.code)
    const primaryDiagnosis = patientCodes.filter((code) => primaryCodes.includes(code))
    return primaryDiagnosis.length === 1 ? currentProgram.diagnosis.find((d) => d.code === primaryDiagnosis[0]).primaryDiagnosisId : ''
  }
  return ''
}

export const getOtherDiagnosis = (conditions, currentProgram, index) => {
  if (currentProgram && currentProgram.diagnosis) {
    const primaryCodes = currentProgram.diagnosis.map((d) => d.code)
    const patientCodes = conditions.map((c) => c.code)
    const filteredConditions = patientCodes.filter((code) => !primaryCodes.includes(code))
    return filteredConditions[index] ? filteredConditions[index] : ''
  }
  return ''
}

export const formatDosageOptions = (dosages, patient, code) => {
  let options = []
  let header = ''
  const patientAge = patient && patient.dateOfBirth ? calculateAge(patient.dateOfBirth) : 0
  const patientWeight = patient && patient.weight ? patient.weight.value : 0

  if (code === 1 || code === 2) {
    if (patientAge >= 18) {
      header = 'Dosage for Patients aged ≥ 18'
      options = [1, 2].map((dose) => dosages.find((d) => d.dosageId === dose))
    }

    if (patientAge < 18 && patientWeight >= 60) {
      header = 'Dosage for Patients aged 6-17 years; Weight ≥ 60 kg'
      options = [1, 2].map((dose) => dosages.find((d) => d.dosageId === dose))
    }

    if (patientAge < 18 && (patientWeight >= 15 && patientWeight < 30)) {
      header = 'Dosage for Patients aged 6-17 years; Weight 15-30 kg'
      options = [1, 2].map((dose) => dosages.find((d) => d.dosageId === dose))
    }

    if (patientAge < 18 && (patientWeight >= 30 && patientWeight < 60)) {
      header = 'Dosage for Patients aged 6-17 years; Weight 30-60 kg'
      options = [3, 4].map((dose) => dosages.find((d) => d.dosageId === dose))
    }
  }

  if (code === 3 || code === 4) {
    if (patientAge >= 12) {
      header = 'Dosage for Patients aged ≥ 12'
      options = [3, 4, 1, 2].map((dose) => ({
        ...dosages.find((d) => d.dosageId === dose),
        includeOr: dose === 4
      }))
    }

    if ((patientAge >= 6 && patientAge <= 11) && patientWeight >= 30) {
      header = 'Dosage for Patients aged aged 6-11 years; Weight ≥ 30 kg'
      options.push(dosages.find((d) => d.dosageId === 5))
    }

    if ((patientAge >= 6 && patientAge <= 11) && (patientWeight >= 15 && patientWeight < 30)) {
      header = 'Dosage for Patients aged aged 6-11 years; Weight 15-30 kg'
      options = [6, 7].map((dose) => ({
        ...dosages.find((d) => d.dosageId === dose),
        includeOr: dose === 6
      }))
    }
  }

  if (code === 5 || code === 6) {
    if (patientAge >= 18) {
      header = 'Dosage for Patients aged ≥ 18'
      options.push(dosages.find((d) => d.dosageId === 8))
    }
  }

  return { options, header }
}

export const getApiServer = () => {
  const { protocol, hostname } = window.location
  return hostname === 'localhost' ? 'http://localhost:5000/api' : `${protocol}//${hostname}/api`
}

export const rebuildHtml = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8" />
  <title>Rebuilding PDF</title>
</head>
<body>
  Rebuilding PDF...
</body>
</html>`

export const dateFormatter = (date) => date.replace(/-/g, '/').replace(/T.+/, '')

export const calculateWeightInPounds = (weight) => Math.round((weight * 2.2046) * 10) / 10

export const calculateStandardHeight = (height) => {
  const lengthInInches = height / 2.54
  const feet = Math.floor(lengthInInches / 12)
  const inches = lengthInInches - (12 * feet)

  return `${feet} ft ${Math.round(inches)} in`
}

export const programHasDemographic = (demographicId, patientDemographics) => patientDemographics.find((patientDemographic) => patientDemographic.patientDemographicId === demographicId)

export const generateWeightMeasurement = (patient, unit) => {
  const measurement = patient.measurements.find((measurement) => measurement.type === 'weight')
  const value = unit === 'kg' ? measurement.value : calculateWeightInPounds(measurement.value)
  return `${value} ${unit} (${format(new Date(measurement.dateTaken), 'MM/dd/yyyy')})`
}

export const generateHeightMeasurement = (patient, unit) => {
  const measurement = patient.measurements.find((measurement) => measurement.type === 'height')
  const value = unit === 'cm' ? measurement.value : calculateStandardHeight(measurement.value)
  return `${value}${unit === 'cm' ? unit : ''} (${format(new Date(measurement.dateTaken), 'MM/dd/yyyy')})`
}