import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import format from 'date-fns/format'

const FormCheck = ({ type, label, name, required, value, updateValue, checked }) => {
  return (
    <Form.Group>
      <Form.Check>
        <Form.Check.Input 
          id={name}
          name={name}
          type={type}
          required={required}
          value={value}
          checked={value === checked}
          onChange={(event) => {
            if (event.target.checked) {
              updateValue(name, event.target.value)
            } else {
              updateValue(name, '')
            }
          }}
        />
        <Form.Check.Label dangerouslySetInnerHTML={{ __html: label.replace('[DATE]', format(new Date(), 'MM/dd/yyyy'))}} />
      </Form.Check>
    </Form.Group>
  )
}

FormCheck.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string
  }), PropTypes.string]),
  updateValue: PropTypes.func,
  checked: PropTypes.string
}

export default FormCheck