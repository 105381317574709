import React from 'react'
import { Container, Image } from 'react-bootstrap'
import { getApiServer } from '../../utils/helpers'

import '../../assets/styles/rxenroll.css'
import StyledH2 from '../StyledH2'

const NotFound = () => {
  const server = getApiServer()
  
  return (
    <div id='rx'>
      <Container fluid>
        <header>  
          <Container className='pt-3'>
            <h1>Rx Enrollment</h1>
            <p><Image src={`${server}/static/images/pocp/RxEnrollment.png`} width='113' height='64' alt="Rx Enrollment Logo" /></p>
          </Container>			
        </header>
      </Container>
      <main>
        <Container className='shadow p-0 bg-white mb-4 rounded'>
          <Container className='m-0 pt-2 pl-3 pb-2'>
            <StyledH2>Not Found.</StyledH2>
            <p>Unable to find the page you are looking for. Please go back now.</p>
          </Container>
        </Container>
      </main>
    </div>
  )
}

export default NotFound