import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Alert, Button, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from '../../features/auth/authAction'
import { getHealthSystem, startSession } from '../../features/user/userAction'
import { getApiServer } from '../../utils/helpers'

import '../../assets/styles/rxenroll.css'

const ProviderLayout = () => {
  const [loadingMessage, setLoadingMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')


  const dispatch = useDispatch()

  const {
    auth,
    healthSystem,
    startSessionState
  } = useSelector((state) => ({
    auth: state.auth,
    healthSystem: state.user.healthSystem,
    startSessionState: state.user.startSession
  }))

  useEffect(() => {
    if (healthSystem.loading) {
      setLoadingMessage('Fetching Health System...')
    } else {
      setLoadingMessage(auth.userFriendlyStatus)
    }
  }, [healthSystem.loading])

  useEffect(() => {
    if (healthSystem.error !== false) {
      setErrorMessage('Unable to find Health System')
    }

    if (auth.error) {
      setErrorMessage(auth.error.description)
    }
  }, [healthSystem.error, auth.error])

  const server = getApiServer()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (auth.accessTokenRecieved) {
      dispatch(getHealthSystem())
    } else {
      dispatch(getAccessToken(window.location.href))
    }
  }, [auth.accessTokenRecieved])

  useEffect(() => {
    if (healthSystem.success && !startSessionState.success) {
      dispatch(startSession('not-patient'))
    }
  }, [healthSystem.success, startSessionState.success])

  const handleExitClick = () => {
    navigate('/provider/exit')
  }

  return (
    <div id='rx'>
      <Container fluid>
        <header>  
          <Container className='pt-3'>
            <Row>
              <Col>
                <h1>Rx Enrollment</h1>
                <p><Image src={`${server}/static/images/pocp/RxEnrollment.png`} width='113' height='64' alt="Rx Enrollment Logo" /></p>
              </Col>
            </Row>
          </Container>			
        </header>
      </Container>
      <>
        {
          (auth.isFetching || healthSystem.loading) ? (<div className='text-center'>
            <Spinner animation='border' role='status' />
            <div>
              {loadingMessage}
            </div>
          </div>) : (auth.error !== null || healthSystem.error !== false) ?  (
            <Container className='pt-5'>
              <Alert variant='danger'>
                <Alert.Heading>Error</Alert.Heading>
                <div>{errorMessage}</div>
              </Alert>
            </Container>
          ) : <Outlet />
        }
      </>
      <footer>
        <Container>
          <Row>
            {
              !location.pathname.includes('/exit') && (
                <Col className='text-right'>
                  <Button 
                    variant='secondary'
                    className='mb-3'
                    onClick={handleExitClick} 
                    disabled={auth.isFetching}
                  >
                  Exit
                  </Button>
                </Col>
              )
            }
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default ProviderLayout