import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPatient, getProgram } from '../../features/user/userAction'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ProgramHeader from '../../components/ProgramHeader'
import PatientHeader from '../../components/PatientHeader'
import { calculateAge, generateHeightMeasurement, generateWeightMeasurement, getPatientBirthDate, getPatientGender, getPatientName, programHasDemographic } from '../../utils/helpers'

import '../../assets/styles/program.css'

const PatientProgramLayout = () => {
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProgram(params.programId))
  }, [])

  const { 
    program,
    getProgramState,
    patient,
  } = useSelector((state) => ({
    program: state.user.program,
    getProgramState: state.user.getProgram,
    patient: state.user.session.patient,
  }))

  useEffect(() => {
    if (program) {
      dispatch(getPatient(patient.patientId))
    } 
  }, [program])

  return (
    <div>
      {getProgramState.loading && (
        <div>
          Loading Program...
        </div>
      )}
      {
        getProgramState.success && (
          <Container fluid className='p-0' id='program'>
            <Helmet>
              <title>
                Rx Enrollment :: {program.medicationName}{program.includeRegisteredTrademark === 1 ? ('®') : ''} {program.name}
              </title>
            </Helmet>
            <header>
              <ProgramHeader />
            </header>
            <div>
              <Container className='shadow p-0 bg-white mb-4 rounded'>
                <Container className='m-0 p-0'>
                  <PatientHeader
                    color={program.tertiaryColor}
                    className='px-3 py-2'
                  >
                    {
                      programHasDemographic(1, program.patientDemographics) && (
                        <>
                          <dt>Patient Name:</dt>
                          <dd> {getPatientName(patient)}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(2, program.patientDemographics) && (
                        <>
                          <dt>MRN:</dt>
                          <dd> {patient.mrn}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(3, program.patientDemographics) && (
                        <>
                          <dt>Gender:</dt>
                          <dd> {getPatientGender(patient)}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(4, program.patientDemographics) && (
                        <>
                          <dt>DOB:</dt>
                          <dd> {getPatientBirthDate(patient)}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(5, program.patientDemographics) && (
                        <>
                          <dt>Age:</dt>
                          <dd> {calculateAge(getPatientBirthDate(patient))}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(6, program.patientDemographics) && (
                        <>
                          <dt>Weight:</dt>
                          <dd> {patient && patient.measurements ? generateWeightMeasurement(patient, 'kg') : ''}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(7, program.patientDemographics) && (
                        <>
                          <dt>Weight:</dt>
                          <dd> {patient && patient.measurements ? generateWeightMeasurement(patient, 'ibs') : ''}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(8, program.patientDemographics) && (
                        <>
                          <dt>Height:</dt>
                          <dd> {patient && patient.measurements ? generateHeightMeasurement(patient, 'cm') : ''}</dd>
                        </>
                      )
                    }
                    {
                      programHasDemographic(9, program.patientDemographics) && (
                        <>
                          <dt>Height:</dt>
                          <dd> {patient && patient.measurements ? generateHeightMeasurement(patient, 'ft') : ''}</dd>
                        </>
                      )
                    }
                  </PatientHeader>
                </Container>
                <Container className='m-0 pt-0 pl-3 pb-2'>
                  <Outlet />
                </Container>
              </Container>
            </div>
            <footer>
              <Container className='small'>
                <Row>
                  <Col className='text-right'>
                    <div dangerouslySetInnerHTML={{ __html: program.footerTemplate ? program.footerTemplate : null }} />
                  </Col>
                </Row>
              </Container>
            </footer>
          </Container>
        )
      }
    </div>
  )
}

export default PatientProgramLayout