import axios from 'axios'
import { getApiServer } from '../../utils/helpers'

const serverUrl = getApiServer()

const getForm = async (programId, formStepId) => {
  const form = await axios.get(`${serverUrl}/form`, { params: { program: programId, formStep: formStepId }})
  return form.data
}

const submitForm = async (form, body) => {
  return await axios.post(`${serverUrl}/${form}`, body)
}

const patientService = {
  getForm,
  submitForm
}

export default patientService