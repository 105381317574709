import axios from 'axios'
import { getApiServer } from '../../utils/helpers'

const serverUrl = getApiServer()

const getDropdownValues = async (dropdown, id = 0) => {
  return await axios.get(`${serverUrl}/${dropdown}${id > 0 ? `/${id}` : ''}`)
}

const getPrograms = async () => {
  return await axios.get(`${serverUrl}/programs`)
}

const getProgram = async (programId) => {
  return await axios.get(`${serverUrl}/programs/${programId}`)
}

const createNewProgram = async (values) => {
  return await axios.post(`${serverUrl}/program`, values)
}

const getSection = async (payload) => {
  const { sectionId, programId } = payload
  return await axios.get(`${serverUrl}/sections/${sectionId}`, { params: { programId }})
}

const getFormSection = async (payload) => {
  const { formSectionId, programId } = payload
  return await axios.get(`${serverUrl}/form-section/${formSectionId}`, { params: { programId }})
}

const updateProviderSection = async (payload) => {
  return await axios.post(`${serverUrl}/sections/provider`, payload)
}

const saveProviderSection = async (payload) => {
  return await axios.put(`${serverUrl}/sections/provider`, payload)
}

const updateAttestationSection = async (payload) => {
  return await axios.post(`${serverUrl}/sections/attestation`, payload)
}

const editAttestationSection = async (payload) => {
  return await axios.put(`${serverUrl}/sections/attestation`, payload)
}

const updatePrescriptionSection = async (payload) => {
  return await axios.post(`${serverUrl}/sections/prescription`, payload)
}

const editPrescriptionSection = async (payload) => {
  return await axios.put(`${serverUrl}/sections/prescription`, payload)
}

const saveDemographics = async (payload) => {
  return await axios.post(`${serverUrl}/demographics`, payload)
}

const updatePatientSection = async (payload) => {
  return await axios.post(`${serverUrl}/sections/patient`, payload)
}

const editPatientSection = async (payload) => {
  return await axios.put(`${serverUrl}/sections/patient`, payload)
}

const updateProgram = async (payload) => {
  const { programId, ...program } = payload
  return await axios.put(`${serverUrl}/programs/${programId}`, program)
}

const updateProgramSettings = async (payload) => {
  const { programId, ...program } = payload
  return await axios.put(`${serverUrl}/program-settings/${programId}`, program)
}

const resetClinicalForm = async (programId) => {
  return await axios.delete(`${serverUrl}/clinical-form/${programId}`)
}

const resetPatientForm = async (programId) => {
  return await axios.delete(`${serverUrl}/patient-form/${programId}`)
}

const adminService = {
  getDropdownValues,
  getPrograms,
  getProgram,
  createNewProgram,
  getSection,
  getFormSection,
  updateProviderSection,
  updateAttestationSection,
  updatePrescriptionSection,
  saveDemographics,
  updatePatientSection,
  updateProgram,
  resetClinicalForm,
  resetPatientForm,
  updateProgramSettings,
  saveProviderSection,
  editAttestationSection,
  editPatientSection,
  editPrescriptionSection
}

export default adminService