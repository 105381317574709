import React, { useEffect } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FormSection from '../../../components/FormSection'
import { consent, getCertificationForm, setStep, updateCertificationValue } from '../../../features/patient/patientAction'
import PrimaryButton from '../../../components/PrimaryButton'
import LinkButton from '../../../components/LinkButton'
import { setSession } from '../../../features/user/userAction'

const Certification = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    program,
    certificationFormState,
    programState,
    certificationForm,
    formValues,
    session,
    consentState,
    actionId
  } = useSelector((state) => ({
    program: state.user.program,
    certificationFormState: state.patient.getCertificationForm,
    programState: state.user.getProgram,
    certificationForm: state.patient.certificationForm,
    formValues: state.patient.certification,
    session: state.user.session,
    consentState: state.patient.consent,
    actionId: state.user.actionId
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!certificationFormState.success && programState.success) {
      dispatch(getCertificationForm(program.programId))
    }
  }, [certificationFormState.success, programState.success])

  useEffect(() => {
    const element = document.getElementById('certificationRepresentativeName')
    if (formValues.certificationPatientUser?.value) {
      if (formValues.certificationPatientUser.value === 'legal representative') {
        element.style.display = null
      } else {
        element.style.display = 'none'
      }
    }
  }, [formValues.certificationPatientUser?.value])

  useEffect(() => {
    const element = document.getElementById('communicationRepresentativeName')
    if (formValues.communicationPatientUser?.value) {
      if (formValues.communicationPatientUser.value === 'legal representative') {
        element.style.display = null
      } else {
        element.style.display = 'none'
      }
    }
  }, [formValues.communicationPatientUser?.value])

  const handlePreviousClick = () => {
    dispatch(setStep(4))
    navigate('/patient-program/assistance')
  }

  const handleUpdateFormField = (name, value) => {
    dispatch(updateCertificationValue({ name, value }))
  }

  useEffect(() => {
    if (consentState.success) {
      dispatch(setSession(consentState.data))
      navigate('/patient')
    }
  }, [consentState.success])

  return (
    <div>
      <p className='small text-muted'>Step 5 of 5</p>
      {
        certificationFormState.success && (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              const sessionAction = session.actions.find((action) => action.sessionActionId === actionId)
              dispatch(consent({
                ...formValues,
                sessionActionId: sessionAction.sessionActionId,
                formId: certificationForm.formId
              }))
            }}
          >
            {
              consentState.error && (
                <Alert variant='danger'>
                  Error consenting to certifications :(
                </Alert>
              )
            }
            <Row>
              {
                certificationForm.columns.map((column, index) => (
                  <Col
                    key={`${program.name}_patient_certification_${index}`}
                    md={12 / certificationForm.numberOfColumns}
                  >
                    {
                      column.map((section) => (
                        <FormSection
                          key={`${program.name}_patient_certification_section_${section.formSectionId}`}
                          section={section}
                          showHeader
                          primaryColor={program.primaryColor}
                          formValues={formValues}
                          updateValue={handleUpdateFormField}
                        />
                      ))
                    }
                  </Col>
                ))
              }
            </Row>
            <hr />
            <Row>
              <Col>
                <LinkButton
                  variant='link'
                  color={program.programBanner ? program.primaryColor : '#030A08'}
                  onClick={handlePreviousClick}
                >
                  Previous
                </LinkButton>
              </Col>
              <Col className='text-center py-2'>
                <PrimaryButton
                  color={program.programBanner ? program.primaryColor : '#3B6E35'}
                  secondcolor={program.programBanner ? program.secondaryColor : '#5FAD56'}
                  type='submit'
                >
                  Finish
                </PrimaryButton>
              </Col>
              <Col />
            </Row>
          </Form>
        )
      }
    </div>
  )
}

export default Certification