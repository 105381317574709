import React, { useEffect, useState } from 'react'
import StyledH2 from '../../../components/StyledH2'
import { Alert, Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BsArrowDownShort, BsArrowUpShort, BsSearch } from 'react-icons/bs'
import PrimaryButton from '../../../components/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreateAndUpdateProgram, getManufacturers, getPrograms, getProgramTypes, searchPrograms, sortPrograms, updateAction, updateStep } from '../../../features/admin/adminAction'
import FormRow from './FormRow'

const Forms = () => {
  const [filters, setFilters] = useState({
    search: '',
    programType: '',
    manufacturer: '',
    status: ''
  })

  const dispatch = useDispatch()

  const { programTypes, manufacturers, getProgramState, programs, sortKey, sortDirection } = useSelector((state) => ({
    programTypes: state.admin.programTypes,
    manufacturers: state.admin.manufacturers,
    getProgramState: state.admin.getPrograms,
    programs: state.admin.programs,
    sortKey: state.admin.sortKey,
    sortDirection: state.admin.sortDirection
  }))

  useEffect(() => {
    dispatch(getProgramTypes())
    dispatch(getManufacturers())
  }, [])

  useEffect(() => {
    if (!getProgramState.success) {
      dispatch(getPrograms())
    }
  }, [getProgramState.success])

  const handleSearchPrograms = (event) => {
    event.preventDefault()
    dispatch(searchPrograms(filters))
  }

  const handleChangeProgramType = (event) => {
    setFilters((ps) => ({
      ...ps,
      programType: parseInt(event.target.value, 10),
      manufacturer: '',
      status: ''
    }))
  }

  const handleChangeManufacturer = (event) => {
    setFilters((ps) => ({
      ...ps,
      programType: '',
      manufacturer: parseInt(event.target.value, 10),
      status: ''
    }))
  }

  const handleChangeStatus = (event) => {
    setFilters((ps) => ({
      ...ps,
      programType: '',
      manufacturer: '',
      status: event.target.value
    }))
  }

  useEffect(() => {
    if (filters.programType || filters.manufacturer || filters.status) {
      dispatch(searchPrograms(filters))
    }
  }, [filters])

  const handleSort = (key) => {
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    const newPrograms = [...programs]
    dispatch(sortPrograms({ sortKey: key, sortDirection: direction, programs: newPrograms }))
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col sm={1}>
          <StyledH2 color='#070f0d' className='font-weight-bold'>Forms</StyledH2>
        </Col>
        <Col md={5}>
          <Form noValidate className='mt-1' onSubmit={handleSearchPrograms}>
            <Row>
              <Col>
                <Form.Group controlId='search'>
                  <Form.Control
                    size='sm'
                    value={filters.search}
                    onChange={(event) => setFilters((ps) => ({
                      ...ps,
                      search: event.target.value
                    }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button variant='outline-secondary' size='sm' type='submit'>
                  <BsSearch />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md={6}>
          <Link
            to='/form'
            onClick={() => {
              dispatch(updateAction('create'))
              dispatch(updateStep('settings'))
              dispatch(clearCreateAndUpdateProgram())
            }}
          >
            <PrimaryButton
              color='#3B6E35'
              secondcolor='#5FAD56'
              className='float-md-end'
            >
              Add New Form
            </PrimaryButton>
          </Link>
         
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Table responsive borderless>
            <thead className='table-secondary'>
              <tr>
                <th>
                  <div
                    onClick={() => handleSort('medicationName')}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <u>Drug Name</u>
                    {
                      sortKey === 'medicationName' && (
                        <>
                          {
                            sortDirection === 'ASC' && <BsArrowUpShort />
                          }
                          {
                            sortDirection === 'DESC' && <BsArrowDownShort />
                          }
                        </>
                      )
                    }
                  </div>
                  
                </th>
                <th>
                  <div
                    onClick={() => handleSort('programName')}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <u>Program Name</u>
                    {
                      sortKey === 'programName' && (
                        <>
                          {
                            sortDirection === 'ASC' && <BsArrowUpShort />
                          }
                          {
                            sortDirection === 'DESC' && <BsArrowDownShort />
                          }
                        </>
                      )
                    }
                  </div>
                  
                </th>
                <th><u>Launch Tokens</u></th>
                <th className='m-0'>
                  <Form.Control
                    as='select'
                    size='sm'
                    aria-label='Program Type'
                    value={filters.programType}
                    onChange={handleChangeProgramType}
                  >
                    <option hidden>Program Type</option>
                    {
                      programTypes.map((programType) => (
                        <option 
                          key={`${programType.name}-${programType.programTypeId}`} 
                          value={programType.programTypeId}
                        >
                          {programType.name}
                        </option>
                      ))
                    }
                  </Form.Control>
                </th>
                <th>
                  <Form.Control
                    as='select'
                    size='sm'
                    aria-label='Manufacturer'
                    value={filters.manufacturer}
                    onChange={handleChangeManufacturer}
                  >
                    <option hidden>Manufacturer</option>
                    {
                      manufacturers.map((manufacturer) => (
                        <option
                          key={`${manufacturer.name}-${manufacturer.manufacturerId}`} 
                          value={manufacturer.manufacturerId}
                        >
                          {manufacturer.name}
                        </option>
                      ))
                    }
                  </Form.Control>
                </th>
                <th>
                  <Form.Control
                    as='select'
                    size='sm'
                    aria-label='Status'
                    value={filters.status}
                    onChange={handleChangeStatus}
                  >
                    <option hidden>Status</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Draft'>Draft</option>
                  </Form.Control>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                getProgramState.loading && (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      <Spinner />
                      Loading Programs ...
                    </td>
                  </tr>
                )
              }
              {
                getProgramState.success && (
                  <>
                    {
                      programs.length > 0 ? (
                        programs.map((form) => (
                          <FormRow key={form.medicationName} program={form} />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <Alert variant='info'>No Programs found.</Alert>
                          </td>
                        </tr>
                      )
                    }
                  </>
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default Forms